<template>
	<v-btn
		:aria-label="`Copy ${label} to clipboard`"
		:x-small="btn_x_small" :small="btn_small" 
		:fab="fab" :icon="!fab"
		:color="current_color" @click.stop="copy"
	>
		<v-icon :x-small="size=='small'" :large="size=='x-large'" :small="size==''">{{'fas fa-' + current_icon}}</v-icon>
		<textarea v-if="copying" style="display:none" class="k-copy-hidden-input"></textarea>
	</v-btn>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		val: { required: true },	// this will be changed to a string if necessary
		size: { type: String, required: false, default() { return ''} },	// we support values 'small', '' (normal), and 'large'
		label: { type: String, required: false, default() { return ''} },	
		color: { type: String, required: false, default() { return 'secondary'} },
		icon: { type: String, required: false, default() { return 'copy'} },
		fab: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		copying: false,
		current_icon: this.icon,
		current_color: this.color,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		btn_x_small() {
			// the btn should be x-small if...
			if (!this.fab) {
				// we're not using fab and size is '' (normal) or 'small'
				// in this case we want the overall size of the btn to be as small as possible
				return (this.size == '' || this.size == 'small')
			} else {
				// or if we *are* using fab and size is small (for a 'normal' size fab CopyBtn we'll use btn size small)
				return this.size == 'small'
			}
		},
		btn_small() {
			// the btn should be small if...
			if (!this.fab) {
				// we're not using fab and size is 'large'
				return this.size == 'large'
			} else {
				// or if we *are* using fab and size is '' (normal)
				return this.size == ''
			}
		},
		// if we're using fab and size is 'large', we'll use a "normal" sized btn
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		copy() {
			// doing this nextTick prevents mobile safari from showing the keyboard for the hidden div
			this.copying = true
			this.$nextTick(x=>{
				let jq = $(this.$el).find('.k-copy-hidden-input')
				let val = (typeof(this.val) == 'string') ? this.val : JSON.stringify(this.val)
				jq.val(val)
				jq.show()

				// select the input value and copy to clipboard
				jq[0].select()
				document.execCommand("copy")

				// re-hide the input
				jq.hide()

				// this.$inform({
				// 	text: 'Copied to clipboard',
				// 	// color: 'orange darken-2',
				// 	// snackbarTimeout:5000
				// })
				this.copying = false
			})
			this.current_icon = 'clipboard'
			this.current_color = 'pink accent-4'
			setTimeout(x=>{
				this.current_icon = this.icon
				this.current_color = this.color
			}, 1500)
		},
	}
}
</script>

<style lang="scss">
</style>
