<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><li v-show="!search_re||item_or_child_search_match" class="k-help-toc-item-wrapper" :class="wrapper_css">
	<a :href="`#${current_doc}`" class="k-help-toc-item" v-html="item_title" :class="item_css" @click="item_clicked" style="text-decoration: none; display: block;" :aria-expanded="item.expanded ? 'true' : 'false'" aria-current="page"></a>
	<div v-show="is_open"><ul v-if="item.children" class="k-help-toc-item-children">
		<HelpTOCItem v-for="(child, key) in item.children" :key="key" :doc_key="key" :item="child" :current_doc="current_doc" :search_re="search_re" :data-help-doc="key" />
	</ul></div>
</li></template>

<script>
import { mapState, mapGetters } from 'vuex'
import HelpTOCItem from './HelpTOCItem'

export default {
	name: 'HelpTOCItem',
	components: { HelpTOCItem },
	props: {
		doc_key: { type: String, required: true },
		item: { type: Object, required: true },
		current_doc: { type: String, required: true },
		search_re: { required: true },
		top_level: { type: Boolean, required: false, default() { return false} },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		item_title() {
			let s = this.item.title
			if (this.item.search_match) {
				s = U.html_to_text(s)
				s = s.replace(this.search_re, '<b>$1</b>')
			}
			return s
		},
		wrapper_css() {
			let s = ''
			if (this.top_level && this.item.children) {
				s += ' k-top-level'
				if (this.is_open) s += ' k-top-level-expanded my-2'
			}
			return s
		},
		item_css() {
			let s = ''
			if (this.doc_key == this.current_doc) {
				s += ' k-help-toc-item-current'
			} else if (this.item.search_match) {
				s += ' k-help-toc-item-search-match'
			}
			return s
		},
		is_open() {
			// everything below the top level is always open
			if (!this.top_level) return true

			// other conditions below are for top-level items
			// if (this.doc_key == this.current_doc) return true

			let child_open = (children) => {
				for (let doc_key in children) {
					if (doc_key == this.current_doc) return true
					let child = children[doc_key]
					if (child.children && child_open(child.children)) return true
				}
				return false
			}
			
			// if a child is open, show it
			if (this.item.children && child_open(this.item.children)) return true

			// if item_or_child_search_match, show it
			if (this.item_or_child_search_match) return true

			// otherwise show based on expanded flag
			return this.item.expanded
		},
		item_or_child_search_match() {
			if (!this.search_re) return false

			let child_search_match = (children) => {
				for (let doc_key in children) {
					let child = children[doc_key]
					if (child.search_match) return true
					if (child.children && child_search_match(child.children)) return true
				}
				return false
			}
			if (this.item.search_match) return true
			if (this.item.children) return child_search_match(this.item.children)
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		item_clicked() {
			vapp.$refs.help.show(this.doc_key, 'no_reposition')
			if (this.top_level) this.$emit('toggle_expanded')
		}
	}
}
</script>

<style lang="scss">
</style>