var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":"","nudge-right":"-22"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btn_size=='small')?_c('v-btn',_vm._g({style:(_vm.bstyle),attrs:{"aria-label":"Framework switcher","aria-haspopup":"true","aria-expanded":attrs['aria-expanded'] === 'true' ? 'true' :'false',"icon":"","color":_vm.color,"text":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-right-arrow-left")])],1):_vm._e(),_vm._v(" "),(_vm.btn_size=='large')?_c('v-btn',_vm._g({staticClass:"k-tight-btn",style:(_vm.bstyle),attrs:{"color":_vm.color,"aria-label":"Framework switcher","aria-haspopup":"true","aria-expanded":attrs['aria-expanded'] === 'true' ? 'true' :'false'}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-arrow-right-arrow-left")]),_vm._v("Select a "+_vm._s(_vm.framework_noun)+"…")],1):_vm._e()]}}]),model:{value:(_vm.menu_showing),callback:function ($$v) {_vm.menu_showing=$$v},expression:"menu_showing"}},[_vm._v(" "),_c('v-list',{staticClass:"framework_switcher_menu",attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"border-bottom":"1px solid #ccc","margin-top":"-8px","margin-bottom":"6px"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-right-arrow-left")])],1),_c('v-list-item-title',[_vm._v("\n\t\t\t"+_vm._s((_vm.btn_size=='small')?'Switch to framework…':'Select a ' + _vm.framework_noun + '…')+"\n\t\t\t"),_vm._v(" "),_c('span',{staticStyle:{"padding-right":"24px","margin-left":"12px","height":"24px","background-color":"transparent","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.clear_stored_identifier.apply(null, arguments)}}})])],1),_vm._v(" "),_vm._l((_vm.category_records),function(cr,index){return _c('v-menu',{key:index,staticStyle:{"display":"block"},attrs:{"transition":false,"offset-x":"","right":"","nudge-top":(_vm.$vuetify.breakpoint.width<700)?-36:0,"nudge-left":(_vm.$vuetify.breakpoint.width<700)?0:12},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',_vm._g({staticStyle:{"cursor":"pointer"},style:(hover?'background-color:#eee':'')},on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-layer-group")])],1),_c('v-list-item-title',{style:(cr==_vm.selected_category?'font-weight:bold; font-style:italic;':''),domProps:{"innerHTML":_vm._s(cr.title)}}),_vm._v(" "),_c('v-list-item-action',{staticClass:"justify-end"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-chevron-right")])],1)],1)]}}],null,true)})]}}],null,true)},[_vm._v(" "),_c('v-list',{staticStyle:{"max-height":"calc(100vh - 30px)","overflow":"auto"},attrs:{"dense":""}},_vm._l((cr.framework_records),function(fr){return _c('v-list-item',{key:fr.lsdoc_identifier,on:{"click":function($event){return _vm.view_framework(fr.lsdoc_identifier,$event)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-map")])],1),_c('v-list-item-title',{staticStyle:{"overflow":"visible","white-space":"normal"},style:(fr==_vm.selected_framework?'font-weight:bold; font-style:italic;':''),domProps:{"innerHTML":_vm._s(fr.json.CFDocument.title)}})],1)}),1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }