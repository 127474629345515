import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// this imports the version number from package.json, so we can show the version number in the ui with $state.store.app_version; also need something in vue.config.js (PACKAGE_VERSION)
		// run the following to update the third number; use 'minor' to update the second number and 'major' to update the first number
		// npm --no-git-tag-version version patch; npm run build; npm run serve
		app_version: process.env.PACKAGE_VERSION || '0.0.0',

		subdomain: window.satchel_subdomain,

		embedded_mode: false,
		embedded_mode_chooser: false,
		case_tree_component_showing: false,

		start_string: '',
		start_identifier: '',
		start_string_identifiers: '',

		// default values for site_config; some of these will be replaced by values coming in via site_config.php
		site_config: {
			// properties marked with ** are required to be initially set in order for other things to work properly before we load the server-provided data from site_config.php
			// used in plugins/vuetify
			primary_color: '#1A237E',

			// included in index.html as the browser window/tab title
			index_title: 'Standards Satchel',

			// banner color and color of the "agency" string
			banner_color: '#1A237E',
			agency_color: '#EA80FC',
			app_color: '#82B1FF',

			// global font
			sans_serif_font: 'Roboto,Calibri,Helvetica Neue,Helvetica,Arial,sans-serif',

			// we have three different "app" identifiers here, for use in different contexts. If app_name only is specified, it will be used in all three
			// app_name is the "official" name of the application
			app_name: 'Standards Satchel',		// **
			// app_product_name is what we refer to the app as in the help documentation; if not specified, we will use app_name (see HelpMixin.js)
			app_product_name: 'Standards Satchel', // ** 
			// app_name_for_banner is the text to show to the right of the logo in the banner; if not specified, we use app_name (and it can be hidden altogether by show_app_name_with logo)
			// note that this should be commented out here in store, so that the default of using the app_name is honored
			// app_name_for_banner: 'Standards Satchel',
			// agency_name is shown in the little "tab" that hangs below the banner on when viewing the framework list; agency_name_css can alter the css of this tab, and agency_url is the link target if someone clicks on the tab
			agency_name: 'Common Good Learning Tools',	// ** 
			agency_name_css: '',
			agency_url: 'https://commongoodlt.com/satchel',

			// Note: it is acceptable to switch "app_name_for_banner" and "agency_name"; e.g. we might want to say "Carnegie Learning" in the main banner and "Standards Satchel" in the tab that hangs down

			// logo file; this file must be included in vue-cli/public/images
			agency_logo: 'cglt-globe-with-stars.svg',	// **
			show_app_name_with_logo: true,	// **

			// css to adjust the way the agency logo appears in the banner
			agency_img_outer_css: 'height:60px;margin-top:-5px;',
			agency_img_css: 'height:100%;',
			title_left_padding: '84px',

			// css to adjust the way the agency logo appears in the signin dialog
			agency_img_outer_css_signin: 'flex: 0 1 37%; text-align:right;',
			agency_img_css_signin: 'height:100px;',

			// html to show in the banner when the framework index page is showing (see AZ)
			// 'agency_img_index_page_html' => '<div style="position: absolute; z-index: 10000; left: 8px; top: 3px;"><img style="height: 82px;" src="/src/logos/az-paasport.png"/></div>'

			// links to be added to burger menu at top-right of app
			burger_links: [],	// **

			// other misc colors
			heading_color: '#000000',
			sub_heading_color: '#000000',
			dividing_line_color: '#999999',

			// title of the framework list index page
			framework_list_title: 'Framework Index',
			// title used for the browsing interface, e.g. "Standards Explorer"
			browsing_interface_title: 'Standards Explorer',
			// noun used for competencies (e.g. standard, item, or competency)
			comp_noun: 'standard',
			// noun used to refer to frameworks in, e.g., the help documentation
			framework_noun: 'standards framework',

			map_mode_enabled: 'false',
			site_headline: '',
			site_headline_welcome: 'Welcome to Standards Satchel!',
			show_subject_coverage: 'false',
			site_headline_btns: [],

			// whether or not to enable non-admin users to have access to framework archives; if no, users won't be able to access framework update reports, the archives interface, side-by-side view, or item change history
			enable_archive_access: 'yes',

			copyright_org: {},	// ['name'=>'1EdTech', 'url'=>'https://www.1edtech.org'],
			terms_of_use_url: '',	// 'https://www.imsglobal.org/casenetwork/terms',

			// trusted origins that are allowed to include this instance of satchel in applications
			post_message_trusted_origins: ['https://sparkl-ed.com'],
		},

		app_initialized: false,
		mathjax_initialized: false,
		mathfield_uuid: '',
		login_error: '',
		user_info: {},
		froala_key: '',
		require_sign_in_for_full_functionality: false,
		enable_ap_export: false,
		framework_domain: 'https://agency.org',
		framework_access_report_fields: ['remote_addr'],
		start_string_regexps: [],
		session_renew_dialog_showing: false,
		today: '',

		framework_records: [],
		mirrors: [],
		mirror_logs: {},
		case_tree_viewer_width: null,
		case_tree_viewer_height: null,
		case_tree_suppress_watcher: false,	// see CASEItem
		editing_enabled: false,
		framework_lastChangeDateTime: null,
		last_edited_item_field: 'fs_input',
		viewer_post_load_execute_fn: null,

		framework_categories: [],

		resource_sets: [],

		map_frozen_hovered_square: '',

		comments: [],
		comment_groups: [],
		comments_previous_update_timestamp: {},
		comments_selected_group: -1,
		comments_selected_user: -1,
		comments_selected_suggested_edits: 'all',
		comments_show_resolved: true,
		comments_update_interval: 15000,

		// this holds data about archives of frameworks, indexed by lsdoc_identifier; used in update reports and the side-by-side editor
		framework_archives: {},

		// this holds entity archive data (see CASEEntityHistory) so we don't have to re-fetch it if the interface is opened and closed
		entity_archives: {},

		framework_access_selected_interval: 1,

		// difference between GMT and the timezone of the server that's saving MySQL dates, e.g. 300 if the server is on east coast time
		// PW 7/20/2022: actuallly if this isn't 0 it seems to screw things up...
		convert_to_local_date_offset_adjustment: 0,

		case_version_displayed: 'vext',	// could be set to v1p0 or v1p1

		// TODO: make the following 3 configurable
		standard_item_types: [
			{ value:"", text:"—" },
			{ value:"Grade", text:"Grade" },
			{ value:"Cluster", text:"Cluster" },
			{ value:"Pathway", text:"Pathway" },
			{ value:"Course", text:"Course" },
			{ value:"Domain", text:"Domain" },
			{ value:"Strand", text:"Strand" },
			{ value:"Competency", text:"Competency" },
			{ value:"Standard", text:"Standard" },
			{ value:"Element", text:"Element" },
			{ value:"Expectation", text:"Expectation" },
			{ value:"Component", text:"Component" },
			{ value:"Metadata Category", text:"Metadata Category" },
		],
		languages: [
			{ value:"", text:"—" },
			{ value:"en", text:"English" },
			{ value:"es", text:"Spanish" },
		],
		grades: [
			{ index:0, value:"", text:"—", abbreviation:"", },
			{ index:1, value:"PK", text:"PK", abbreviation:"PK" },
			{ index:2, value:"KG", text:"KG", abbreviation:"KG" },
			{ index:3, value:"01", text:"01", abbreviation:"1" },
			{ index:4, value:"02", text:"02", abbreviation:"2" },
			{ index:5, value:"03", text:"03", abbreviation:"3" },
			{ index:6, value:"04", text:"04", abbreviation:"4" },
			{ index:7, value:"05", text:"05", abbreviation:"5" },
			{ index:8, value:"06", text:"06", abbreviation:"6" },
			{ index:9, value:"07", text:"07", abbreviation:"7" },
			{ index:10, value:"08", text:"08", abbreviation:"8" },
			{ index:11, value:"09", text:"09", abbreviation:"9" },
			{ index:12, value:"10", text:"10", abbreviation:"10" },
			{ index:13, value:"11", text:"11", abbreviation:"11" },
			{ index:14, value:"12", text:"12", abbreviation:"12" },
			{ index:15, value:"College", text:"College", abbreviation:"C" },
		],

		// note: ext:satchelAIOnly association type not explicitly listed here...
		
		association_type_labels: {
			exactMatchOf: 'Exact Match',
			replacedBy: 'Replaced By',
			hasSkillLevel: 'Has Skill Level',
			isRelatedTo: 'Related',
			isPartOf: 'Is Part Of',
			precedes: 'Precedes',
			exemplar: 'Is Exemplar Of',
			isPeerOf: 'Peer Of',
			isTranslationOf: 'Is Translation Of',
			isChildOf: 'Is Child Of',

			// extensions created by Satchel crosswalk tool
			'ext:isNearExactMatch': 'Near-Exact Match',
			'ext:isCloselyRelatedTo': 'Closely Related',
			'ext:isModeratelyRelatedTo': 'Moderately Related',
			'ext:hasNoMatch': 'Has No Match',

			// extended in Satchel
			copiedFromSource: 'Copied From',
			aliasOf: 'Alias Of',	// within-framework alias -- not currently creating these, but need to keep them around
			aliasOfExternal: 'Alias Of',	// between-framework alias -- allowed as of 8/2024
		},
		association_type_labels_reverse: {
			exactMatchOf: 'Exact Match',
			replacedBy: 'Replaces',
			hasSkillLevel: 'Is Skill Level Of',
			isRelatedTo: 'Related',
			isPartOf: 'Has Part',
			precedes: 'Follows',
			exemplar: 'Has Exemplar',
			isPeerOf: 'Peer Of',
			isTranslationOf: 'Is Translation Of',
			isChildOf: 'Is Parent Of',

			// extensions created by Satchel crosswalk tool
			'ext:isNearExactMatch': 'Near-Exact Match',
			'ext:isCloselyRelatedTo': 'Closely Related',
			'ext:isModeratelyRelatedTo': 'Moderately Related',
			'ext:hasNoMatch': 'Has No Match',

			// extended in Satchel
			copiedFromSource: 'Copied To',
			aliasOf: 'Alias Of',
			aliasOfExternal: 'Alias Of',
		},
		association_type_icons: {
			exactMatchOf: 'fas fa-bullseye',
			replacedBy: 'fas fa-left-right',
			hasSkillLevel: 'fas fa-left-right',
			isRelatedTo: 'fas fa-left-right',
			isPartOf: ['fas fa-arrow-right-to-bracket', 'fas fa-arrow-right-from-bracket'],
			precedes: ['fas fa-play-down', 'fas fa-play-up'],
			exemplar: 'fas fa-left-right',
			isPeerOf: 'fas fa-left-right',
			isTranslationOf: 'fas fa-language',
			isChildOf: ['fas fa-circle-right', 'fas fa-circle-left'],

			// extensions created by Satchel crosswalk tool
			'ext:isNearExactMatch': 'fas fa-circle-dot',
			'ext:isCloselyRelatedTo': 'fas fa-circle',
			'ext:isModeratelyRelatedTo': 'fas fa-circle-half-stroke',
			'ext:hasNoMatch': 'fas fa-ban',

			// extended in Satchel
			copiedFromSource: ['fas fa-copy-reverse', 'fas fa-copy'],
			aliasOf: 'fas fa-clone',
			aliasOfExternal: 'fas fa-clone',
		},
		// this is the order we want association types to appear in
		association_type_order: [
			'exactMatchOf',
			'ext:isNearExactMatch',
			'ext:isCloselyRelatedTo',
			'ext:isModeratelyRelatedTo',
			'isRelatedTo',
			'ext:hasNoMatch',
			'precedes',
			'replacedBy',
			'hasSkillLevel',
			'isPartOf',
			'isPeerOf',
			'exemplar',
			'isTranslationOf',
			'isChildOf',
			'copiedFromSource',
			'aliasOf',
			'aliasOfExternal',
		],

		// for ItemCopyInterface: remember these as long as the user doesn't reload the browser
		item_copy_duplicate_children: true,
		item_copy_use_sourceItemIdentifier: true,
		item_copy_make_aliases: true,
		item_copy_add_associations: false,
		item_copy_association_type: 'exactMatchOf',

		// for item import interface
		import_interface_show_advanced_options: false,
		import_interface_convert_newlines_to_spaces: true,

		// for update reports and archives
		update_records_timestamp: 0,
		update_records: null,
		update_record_ref_dates: null,
		archive_comps_timestamp: {},
		archive_comps: {},	// this is used for the update report
		archive_json: {},	// this is use for tracking changes
		last_managed_archive: null,
		last_framework_update_report: null,
		last_viewed_item_from_archive_comparison: null,
		change_type_filters_showing: {},

		usage_data: null,

		PDF_import_interface_last_pdf_data: null, // last pdf opened in the pdf import interface

		crosswalk_selected_left_item: {},
		crosswalk_selected_right_item: {},
		crosswalk_available_left_items: {},
		crosswalk_available_right_items: {},
		crosswalk_revealed_left_item: {},
		crosswalk_revealed_right_item: {},
		crosswalk_chosen_left_item: {},
		crosswalk_chosen_right_item: {},
		last_crosswalk_opened: '',

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// foo: {
		// 	get() { return this.$store.state.lst.foo },
		// 	set(val) { this.$store.commit('lst_set', ['foo', val]) }
		// },
		// @update:foo="(val)=>foo=val"
		lst: {
			frameworks_or_resources_toggle: 'frameworks',	// set in App -- 'frameworks' (default) or 'resources'
			framework_list_view_mode: '',	// set in FrameworkList
			framework_list_header_dismissed: false,
			framework_list_show_mirrors_only: false,
			framework_list_show_sandboxes: true,
			framework_list_table_sort_by: 'lastChangeDateTime',
			framework_list_table_sort_desc: true,
			framework_list_show_crosswalks: false,
			framework_list_force_include_all_frameworks: false,

			recent_frameworks: [],

			resource_set_list_table_sort_by: 'created_at',
			resource_set_list_table_sort_desc: true,
			resource_set_show_aligned_resources: true,
			resource_set_show_unaligned_resources: true,
			resource_set_batch_align_settings_showing: false,
			resource_set_batch_max_alignments: 1,
			resource_set_batch_simscore_threshold: 850,
			resource_set_table_sort_by: 'created_at',
			resource_set_table_sort_desc: false,
			resource_set_tba_framework_identifier: '',
			resource_set_currently_aligning: false,
			resource_set_item_alignments_wrap: true,
			resource_set_item_suggestions_wrap: true,
			align_leafs_only: true,
			align_current_resource_id: '',
			align_lowest_ancestor: '',
			align_comp_factors: '',
			align_limiters: {},

			archive_date_to_show: 'archive_framework_date',	// date_archived or archive_framework_date
			archive_col_option_details: false,
			archive_col_option_auto_archives: false,
			archive_update_summary_field: 'update_report_summary',
			update_report_type: 'update_type',
			update_report_by_update_type_sort: 'lastChangeDateTime',
			update_report_by_update_update_types: {
				item_updates_code_or_statement: true,
				item_updates_not_code_or_statement: true,
				item_updates_moved: true,
				items_created: false,
				items_deleted: false,
				assocs_updated: false,
				assocs_created: false,
				assocs_deleted: false,
				document_updated: false,
				dates_only: false,
			},
			update_report_by_framework_identifier: '',
			update_report_archive_compare_arr: null,
			show_identifiers_in_framework_table: false,
			gt_enabled: false,
			single_color_scheme_on: false,
			assoc_framework_menu_items4: {},
			assoc_type_menu_options2: {},
			import_parse_algo: 'algo1',
			import_pre_script: '',
			import_post_script: '',
			import_delimiter: 'tabs',
			make_association_association_type: 'isRelatedTo',
			make_association_save_to_crosswalk_framework_inter: true,
			make_association_save_to_crosswalk_framework_intra: false,
			make_association_all_types_shown: false,
			make_association_right_framework_identifier: '',
			make_association_lowest_ancestor: '',
			make_association_keywords: '',
			font_size: 0,
			font_size_xs: -2,
			font_size_sm: -1,
			exemplar_collapsed: false,
			show_identifiers_in_tiles: false,
			show_color_coded_item_types: true,
			show_comments: false,
			show_associations: true,
			wrap_item_text: true,
			import_metadata_field: '',
			import_metadata_replace_or_append: 'replace',

			default_comment_group_id: {},
			comments_table_items_per_page: 50,
			comments_sort_order: 'date',	// 'date' or 'item'
			public_review_name: '',
			public_review_comments: [],

			associations_table_items_per_page: 50,
			items_table_items_per_page: 50,
			search_grade_low: '',
			search_grade_high: '',
			search_item_types: '',
			search_field_types: '',
			search_item_for_limit: '',
			search_limit_to_item: '',
			pinned_items: '',
			framework_access_report_count_type: 'webapp',
			usage_graph_dataset: 'smoothed',
			print_view_settings: '',
			viewer_mode: 'tree',	// tree, tiles, table
			viewer_table_mode: 'items',	// items, associations

			// see CrosswalkEditor
			crosswalk_interface_showing: 'summary',
			crosswalk_show_alignable_only: {},
			crosswalk_show_already_aligned: {},
			crosswalk_show_not_suggestible: {},
			crosswalk_match_levels: {},
			crosswalk_show_diff_coloring: {},
			crosswalk_table_spacing: {},
			crosswalk_items_per_page: {},
			crosswalk_make_alignments_for_types: {},
			crosswalk_include_branches_0: {},
			crosswalk_include_branches_1: {},
			crosswalk_exclude_branches_0: {},
			crosswalk_exclude_branches_1: {},
			
			use_html_for_supplementalNotes: true,

			track_changes_fn: '',
			track_changes_fields: {
				fullStatement: true,
				humanCodingScheme: false,
				notes: false,
				supplementalNotes: false,
				CFItemType: false,
				educationLevel: false,
				highlight_text_diffs: true,
				// if loose_comparisons is true, we *don't* count changes to punctuation, spacing, or formatting as representing a change
				loose_comparisons: false,
			},
			side_by_side_editor_head_identifier: {},
			side_by_side_show_unchanged_items: true,
			
			association_suggestion_criteria: {
				fullStatement: true,
				humanCodingScheme: true,
				educationLevel: true,
				itemType: true,
				search: false,
				parents: false,
				siblings: false,
				existing_assocs: false,
				advanced: false,
				auto_suggest: false,
			},
			highlight_progression_changes: true,

			embedded_framework_identifier: '',

			associations_table_fields_to_show: '',
			items_table_types_to_show: '',
			items_table_fields_to_show: '',

			froala_image_size: '500',
			froala_paste_mode: 'normal',

			mathlive_advanced_options: false,
			mathlive_chosen_keyboard_index: 1,

			PDF_import_interface_rule_sets: {},
			PDF_import_interface_misc: {
				last_pdf_opened: "",
			},
			PDF_import_interface_pdf_data: {},
			last_custom_script_run: '',
			last_custom_script_input: '',
		},
		lst_prefix: 'sparklsalt_local_storage_setting_',
	},
	getters: {
		signed_in:(state) => { return state.user_info.first_name != '' },
		comments_hash:(state) => {
			// hash of comments referenced by item_identifier; makes looking things up by item easier
			let hash = {}
			for (let i = 0; i < state.comments.length; ++i) {
				let c = state.comments[i]
				if (!hash[c.framework_identifier]) hash[c.framework_identifier] = {}
				if (!hash[c.framework_identifier][c.item_identifier]) hash[c.framework_identifier][c.item_identifier] = []
				hash[c.framework_identifier][c.item_identifier].push(c)
			}

			// add public_review_comments (but don't double-add)
			// console.log('comments_hash', state.lst.public_review_comments.length)
			for (let i = 0; i < state.lst.public_review_comments.length; ++i) {
				let c = state.lst.public_review_comments[i]
				if (!hash[c.framework_identifier]) hash[c.framework_identifier] = {}
				if (!hash[c.framework_identifier][c.item_identifier]) hash[c.framework_identifier][c.item_identifier] = []
				if (!hash[c.framework_identifier][c.item_identifier].find(x=>x.comment_id == c.comment_id)) {
					hash[c.framework_identifier][c.item_identifier].push(new Comment(c))
				}
			}

			return hash
		},
		show_identifiers_in_tiles:(state) => { 
			// if we're in embedded_mode mode, never show identifiers in tiles
			if (state.embedded_mode) return false
			return state.lst.show_identifiers_in_tiles 
		},
		filtered_framework_records:(state) => {
			// this returns a list of framework records filtered by various things that we usually want to filter on

			// extract included_categories from site_config; if non-null, limit to these categories
			let included_categories = state.site_config.included_categories
			if (included_categories) included_categories = included_categories.split(/\s*,\s*/)

			// if we have included_subjects, limit by that too
			let included_subjects = state.site_config.included_subjects
			if (included_subjects) included_subjects = included_subjects.split(/\s*,\s*/)	
			// note that these should be `subject_guess_string` values, e.g. 'Math,English,Science,Social Studies',	

			// backdoor: if user has system admin rights, they can click a checkbox to see all categories and subjects
			if (state.lst.framework_list_force_include_all_frameworks && vapp.is_granted('super')) {
				included_categories = null
				included_subjects = null
			}

			let arr = []
			for (let framework_record of state.framework_records) {
				let ls_doc = framework_record.json.CFDocument

				// filter on included_subjects if we have them
				if (included_subjects) {
					// get guess for first subject; if it isn't in included_subjects, continue
					let subject = U.framework_subject_guess(framework_record.json.CFDocument)['subject_guess_string']
					if (!included_subjects.includes(subject)) continue
				}

				// if user isn't authorized to view this framework, skip it
				if (!vapp.is_granted('view_framework', ls_doc.identifier)) continue

				// also for our purposes here, skip crosswalk frameworks (which only include associations)
				if (ls_doc.frameworkType == 'crosswalk') continue

				// try to get an entry for the framework_record's category
				let category = framework_record.ss_framework_data.category

				// if included_categories are specified and this doesn't match an included_category, don't show it
				if (included_categories) {
					// if the framework hasn't been assigned a category, don't include
					if (!category) continue
					let found = false
					for (let ic of included_categories) {
						// note that we look here for map_labels
						if (category.indexOf('[[' + ic + ']]') > -1) {
							found = true
							break
						}
					}
					if (!found) continue
				}

				arr.push(framework_record)
			}
			return arr
		},
		crosswalk_framework_hash:(state) => {
			let hash = {}
			for (let framework_record of state.framework_records) {
				let ls_doc = framework_record.json.CFDocument

				// if user isn't authorized to view this framework, skip it
				// ? for now always include them in the hash
				// if (!vapp.is_granted('view_framework', ls_doc.identifier)) continue

				if (ls_doc.frameworkType == 'crosswalk') {
					// the two frameworks that are crosswalked should be in the title
					// "Crosswalk: “Mathematics - Georgia Standards of Excellence” <-> “California Common Core State Standards - Mathematics” [23a8e45a-9d5a-11e7-81bc-064e21a83c7c <-> c6487102-d7cb-11e8-824f-0242ac160002]"
					if (ls_doc.title.search(/“(.*?)” <-> “(.*?)” \[(.*?) <-> (.*?)\]/) > -1) {
						let o = {
							crosswalk_identifier: ls_doc.identifier,
							title1: RegExp.$1,
							title2: RegExp.$2,
							identifier1: RegExp.$3,
							identifier2: RegExp.$4,
						}
						if (!hash[o.identifier1]) hash[o.identifier1] = []
						if (!hash[o.identifier2]) hash[o.identifier2] = []
						hash[o.identifier1].push(o)
						hash[o.identifier2].push(o)
					} else {
						console.warn('couldn’t get crosswalked frameworks for crosswalk', extobj(framework_record))
					}
				}
			}
			return hash
		},
		enable_archive_access:(state) => {
			// always allow archive access to system admins
			if (state.user_info.system_role == 'admin') return true
			// else if site_config.enable_archive_access is 'no', disable access
			if (state.site_config.enable_archive_access == 'no') return false
			// by default, access is enabled
			return true
		},

	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// this.$store.commit('set', [obj, 'PUSH', 1])	// push 1 onto obj, which must be an array in this case
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			if (typeof(key) == 'string') {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// in this case val will be the 'start' index for splice
					// if we got a fourth and fifth value in payload, payload[3] is the 'deleteCount' index for splice (i.e. 0, meaning that we're adding without deleting)
					if (!empty(payload[3]) && !empty(payload[4])) {
						o.splice(val, payload[3], payload[4])

					// else if we got a fourth value in payload (this is more common), replace the old value with the new value
					} else if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])

					// otherwise just take the val-th item out
					} else {
						o.splice(val, 1)
					}
				} else if (val == '*DELETE_FROM_STORE*') {
					// delete the val if it existed (if it didn't exist, we don't have to do anything)
					if (o[key] != undefined) Vue.delete(o, key)
				} else if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}
			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else if (val == '*DELETE_FROM_STORE*') Vue.delete(o, key[i])
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['temp', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		push(state, payload) {
			// this.$store.commit('push', ['key', val])
			// push value 'val' onto property 'key', which must be an array
			if (payload.length == 2 && typeof(payload[0]) == 'string') {
				state[payload[0]].push(payload[1])
				return
			}

			// this.$store.commit('push', [obj, val])
			// push value onto array property of obj, **WHICH MUST BE PART OF STATE!**
			var o = payload[0]
			var val = payload[1]
			o.push(val)
		},

		add_to_array(state, payload) {
			// add to the array, checking first to make sure it's not already there
			// this.$store.commit('add_to_array', [array, old_val])
			let arr = payload[0]
			let val = payload[1]

			// if it doesn't already exist, add to the array
			if (arr.findIndex(x=>x==val) == -1) {
				arr.push(val)
			}
		},

		replace_in_array(state, payload) {
			// this.$store.commit('replace_in_array', [array, old_val, new_val])
			let arr = payload[0]

			// try to find the index of the old_val; caller can send either a value to look for directly, or a property and a value
			let i, new_val
			if (payload.length == 3) {
				let old_val = payload[1]
				new_val = payload[2]
				i = arr.findIndex(x=>x==old_val)
			} else {
				let prop = payload[1]
				let old_val = payload[2]
				new_val = payload[3]
				i = arr.findIndex(x=>x[prop]==old_val)
			}

			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1, new_val)
			} else {
				// else push
				arr.push(new_val)
			}
		},

		splice_from_array(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let old_val = payload[1]

			// try to find the index of the old_val
			let i = arr.findIndex(x=>x==old_val)
			if (i > -1) {
				// if found, remove from array
				arr.splice(i, 1)
			}
		},

		splice_from_array_by_index(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let i = payload[1]

			arr.splice(i, 1)
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		// use this for hashes where we need different values for different contexts (e.g. different frameworks in Satchel)
		// this.$store.commit('lst_set_hash', ['lst_key_name', lsdoc_identifier, val])
		lst_set_hash(state, payload) {
			let key = payload[0]
			let key_key = payload[1]
			let val = payload[2]

			// copy the hash, then set the val for key_key, then set state.lst
			let o = $.extend(true, {}, state.lst[key])
			o[key_key] = val
			state.lst[key] = o

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, o)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},

		item_moved(state, payload) {
			// this is called by a computed property in CASETree and CASEItem when an item is drag-and-drop moved into place.
			// payload will include the framework_record we're updating, the parent_node...
			let [framework_record, parent_node, new_children_array] = payload
		},

		write_site_config(state) {
			// index.html includes site_config.php, which just writes this out for us; app.vue then calls commit.write_site_config so we have the config data right away (even before the initialize_app service runs)

			// just to be safe, if we don't yet have post_message_trusted_origins for some reason, call again in 100 ms (see also PostMessageMixin)
			if (empty(window.satchel_site_config)) {
				// but give up after 50 tries
				if (!vapp.write_site_config_try_count) vapp.write_site_config_try_count = 1
				if (vapp.write_site_config_try_count >= 50) return
				++vapp.write_site_config_try_count
				setTimeout(x=>{vapp.$store.commit('write_site_config')}, 100)
				console.log('retrying write_site_config in 100ms')
				return
			}

			// set incoming values property-by-property, so that we retain default values set below
			for (let key in window.satchel_site_config) {
				state.site_config[key] = window.satchel_site_config[key]
			}

			let style_rules = [
				sr(' .v-application .primary { background-color:$1!important; border-color:$1!important; }', state.site_config.primary_color),
				sr(' .k-banner-color { background-color:$1!important; }', state.site_config.banner_color),
				sr(' .k-agency-color { color:$1!important; }', state.site_config.agency_color),
				sr(' .k-banner-color-text { color:$1!important; }', state.site_config.banner_color),
				sr(' .k-app-title-color { color:$1!important; }', state.site_config.app_color),
				sr(' .k-toolbar-agency-img-outer { $1 }', state.site_config.agency_img_outer_css),
				sr(' .k-toolbar-agency-img { $1 }', state.site_config.agency_img_css),
				sr(' .k-toolbar-app-title { padding-left:$1; }', state.site_config.title_left_padding),
				sr(' .k-agency-title-extra { $1 }', state.site_config.agency_name_css),
				sr(' .k-login-logo-wrapper-ext { $1 }', state.site_config.logo_wrapper_css_signin),
				sr(' .k-login__logo-text-ext { $1 }', state.site_config.logo_text_wrapper_css_signin),
				sr(' .k-login__logo-img-wrapper { $1 }', state.site_config.agency_img_outer_css_signin),
				sr(' .k-login__logo-img { $1 }', state.site_config.agency_img_css_signin),
				sr(' .k-sans-serif-font, .v-application, .v-btn, .fr-box.fr-basic .fr-element, .fr-view { font-family:$1!important; }', state.site_config.sans_serif_font),
				sr(' .k-custom-heading-color { color: $1!important; }', state.site_config.heading_color),
				sr(' .k-custom-sub-heading-color { color: $1!important; }', state.site_config.sub_heading_color),
				sr(' .k-custom-border-color { border-color: $1!important; }', state.site_config.dividing_line_color),
			]

			$('head').append('<style type="text/css">' + style_rules.join('\n') + '</style>');

			document.title = state.site_config.index_title
		},
	},
	actions: {
		initialize_app({state, commit, dispatch}, payload) {
			// initialize local_storage settings
			commit('lst_initialize')

			U.loading_start('', 'initialize_app')

			return new Promise((resolve, reject)=>{
				if (empty(payload)) payload = {}

				// if we got '?start=xxx' in the url, save 'xxx' to start_string and send to the initialize_app service; initialize_app and CASEFrameworkViewer will handle what to do with it
				if (location.search.search(/(\?|\&)start=(.*?)(\&|$)/) > -1) {
					state.start_string = RegExp.$2
					// only send start_string to initialize_app if we *don't* have an identifier for the framework in the url
					if (window.location.href.search(/[0123456789abcdef]{8}-[0123456789abcdef]{4}-[0123456789abcdef]{4}-[0123456789abcdef]{4}-[0123456789abcdef]{12}/) == -1) {
						payload.start_string = state.start_string
					}
				}

				U.ajax('initialize_app', payload, result=>{
					console.log('initialized', result)

					// note that site_config comes in separately (as of 4/20/2024; see above)

					if (result.status != 'ok') {
						U.loading_stop('initialize_app')
						reject(result)
						return
					} else if (!empty(result.login_error)) {
						U.loading_stop('initialize_app')
						// the initialization was called from the sign-in screen, so set state.login_error and reject
						state.login_error = result.login_error
						reject()
						return
					}

					state.login_error = ''

					// if we received user_info, the user is logged in.
					if (!empty(result.user_info)) {
						// set user_info in store
						commit('set', ['user_info', new User_Info(result.user_info)])

					} else {
						commit('set', ['user_info', new User_Info({})])
					}

					// store CFG things
					state.use_oidc_login = result.use_oidc_login
					state.use_auth0_login = result.use_auth0_login
					state.use_msft_oidc_login = result.use_msft_oidc_login
					state.cgrt_ip = result.cgrt_ip
					state.froala_key = result.froala_key
					state.require_sign_in_for_full_functionality = result.require_sign_in_for_full_functionality
					state.enable_ap_export = result.enable_ap_export
					state.framework_domain = result.framework_domain
					state.framework_access_report_fields = result.framework_access_report_fields
					state.start_string_regexps = result.start_string_regexps
					state.start_string_identifiers = result.start_string_identifiers	// this may or may not come in; it will have a value if we have a ?start=xxx param but no framework identifier
					state.all_course_metadata_framework_identifier = result.all_course_metadata

					// allow a subdomain's site_config to override some settings from the config file
					if (state.site_config.require_sign_in_for_full_functionality) state.require_sign_in_for_full_functionality = (state.site_config.require_sign_in_for_full_functionality == 'true')

					window.create_CASE_Custom_Extension_Fields(result.custom_extensions)

					// set today, for dealing with statusStartDates/statusEndDates
					state.today = date.format(new Date(), 'YYYY-MM-DD')

					// get the basic framework_records list, if we haven't already done so
					if (state.framework_records.length == 0) {
						dispatch('get_lsdoc_list')
						// if we call get_lsdoc_list, we will stop the loader once it's done; otherwise stop it now
					} else {
						U.loading_stop('initialize_app')
					}

					// resolve, sending back the token_result param so that vapp can doe something with it
					resolve(result.token_result)
				})
			})
		},

		// U.loading_start()
		// let payload = {
		// 	service_url: 'user/save_user_updates',
		// 	other_data: 'foo',
		// }
		// this.$store.dispatch('service', payload).then((result)=>{
		// 	U.loading_stop()
		// 	// deal with result
		//
		// }).catch((result)=>{
		// 	U.loading_stop()
		// 	this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
		// }).finally(()=>{})
		service({state, commit, dispatch}, payload) {
			let service_url
			if (typeof(payload) == "string") {
				service_url = payload
				payload = {}
			} else {
				service_url = payload.service_url
				delete payload.service_url
			}

			return new Promise((resolve, reject)=>{
				U.ajax(service_url, payload, result=>{
					if (result.status != 'ok') {
						console.log('Error in service ' + service_url, result)
						reject(result)
						return
					}

					resolve(result)
				})
			})
		},

		get_lsdoc_list({state, commit, dispatch}) {
			// note that the loader should already have been initiated by the initialize service
			return new Promise((resolve, reject)=>{
				U.ajax('get_framework_list', {}, result=>{
					if (result.status != 'ok') {
						U.loading_stop('initialize_app')
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the framework list.')
						reject()
						return
					}

					// construct list of framework_categories as we process the framework_records
					let fcats = []
					state.framework_records = []
					for (let record of result.records) {
						// console.log(record.framework_identifier)
						let json = JSON.parse(record.json)
						let doc = new CFDocument(json.case_document_json)
						delete(json.case_document_json)
						let framework_record = U.create_framework_record(doc.identifier, {CFDocument: doc}, json, false)
						// if mirror, include framework.next_auto_update
						if (!empty(record.next_auto_update)) framework_record.ss_framework_data.next_auto_update = record.next_auto_update

						state.framework_records.push(framework_record)

						if (framework_record.ss_framework_data.category && !fcats.find(x=>x == framework_record.ss_framework_data.category)) {
							fcats.push(framework_record.ss_framework_data.category)
						}
					}

					fcats.sort(U.natural_sort)
					state.framework_categories = fcats

					// set app_initialized to true only after framework_records have been loaded
					state.app_initialized = true

					setTimeout(x=>{ U.loading_stop('initialize_app') }, 0)

					// initiate google translate, and stop the loader, after some time
					setTimeout(x=>{ 
						console.log('initializing google translate')
						vapp.initialize_google_translate() 
					}, 2500)

					resolve()
				});
			})
		},

		// given an item_identifier, return the lsdoc_identifier that houses the item
		// note that U.get_lsdoc_identifier_from_item_identifier will first look in frameworks that we've already loaded.
		get_lsdoc_identifier_from_item_identifier({state, commit, dispatch}, item_identifier) {
			return new Promise((resolve, reject)=>{
				U.ajax('get_lsdoc_identifier_from_item_identifier', {item_identifier: item_identifier}, result=>{
					if (result.status != 'ok') {
						reject(result.status)
						return
					}
					resolve(result.lsdoc_identifier)
				})
			})
		},

		get_lsdoc({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				let lsdoc_identifier, archive_filename, exemplar_archive_fn
				let payload_to_send = {}
				// payload could just be a string, which represents the lsdoc_identifier
				if (typeof(payload) == 'string') {
					lsdoc_identifier = payload
					archive_filename = null
					exemplar_archive_fn = null
				} else {
					// or it could be an object that must specify the lsdoc_identifier and might specify additional info...
					lsdoc_identifier = payload.lsdoc_identifier

					// an archive_filename could be sent in...
					if (payload.archive_filename) archive_filename = payload.archive_filename
					if (payload.exemplar_archive_fn) exemplar_archive_fn = payload.exemplar_archive_fn

					// and/or a `record_access` value of true could be sent in,
					if (payload.record_access) {
						// in which case we send access_type, screen_size, user_id, and framework_identifier
						payload_to_send = {
							access_type: 'webapp',	// note that we're accessing the framework through the web application
							screen_size: (vapp.$vuetify.breakpoint.xs || vapp.$vuetify.breakpoint.sm) ? 'small' : 'normal',
							user_id: state.user_info.user_id,
							// have to collect the user's IP address via the client (see index.html)
							ip_address: window.client_ip_address,
							framework_identifier: lsdoc_identifier
						}
					}
				}

				// by default we load the current json; but if archive_filename is specified, load the archived json
				let filepath
				if (empty(archive_filename)) filepath = sr('frameworks/$1.json', lsdoc_identifier)
				else filepath = sr('framework_archives/$1', archive_filename)

				// if we have a framework record for this framework already, set framework_json_loading to true
				let fr = state.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
				if (fr) commit('set', [fr, 'framework_json_loading', true])

				// TODO: get_json_file will throw an error if the json is malformed; need to test what happens if that happens.
				U.get_json_file(filepath, payload_to_send, (json, may_have_latex) =>{
					// the json param should hold the CASE JSON for the framework, already JSON.parse'd
					// if it's a string, or if the json doesn't have a CFDocument, something went wrong
					if (typeof(json) == 'string' || !json.CFDocument) {
						console.log('!!!Error in get_lsdoc', json)
						// set framework_json_loaded to true so we don't keep trying to load the framework
						let fr = state.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
						if (fr) {
							commit('set', [fr, 'framework_json_loading', false])
							commit('set', [fr, 'framework_json_loaded', true])
						}
						reject(json)
						return
					}

					// if the CASE json may have latex, initialize the MathJax parser
					if (may_have_latex) U.mathjax.initialize()

					// if the requested framework is one that is loaded into Satchel, the framework_record should already exist when this is called,
					let fr = state.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
					if (fr) {
						// so all we have to do is store the json and set framework_json_loaded to true, which framework_record_load_json does
						U.framework_record_load_json(fr, json)
					} else {
						// the requested framework wasn't one that we know about
						console.log('framework_record not found')
					}
					// note that we don't set framework_json_loading to false here; that is left for the caller to do, in case it needs to also process the cfo
					resolve()
				})
			})
		},

		// retrieves a framework's sparkl_bot_vectors. Note that even if we already have the vectors for a framework, we may call this to refresh them
		get_framework_sparkl_bot_vectors({state, commit, dispatch}, framework_identifier) {
			let payload = { framework_identifier: framework_identifier }
			// caller should show loader if they wish to do so
			// U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_framework_sparkl_bot_vectors', payload, result=>{
					// U.loading_stop()

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the framework’s search vectors.')
						reject()
						return
					}

					// add to framework_records
					let r = state.framework_records.find(x=>x.lsdoc_identifier==framework_identifier)
					if (r) r.sparkl_bot_vectors = JSON.parse(result.vector_hash)
					resolve()
				});
			})
		},

		load_framework_archive_list({state, commit, dispatch}, lsdoc_identifier) {
			return new Promise((resolve, reject)=>{
				let payload = {
					user_id: state.user_info.user_id,
					framework_identifier: lsdoc_identifier,
				}

				U.loading_start()
				U.ajax('get_archive_list', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in get_archive_list ajax call'); vapp.ping(); return;
					}

					// result.framework_archives.sort((a,b)
					// records will come in sorted with oldest first

					// PW: for now let's not show the current checkout archive; I think it's confusing
					// TODO for MZ: but if there have been edits since the checkout archive was created, don't set it as open_edit_archive='yes', because that effectively has become a permanent archive now (it won't be deleted)
					let framework_archives = []
					let last_comparison_summary = null
					for (let a of result.framework_archives) {
						if (a.open_edit_archive != 'yes') {
							a.comparison_summary = last_comparison_summary
							last_comparison_summary = a.comparison_summary_to_last
							delete a.comparison_summary_to_last

							if (a.update_report_summary) a.update_report_summary = JSON.parse(a.update_report_summary)

							framework_archives.push(a)
						}
					}

					// add a row for the current state
					let framework_record = state.framework_records.find(x=>x.lsdoc_identifier == lsdoc_identifier)
					if (framework_record) {		// we should always find the framework record, but just in case...
						framework_archives.push({
							timestamp: date.format(new Date(), 'YYYY-MM-DD HH:mm:ss', true),
							item_count: !empty(framework_record.ss_framework_data.item_count) ? framework_record.ss_framework_data.item_count : framework_record.json.CFItems.length,
							assoc_count_not_child: !empty(framework_record.ss_framework_data.assoc_count_not_child) ? framework_record.ss_framework_data.assoc_count_not_child : framework_record.json.CFAssociations.length,
							archive_type: 'current',
							archive_lastChangeDateTime: framework_record.json.CFDocument.lastChangeDateTime,
							archive_fn: '',
							comparison_summary: last_comparison_summary,
						})
					}

					// set in framework_archives
					commit('set', [state.framework_archives, lsdoc_identifier, framework_archives])

					resolve()
					// format for each archive: { archive_fn, archive_lastChangeDateTime, archive_note, archive_type, assoc_count_not_child, association_count, comparison_summary, email, item_count, open_edit_archive, restored_from_timestamp, timestamp, update_report_summary }
				});
			})
		},

		reduce_case_json({state, commit, dispatch}, payload) {
			// this is just for debugging purposes
			// payload must include `json` and a list of `fns` to run:
			// vapp.$store.dispatch('reduce_case_json', {lsdoc_identifier: vapp.case_tree_component.framework_record.lsdoc_identifier, fns:['remove_empty_properties']})
			return new Promise((resolve, reject)=>{
				let fr = state.framework_records.find(x=>x.lsdoc_identifier == payload.lsdoc_identifier)
				if (empty(fr) || empty(fr.json)) {
					vapp.$alert('couldn’t find framework')
					return
				}

				U.reduce_case_json(vapp.$worker, fr.json, payload.fns).then((new_json)=>{
					console.log('done', new_json)
				})
			})
		},

		delete_lsdoc({state, commit, dispatch}, lsdoc_identifier) {
			let payload = {
				// user_id
				lsdoc_identifier: lsdoc_identifier
			}
			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('delete_framework', payload, result=>{
					U.loading_stop()

					// if the action can't proceed because of an edit lock conflict, just show the error message then reload the window, which will cancel edit mode
					if (result.status == 'lock_conflict' || result.status == 'session_conflict') {
						vapp.$alert(result.message).then(x=>window.location.reload())
						reject()
						return
					}

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to delete the framework.')
						reject()
						return
					}

					// remove from framework_records
					let index = state.framework_records.findIndex(x=>x.lsdoc_identifier==lsdoc_identifier)
					if (index > -1) {
						state.framework_records.splice(index, 1)
					}

					resolve()
				});
			})
		},

		archive_lsdoc({state, commit, dispatch}, payload) {
			// payload should include lsdoc_identifier and an optional `phrase` for the archive filename
			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('archive_framework', payload, result=>{
					U.loading_stop()

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to archive the framework.')
						reject()
						return
					}

					resolve(result.archive_time)
				});
			})
		},

		// user may initiate a sync check to mirror source, if mirror changes an archive is saved
		manual_mirror_update({state, commit, dispatch}, payload) {
			U.loading_start()
			return new Promise((resolve, reject)=>{
				// import_mirror_framework hands off to mirror_update, which also handles auto updates
				U.ajax('import_mirror_framework', payload, result=>{
					U.loading_stop()

					// if fail, still send result to caller so mirror info may be updated with fail data
					if (result.status !== 'ok') {
						console.log(sr('Error in mirror_update_by_user: $1', result.update_status));
						vapp.$alert("There was a problem checking this mirror's source for updates.")
					}
					resolve(result)
				})
			})
		},

		save_framework_data({state, commit, dispatch}, payload) {
			// payload.user_id = state.user_info.user_id
			let show_spinner = true
			if (payload.show_spinner === false) {
				show_spinner = false
				delete payload.show_spinner
			}

			// if update_item_types is true and payload includes any CFItems, deal with CFItemTypes
			// note: the caller can specifying to make *some* changes to CFItems, without sending all properties of the CFItems in.  we only want to run keep_item_types_in_sync in situations where the caller is actually specifying the CFItemType information about the to-be-updated items, because if we run keep_item_types_in_sync when the CFItemTypes are not specified, keep_item_types_in_sync will think all the CFItems are having their CFItemTypes deleted
			// note: by running this on payload, ItemEditor will update CFItem values properly upon service completion.
			let update_item_types = false
			if (payload.update_item_types && payload.CFItems && payload.CFItems.length > 0) {
				U.keep_item_types_in_sync(payload)
				update_item_types = true
				delete payload.update_item_types
			}

			let payload_to_send = $.extend(true, {}, payload)

			if (!empty(payload_to_send.CFItems)) payload_to_send.CFItems = JSON.stringify(payload_to_send.CFItems)
			if (!empty(payload_to_send.CFAssociations)) payload_to_send.CFAssociations = JSON.stringify(payload_to_send.CFAssociations)
			if (!empty(payload_to_send.CFDocument)) payload_to_send.CFDocument = JSON.stringify(payload_to_send.CFDocument)
			if (!empty(payload_to_send.CFDefinitions)) payload_to_send.CFDefinitions = JSON.stringify(payload_to_send.CFDefinitions)
			if (!empty(payload_to_send.CFRubrics)) payload_to_send.CFRubrics = JSON.stringify(payload_to_send.CFRubrics)

			// send update_document_date=yes to always update the document date, unless update_document_date is explicitly passed in through payload
			if (empty(payload.update_document_date)) payload_to_send.update_document_date = 'yes'

			if (show_spinner) U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('save_framework_data', payload_to_send, result=>{
					if (show_spinner) U.loading_stop()

					// if the action can't proceed because of an edit lock conflict, just show the error message then reload the window, which will cancel edit mode
					if (result.status == 'lock_conflict' || result.status == 'session_conflict') {
						vapp.$alert(result.message).then(x=>window.location.reload())
						reject()
						return
					}

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to save the standards data.')
						reject()
						return
					}

					// if update_document_date was 'yes', update the lastChangeDateTime value in the framework_record
					if (payload_to_send.update_document_date == 'yes') {
						let index = state.framework_records.findIndex(x=>x.lsdoc_identifier == payload.lsdoc_identifier)
						if (index > -1) {
							state.framework_records[index].json.CFDocument.lastChangeDateTime = result.document_lastChangeDateTime
						}
					}

					// if update_item_types is true and we sent CFItemType values, update them in the store here, so caller isn't responsible for these
					if (update_item_types && payload.CFDefinitions?.CFItemTypes) {
						let fr = state.framework_records.find(x=>x.lsdoc_identifier == payload.lsdoc_identifier)
						if (fr) {
							if (!fr.json.CFDefinitions) fr.json.CFDefinitions = {}
							if (payload.CFDefinitions.CFItemTypes == '*CLEAR*') {
								fr.json.CFDefinitions.CFItemTypes = []
							} else {
								// note that we always save CFItemTypes as a block, so we can always keep them in synch here as a block; we just have to update lastChangeDateTimes
								for (let cit of payload.CFDefinitions.CFItemTypes) {
									if (cit.lastChangeDateTime == '*NOW*') cit.lastChangeDateTime = result.document_lastChangeDateTime
								}
								fr.json.CFDefinitions.CFItemTypes = payload.CFDefinitions.CFItemTypes
							}
						}
					}

					// caller is responsible for updating other store values, and may need to access result.document_lastChangeDateTime in the process of making such updates, i.e:
					// this.CFDocument.lastChangeDateTime = result.document_lastChangeDateTime
					commit('set', ['framework_lastChangeDateTime', result.document_lastChangeDateTime])

					// do we need this anymore? let's not do it if we're not showing the spinner
					if (show_spinner) dispatch('reset_service');

					// show "saved" indicator
					if (show_spinner) vapp.show_saved_indicator()

					resolve(result)
				});
			})
		},

		save_framework_categories({state, commit, dispatch}, framework_updates) {
			// console.log(framework_updates)
			let payload = {
				framework_updates: framework_updates
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('admin_save_framework_categories', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error updating framework categories')
						reject()
						return
					}
					// we don't have to do anything on return
					resolve()
				});
			})
		},

		delete_framework_items({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				let {framework_record, nodes_to_delete} = payload
				let json = framework_record.json

				// delete from the framework file/DB
				let data = {
					lsdoc_identifier: framework_record.lsdoc_identifier,
					CFItems: [],
					CFAssociations: []
				}

				// recursively add CFItems
				let add_cfitem_to_delete = (node) => {
					// note that we always purge this node
					nodes_to_purge.push(node)

					// if this is the only instance of the item in the tree, wipe it out completely
					if (node.cfitem.tree_nodes.length == 1) {
						console.log('wiping out: ' + node.cfitem.fullStatement)
						data.CFItems.push({ identifier: node.cfitem.identifier, delete: 'yes' })
						U.delete_item_from_json(framework_record, node.cfitem.identifier)
						// add associations to this cfitem identifier so we delete those too
						for (let i = framework_record.json.CFAssociations.length-1; i >= 0; --i) {
							let cfa = framework_record.json.CFAssociations[i]
							// remove any association whose origin OR destination is this identifier
							if (cfa.originNodeURI.identifier == node.cfitem.identifier || cfa.destinationNodeURI.identifier == node.cfitem.identifier) {
								if (!data.CFAssociations.find(x=>x.identifier == cfa.identifier)) {
									data.CFAssociations.push({ identifier: cfa.identifier, delete: 'yes' })
									U.delete_association_from_json(framework_record, cfa.identifier)
								}
							}
						}

						// in this case we also recurse to delete the item's children
						for (let child_node of node.children) {
							add_cfitem_to_delete(child_node)
						}

					} else {
						console.log('removing assoc only for: ' + node.cfitem.fullStatement)
						// else we just remove the association placing the item in this position in the tree
						for (let i = framework_record.json.CFAssociations.length-1; i >= 0; --i) {
							let cfa = framework_record.json.CFAssociations[i]
							// this item (origin) ->isChildOf-> parent item (destination)
							if (cfa.associationType == 'isChildOf' && cfa.originNodeURI.identifier == node.cfitem.identifier && cfa.destinationNodeURI.identifier == node.parent_node.cfitem.identifier) {
								if (!data.CFAssociations.find(x=>x.identifier == cfa.identifier)) {
									data.CFAssociations.push({ identifier: cfa.identifier, delete: 'yes' })
									U.delete_association_from_json(framework_record, cfa.identifier)
								}
							}
						}

						// and in this case *don't* delete the item's children
					}
				}

				// add all nodes and their children to delete
				let nodes_to_purge = []
				for (let node of nodes_to_delete) {
					add_cfitem_to_delete(node)
				}

				// there may now be some items that are orphaned, so remove them too.
				// That is, any item that (doesn't have an association where it is an origin) -- i.e. (isn't a child of something) -- should be deleted
				//    also delete all associations where that item is the destination
				// keep doing this until we don't change anything; this removes orphan children, grandchildren, etc.
				// this assumes that the framework does not include *items* that aren't part of the tree structure
				// the framework *can* include *associations* referring to items that *were never* included in the tree structure
				// use U.reduce_case_json.remove_orphan_items for this, in part because it runs much faster in a worker
				U.reduce_case_json(vapp.$worker, framework_record.json, 'remove_orphan_items').then((data_from_remove_orphans)=>{
					console.log('remove_orphan_items', data_from_remove_orphans)

					// merge return value with data and delete items/associations from json
					for (let item of data_from_remove_orphans.CFItems) {
						console.log('adding item to delete: ' + item.identifier)
						data.CFItems.push(item)
						U.delete_item_from_json(framework_record, item.identifier)
					}
					for (let association of data_from_remove_orphans.CFAssociations) {
						data.CFAssociations.push(association)
						U.delete_association_from_json(framework_record, association.identifier)
					}

					// need to purge nodes and send deletions to server here, in the callback after the worker has run
					for (let i = nodes_to_purge.length-1; i >= 0; --i) {
						U.delete_node_from_cfo(framework_record.cfo, nodes_to_purge[i])
					}

					console.log('deleting...', data)

					data.update_item_types = true	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes

					// note that we have already purged the nodes and json, so we don't need to do anything about those when we get back from the service call
					dispatch('save_framework_data', data).then(()=>{
						U.loading_stop()

						// but we will re-run update_frameworks_with_associations, in case any assocs or copies need to be updated in the tree view associations display
						vapp.case_tree_component.update_frameworks_with_associations()

						resolve()
					}).catch(e=>{
						// but if there was a problem, the user needs to reload
						U.loading_stop()
						vapp.$alert('A problem occurred when attempting to delete the items. You should reload your browser window now.').then(x=>window.location.reload())
					})

				}).catch(x=>{
					console.log('error in remove_orphan_items', x)
					U.loading_stop()
				})
			})
		},

		delete_associations({state, commit, dispatch}, payload) {
			// this is for deleting non-isChildOf associations
			return new Promise((resolve, reject)=>{
				let {framework_record, associations_to_delete, check_out_and_in} = payload

				let data = {
					lsdoc_identifier: framework_record.lsdoc_identifier,
					CFAssociations: []
				}
				if (check_out_and_in == 'yes') data.check_out_and_in = 'yes'

				for (let assoc of associations_to_delete) {
					data.CFAssociations.push({
						identifier: assoc.identifier,
						delete: 'yes',
					})
					// remove from associations_hash and from json
					U.remove_from_associations_hash(framework_record.cfo, assoc)
					U.delete_association_from_json(framework_record, assoc.identifier)

					// update associations showing in the tree, if showing
					if (vapp.case_tree_component) vapp.case_tree_component.update_association_display({assoc: assoc, actions: ['remove']})
				}

				console.log('deleting associations...', data)

				// note that we have already purged the json, so we don't need to do anything when we get back from the service call
				data.show_spinner = false
				dispatch('save_framework_data', data).then(()=>{
					resolve()
				}).catch(e=>{
					// but if there was a problem, the user needs to reload
					U.loading_stop()
					vapp.$alert('A problem occurred when attempting to delete the association. You should reload your browser window now.').then(x=>window.location.reload())
				})
			})
		},

		// Commenting functionality
		get_comments_for_framework({state, commit, dispatch}, payload) {
			// payload must include framework_identifier; add user_id
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.ajax('get_comments_for_framework', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error retrieving comments')
						reject()
						return
					}
					// console.log(result)

					// splice comment_groups and comments into arrays
					for (let comment_group of result.comment_groups) {
						comment_group = new Comment_Group(comment_group)
						let i = state.comment_groups.findIndex(x=>x.comment_group_id == comment_group.comment_group_id)
						if (i == -1) state.comment_groups.push(comment_group)
						else state.comment_groups.splice(i, 1, comment_group)
					}
					// sort groups by created_at, with later dates (more recently created) on top
					state.comment_groups.sort((a,b)=>U.natural_sort(b.created_at, a.created_at))

					for (let comment of result.comments) {
						comment = new Comment(comment)
						let i = state.comments.findIndex(x=>x.comment_id == comment.comment_id)
						if (i == -1) state.comments.push(comment)
						else state.comments.splice(i, 1, comment)
					}

					// store comments_previous_update_timestamp value for framework_identifier
					state.comments_previous_update_timestamp[payload.framework_identifier] = result.mysql_now

					resolve()
				});
			})
		},

		get_comment_updates_for_framework({state, commit, dispatch}, payload) {
			// payload must include framework_identifier; add user_id and previous_update_timestamp
			payload.user_id = state.user_info.user_id
			payload.previous_update_timestamp = state.comments_previous_update_timestamp[payload.framework_identifier]

			return new Promise((resolve, reject)=>{
				U.ajax('get_comment_updates_for_framework', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error retrieving comment updates')
						reject()
						return
					}
					// console.log(result)

					// splice comment_groups and comments into arrays, removing any that have been archived
					for (let comment_group of result.comment_groups) {
						let i = state.comment_groups.findIndex(x=>x.comment_group_id == comment_group.comment_group_id)
						if (comment_group.archived == 1) {
							if (i > -1) {
								// remove archived comment_group
								state.comment_groups.splice(i, 1)

								// also remove any comments attached to this group
								for (let j = state.comments.length-1; j >= 0; --j) {
									if (state.comments[j].comment_group_id == comment_group.comment_group_id) {
										state.comments.splice(j, 1)
									}
								}
							}

						} else {
							// add/replace new/updated comment group
							comment_group = new Comment_Group(comment_group)
							if (i == -1) state.comment_groups.push(comment_group)
							else state.comment_groups.splice(i, 1, comment_group)
						}
					}

					// return counts for comment updates
					let rr = {
						archive_count: 0,
						update_count: 0,
						new_count: 0,
					}
					for (let comment of result.comments) {
						let i = state.comments.findIndex(x=>x.comment_id == comment.comment_id)
						if (comment.archived == 1) {
							if (i > -1) {
								state.comments.splice(i, 1)
								++rr.archive_count
							}
						} else {
							comment = new Comment(comment)
							if (i == -1) {
								state.comments.push(comment)
								++rr.new_count
							} else {
								state.comments.splice(i, 1, comment)
								++rr.update_count
							}

							// make sure updated comments are marked as not read
							if (state.user_info.comment_reads[comment.comment_id+''] == 1) {
								state.user_info.comment_reads[comment.comment_id+''] = 0
								dispatch('save_user_account_data')
							}
						}
					}

					// store comments_previous_update_timestamp value for framework_identifier
					state.comments_previous_update_timestamp[payload.framework_identifier] = result.mysql_now

					// console.log('comments updated', rr)

					resolve(rr)
				});
			})
		},

		save_comment({state, getters, commit, dispatch}, comment) {
			let payload = {
				user_id: state.user_info.user_id,
				comment_id: comment.comment_id,
				item_identifier: comment.item_identifier,
				framework_identifier: comment.framework_identifier,
				comment_group_id: comment.comment_group_id,
				parent_comment_id: comment.parent_comment_id,
				author_user_id: comment.author_user_id,
				first_name: comment.first_name,
				last_name: comment.last_name,
				body: comment.body,
				suggested_edits: comment.suggested_edits,
				attn_user_id: comment.attn_user_id,
				pinned: comment.pinned,
				resolved: comment.resolved,
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_comment', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						if (getters.signed_in) {
							vapp.ping()		// call ping to check if the session is expired
							console.log('Error updating comment')
						} else {
							vapp.$alert('Error saving comment')
						}
						reject()
						return
					}

					// if the comment didn't already exist, insert into comments, after updating with returned data
					if (comment.comment_id == 0) {
						comment.comment_id = result.comment_id
						comment.created_at = result.created_at
						// create a new comment class object so we know things will be identical if the user re-edits
						comment = new Comment(comment)
						state.comments.push(comment)

					// else splice the new comment in where the old one was, to make sure all attributes get updated
					} else {
						// create a new comment class object so we know things will be identical if the user re-edits
						comment = new Comment(comment)
						let i = state.comments.findIndex(x=>x.comment_id == comment.comment_id)
						if (i == -1) state.comments.push(comment)
						else state.comments.splice(i, 1, comment)
					}

					resolve(comment)
				});
			})
		},

		save_comment_resolved({state, commit, dispatch}, comment) {
			let payload = {
				user_id: state.user_info.user_id,
				comment_id: comment.comment_id,
				resolved: comment.resolved,
			}

			return new Promise((resolve, reject)=>{
				U.ajax('save_comment_resolved', payload, result=>{
					// note: caller should update resolved value in comment state
					resolve()
				});
			})
		},

		archive_comment({state, commit, dispatch}, comment) {
			let payload = {
				user_id: state.user_info.user_id,
				comment_id: comment.comment_id,
			}
			return new Promise((resolve, reject)=>{
				// if comment_id is 0, it's a newly-created comment that was aborted, so we just have to remove it from state
				if (comment.comment_id == 0) {
					let index = state.comments.findIndex(o=>o == comment)
					state.comments.splice(index, 1)
					resolve()
					return
				}

				U.loading_start()
				U.ajax('archive_comment', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error archiving comment')
						reject()
						return
					}

					// splice the parent and any children out of comments array
					let index
					do {
						index = state.comments.findIndex(o=>o.comment_id == comment.comment_id || o.parent_comment_id == comment.comment_id)
						if (index > -1) state.comments.splice(index, 1)
					} while (index > -1)

					resolve()
				});
			})
		},

		save_user_account_data({state, commit, dispatch}, comment) {
			let payload = {
				user_id: state.user_info.user_id,
				comment_reads: state.user_info.comment_reads_for_save(),
				notification_settings: JSON.stringify(state.user_info.notification_settings),
			}
			return new Promise((resolve, reject)=>{
				U.ajax('save_user_account_data', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving user account data')
						reject()
						return
					}

					resolve()
				});
			})
		},

		// debugging fn to clear all comment reads for a user
		// vapp.$store.dispatch('clear_comment_reads')
		clear_comment_reads({state, commit, dispatch}, comment) {
			state.user_info.comment_reads = []
			dispatch('save_user_account_data')
		},

		get_usage_data({state, commit, dispatch}) {
			// if we already have usage_data, return
			if (state.usage_data) return
			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_usage_data', {}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Couldn’t get usage data')
					} else {
						state.usage_data = {
							files: result,
						}
					}
				})
			})
		},

		///////////////////////////////
		get_resource_sets({state, commit, dispatch}, flag) {
			return new Promise((resolve, reject)=>{
				// if we've already loaded resource_sets, return, unless flag is 'force_reload'
				if (state.resource_sets.length > 0 && flag != 'force_reload') {
					resolve()
					return
				}

				U.loading_start()
				U.ajax('get_resource_sets', {user_id: state.user_info.user_id}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the Resource Set list.')
						reject()
						return
					}

					state.resource_sets = []
					for (let record of result.resource_sets) {
						let json = JSON.parse(record)
						state.resource_sets.push(new Resource_Set(json))
					}

					resolve()
				});
			})
		},

		get_resource_set_resources({state, commit, dispatch}, resource_set_id) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_resource_set_resources', {user_id: state.user_info.user_id, resource_set_id: resource_set_id}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the Resource Set.')
						reject()
						return
					}

					let json = JSON.parse(result.resource_set)
					// console.log(json)
					let rs = new Resource_Set(json)
					rs.resources_loaded = true

					let i = state.resource_sets.findIndex(x=>x.resource_set_id == resource_set_id)
					if (i == -1) state.resource_sets.push(rs)
					else state.resource_sets.splice(i, 1, rs)

					resolve()
				});
			})
		},

		//////////////////////////////
		reset_service({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				U.ajax('reset', {}, result=>{
					// resolve()
				});
			})
		},

		ping_session({state, commit, dispatch}) {
			vapp.ping()
		},

		////////////////////////////////
		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				U.ajax('sign_out', {}, result=>{
					if (result.status != 'ok') {
						alert('Error signing out!')
						reject()
						return
					}

					// clear the session_id from localstorage (if we're using it), since it's no longer valid
					U.local_storage_clear('satchel_session_id')

					vapp.go_to_route('')
					// and reload the window, to be sure everything is clear
					setTimeout(x=>window.location.reload(), 10)
				});
			})
		},

		// Done by MZ - align responses to consolidated change_management_utils
		// checkout or checkin framework
		// caller supplies:
		// - framework_identifier
		// - edit_actions [framework_checkout, framework_checkin]
		manage_edit_lock({state, commit, dispatch}, payload) {

			return new Promise((resolve, reject)=>{
				U.ajax('manage_edit_lock', payload, result=>{

					if (result.status == 'ok') {
						resolve(result)

					} else if (result.status == 'lock_conflict') {
						vapp.$alert(result.message)
						reject('checkout rejected due to edit conflict')

					} else if (result.status == 'session_conflict') {
						// this user has lock in another php session
						vapp.$confirm({
							text:'You have this framework checked out in a different edit session, would you like to edit in this window?',
							acceptText: "Edit Here"
						}).then(y => {
							// update edit_lock session_id and extend edit time
							payload['transfer_session'] = true
							dispatch('manage_edit_lock', payload)

							vapp.$inform('Framework checked out to this location.')
							resolve(result)
						}).catch(n => {
							//console.log(n)
							reject('Editor declined transferring framework checkout.')
						}).finally(f=>{})
					} else {
						vapp.$alert('A problem occurred while checking out a framework.')
						reject()
					}

				});
			})
		},

		// test database availability and models
		test_db_model({state, commit, dispatch}) {
			let user = {
				'first_name': 'Mike',
				'last_name': 'Zapp',
				'email': 'zapp@spedcol.org' + U.random_int(10000),
				'system_role': 'admin',
				'login_count': 0,
			}
			let framework_activity = {
				'framework_identifier' : 'd56d1aaa-f540-4a15-95e8-b1954645de23' + U.random_int(10000),
				'user_id': 1121,
				'session_id': 'PHPSESSID2345',
				'action': 'check_out',
			}
			let framework = {
				'framework_identifier': 'd56d1aaa-f540-4a15-95e8-b1954645de23' + U.random_int(10000),
				'framework_type': 'draft',
				'color': 'blue',
				'image_fn': 'folder.png',
			}
			let edit_lock = {
				'framework_identifier' : 'd56d1aaa-f540-4a15-95e8-b1954645de23'+ U.random_int(10000),
				'user_id': 1121,
				'session_id': 'PHPSESSID2345',
				'lock_timestamp' : '*NOW*',
			}
			let payload = {
				user_data: JSON.stringify(user),
				cfa_data: JSON.stringify(framework_activity),
				cf_data: JSON.stringify(framework),
				el_data: JSON.stringify(edit_lock),
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('test_db_model', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error test_db_model.')
						reject()
						return
					}

					vapp.$alert(result.test_result);
					resolve()
				});
			})
		},
	}
})
