<template><div style="position:fixed; z-index:5; top:60px; left:0; width:100vw; height:calc(100vh - 56px); background-color: white; overflow: auto;" ref="main_container" id="k-crosswalk-interface-main_container"><div v-if="initialized">
<!-- ; min-height: 100%; margin-top: 52px -->

<!-- “CONTROL BAR” AT TOP OF UI -->
	<div class="elevation-1" style="position: fixed; top: 60px; height: 52px; background-color: white; z-index: 5; width: 100vw;"> <!-- 45px -->
		<div class="d-flex mx-3">
			<v-btn large icon color="primary" class="mt-1 mr-1" @click="close_crosswalk_editor"><v-icon>fas fa-circle-xmark</v-icon></v-btn>
			<v-btn-toggle v-model="interface_showing" class="mt-2" mandatory dense>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" value="summary"><v-icon color="primary">fas fa-clipboard-list</v-icon></v-btn></template>Crosswalk Summary</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" value="settings"><v-icon color="primary">fas fa-gear</v-icon></v-btn></template>Crosswalk Settings and Limiters</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" value="assocs"><v-icon color="primary">fas fa-arrow-right-arrow-left</v-icon></v-btn></template>Review/Create Associations</v-tooltip>
			</v-btn-toggle>

			<h3 v-show="interface_showing=='summary'" class="ml-4 mt-2" style="font-size:24px">Crosswalk Summary</h3>
			<h3 v-show="interface_showing=='settings'" class="ml-4 mt-2" style="font-size:24px">Crosswalk Settings and Filters</h3>

			<!-- loader/# of items -->
			<div v-show="interface_showing=='assocs'" class="ml-3" style="width: 108px; min-width: 108px; position: relative;" :style="(compute_progress==100)?'height:36px;margin-top:8px;line-height:14px':'height:20px;margin-top:16px;line-height:12px'" @click="compute_all_match_scores">
				<div style="height: 100%; border-radius: 2px; overflow: hidden;" class="pink lighten-2"><div :style="`height: 100%; background-color: #222; width: ${compute_progress}%;`" class="pink darken-3"></div></div>
				<div style="position:absolute; left:0; top:4px; font-size:12px; width:108px; text-align:center;">
					<b style="color:#fff"><span v-if="compute_progress<100">{{matches_processed_so_far}} / </span>{{total_matches_to_process}} <span v-if="compute_progress==100">Filtered<br>Items</span></b>
				</div>
			</div>
			<v-tooltip top nudge-bottom="0"><template v-slot:activator="{on}"><v-btn v-show="interface_showing=='assocs'" v-on="on" class="mt-2 ml-2 k-tight-btn k-nocaps-btn" color="pink darken-3" dark @click="ai_assoc_btn_clicked"><v-icon small class="mr-2">fas fa-wand-magic-sparkles</v-icon>{{mean_match_score}}<v-icon class="ml-1" v-if="ai_only_matches_unchanged" small>fas fa-check</v-icon></v-btn></template><div>
				<div>Average max. match score: <b>{{mean_match_score}}</b></div>
				<!-- <div>• Average max. statement similarity score: <b>{{mean_fs_sb_score}}</b></div> -->
				<div v-if="!ai_only_matches_unchanged" class="mt-0 text-center">Click to save Auto Associations</div>
				<div v-if="ai_only_matches_unchanged" class="mt-0 text-center">Auto Associations unchanged since last save</div>
			</div></v-tooltip>
			<v-spacer/>

			<div v-if="interface_showing=='assocs'&&!tree_mode" class="d-flex align-center" style="margin-top:5px;">
				<div v-for="(mlo, index) in match_level_options" :key="`x-${mlo.value}-${a_index}`" v-if="index!=6"><v-tooltip :color="match_level_class(index)+' lighten-4'" top nudge-bottom="4"><template v-slot:activator="{on}"><div v-on="on" class="mr-1 py-1 px-1 d-flex" style="border-radius:4px; font-size: 13px; text-wrap: nowrap;" :style="match_level_counts[index]?'':'opacity:0.7'" :class="match_level_class(index)+' lighten-4'">
					<v-checkbox class="mt-0 pt-0 d-inline-block" style="vertical-align:bottom; max-width: 27px;" v-model="match_levels[index]" color="#222" :disabled="match_level_counts[index]==0" :indeterminate="match_level_counts[index]==0" hide-details>
						<template v-slot:label>
						</template>
					</v-checkbox>
					<div v-show="expanded_mlos_showing" style="margin-top: 2px;">
						<b style="font-size: 13px;">{{mlo.text}}</b>
						<span style="font-size: 13px;">{{table_showing?` (${match_level_counts[index]})`:''}}</span>
					</div>
					<div v-show="!expanded_mlos_showing" style="margin-top: 2px">{{match_level_counts[index]}}</div>
				</div></template><span class="black--text">Items with match scores <b>{{mlo.text}}</b></span></v-tooltip></div>
				
				<div class="d-flex flex-column">
					<v-btn v-visible="match_levels.length==0||match_levels.includes(false)" x-small outlined color="secondary" class="mx-1 px-1" style="margin-bottom: 2px;" @click="select_all_match_levels"><v-icon small class="mr-1">fas fa-check</v-icon>All<v-spacer/></v-btn>
					<v-btn v-visible="match_levels.includes(true)" x-small outlined color="secondary" class="mx-1 px-1" @click="select_none_match_levels"><v-icon small class="mr-1">fas fa-check</v-icon>None</v-btn>
				</div>
				<v-tooltip top nudge-bottom="8"><template v-slot:activator="{on}"><div v-on="on"><v-checkbox class="mt-1 mr-1 my-1" style="border:1px solid #999; border-radius:4px; padding:3px 2px;" v-model="show_already_aligned" hide-details>
					<template v-slot:label><v-icon style="margin-left:-6px" small color="black">fas fa-arrows-left-right</v-icon><span style="font-size: 13px; line-height:15px; text-wrap: nowrap; margin-left:4px;" v-html="expanded_mlos_showing?`Associated (${n_aligned_items})`:n_aligned_items"></span></template>
				</v-checkbox></div></template>Already-associated items</v-tooltip>
				<v-tooltip top nudge-bottom="8"><template v-slot:activator="{on}"><div v-show="n_not_suggestible>0" v-on="on"><v-checkbox class="mt-1 mr-1 my-1" style="border:1px solid #999; border-radius:4px; padding:3px 2px;" v-model="show_not_suggestible" hide-details>
					<template v-slot:label><v-icon style="margin-left:-6px" small color="black">fas fa-minus</v-icon><span style="font-size: 13px; line-height:15px; text-wrap: nowrap; margin-left:4px;" v-html="expanded_mlos_showing?`No suggestions (${n_not_suggestible})`:n_not_suggestible"></span></template>
				</v-checkbox></div></template>Items that don’t meet criteria for suggestions</v-tooltip>
			</div>

			<v-spacer/>
			<!-- not currently supporting search -->
			<!-- <v-btn v-show="!tree_mode" x-small color="primary" fab style="margin-top:10px; margin-right:12px;" @click=""><v-icon small>fas fa-search</v-icon></v-btn> -->
			<v-btn-toggle v-show="interface_showing=='assocs'" v-model="tree_mode" class="mt-2" mandatory dense>
				<v-btn :value="false"><v-icon color="primary">fas fa-table</v-icon></v-btn>
				<v-btn :value="true"><v-icon color="primary">fas fa-tree</v-icon></v-btn>
			</v-btn-toggle>
			<!-- <v-btn large icon color="primary" class="mt-1 ml-2" @click="close_crosswalk_editor"><v-icon large>fas fa-circle-xmark</v-icon></v-btn> -->
		</div>
	</div>

<!-- SUMMARY INTERFACE -->
<div v-show="interface_showing=='summary'" style="margin-top:52px; background-color:#ddd; min-height:calc(100vh - 112px);" class="pa-2">
	<div style="width:1000px; border-radius:12px; border:1px solid #999;" class="elevation-1 mt-3 pa-3 mx-auto blue lighten-5">
		<div class="mb-2 d-flex align-center" style=" font-size:18px;">
			<b class="my-1 text-center blue darken-4 white--text" style="display:block; padding:2px 8px; border-radius:4px; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis"><v-btn fab x-small color="blue lighten-4" class="mr-2 ml-1" style="margin:3px 0" @click="open_framework($event,framework_record_a.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn>{{ framework_record_a.json.CFDocument.title }}</b>
			<div class="text-center" style="flex:0 0 40px"><v-icon color="black">fas fa-long-arrow-right</v-icon></div>
			<b class="my-1 text-center orange darken-4 white--text" style="display:block; padding:2px 8px; border-radius:4px; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis"><v-btn fab x-small color="orange lighten-4" class="mr-2 ml-1" style="margin:3px 0" @click="open_framework($event,framework_record_b.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn>{{ framework_record_b.json.CFDocument.title }}</b>
		</div>
		<ul>
			<li v-if="false" class="mb-2">
				Item Types selected for associations (counts):
				<ul>
					<li v-for="(count, item_type) in framework_a_stats.item_type_counts" :key="item_type">
						<b>{{item_type}}</b> ({{count}})
					</li>
				</ul>
			</li>
			<li class="mb-2">SME-Created Association Count: <b>{{framework_a_stats.sme_count}}</b></li>
			<li class="mb-2">Auto-Associations for Cross-Alignments:<ul>
				<li>Count: <b>{{framework_a_stats.fw_items_with_associations_count}} / {{framework_a_stats.fw_items_count}}</b> ({{framework_a_stats.percent}}%)</li>
				<li v-if="framework_a_stats.fw_items_with_associations_count > 0">Average maximum overall match score: <b>{{framework_a_stats.avg_max_match_score.toFixed(1)}}</b></li>
				<li v-if="framework_a_stats.fw_items_with_associations_count > 0">Average maximum statement similarity score: <b>{{framework_a_stats.avg_max_stmt_score.toFixed(1)}}</b></li>
				<li v-if="framework_a_stats.fw_items_with_associations_count > 0">Average number of candidate matches: <b>{{framework_a_stats.avg_matches.toFixed(1)}}</b></li>
			</ul></li>
		</ul>
	</div>

	<div class="mt-6 text-center"><v-btn color="#fff" class="k-tight-btn k-nocaps-btn" @click="switch_sides"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon>Switch Left/Right Frameworks</v-btn></div>

	<div style="width:1000px; border-radius:12px; border:1px solid #999;" class="elevation-1 mt-6 pa-3 mx-auto orange lighten-5">
		<div class="mb-2 d-flex align-center" style=" font-size:18px;">
			<b class="my-1 text-center orange darken-4 white--text" style="display:block; padding:2px 8px; border-radius:4px; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis"><v-btn fab x-small color="orange lighten-4" class="mr-2 ml-1" style="margin:3px 0" @click="open_framework($event,framework_record_b.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn>{{ framework_record_b.json.CFDocument.title }}</b>
			<div class="text-center" style="flex:0 0 40px"><v-icon color="black">fas fa-long-arrow-right</v-icon></div>
			<b class="my-1 text-center blue darken-4 white--text" style="display:block; padding:2px 8px; border-radius:4px; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis"><v-btn fab x-small color="blue lighten-4" class="mr-2 ml-1" style="margin:3px 0" @click="open_framework($event,framework_record_a.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn>{{ framework_record_a.json.CFDocument.title }}</b>
		</div>
		<ul>
			<li v-if="false" class="mb-2">
				Item Types selected for associations (counts):
				<ul>
					<li v-for="(count, item_type) in framework_b_stats.item_type_counts" :key="item_type">
						<b>{{item_type}}</b> ({{count}})
					</li>
				</ul>
			</li>
			<li class="mb-2">SME-Created Association Count: <b>{{framework_b_stats.sme_count}}</b></li>
			<li class="mb-2">Auto-Associations for Cross-Alignments:<ul>
				<li>Count: <b>{{framework_b_stats.fw_items_with_associations_count}} / {{framework_b_stats.fw_items_count}}</b> ({{framework_b_stats.percent}}%)</li>
				<li v-if="framework_b_stats.fw_items_with_associations_count > 0">Average maximum overall match score: <b>{{framework_b_stats.avg_max_match_score.toFixed(1)}}</b></li>
				<li v-if="framework_b_stats.fw_items_with_associations_count > 0">Average maximum statement similarity score: <b>{{framework_b_stats.avg_max_stmt_score.toFixed(1)}}</b></li>
				<li v-if="framework_b_stats.fw_items_with_associations_count > 0">Average number of candidate matches: <b>{{framework_b_stats.avg_matches.toFixed(1)}}</b></li>
			</ul></li>
		</ul>
	</div>

</div>

<!-- SETTINGS AND LIMITERS INTERFACE -->
<div v-if="interface_showing=='settings'" style="font-size:16px; margin:52px 12px 8px 12px;">
	<div class="px-3 mb-2 d-flex align-center white--text" style="margin:0 -24px; font-size:18px;">
		<b class="text-center blue darken-4 py-2 px-1" style="flex: 0 0 calc(50vw - 45px); display:block; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis">{{ framework_record_a.json.CFDocument.title }}<v-btn fab x-small color="blue lighten-4" class="ml-2" style="margin-top:-4px" @click="open_framework($event,framework_record_a.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn></b>
		<div class="text-center" style="flex:0 0 90px">
			<b class="mt-1 text-center" style="display:block; width: 90px;"><v-tooltip><template v-slot:activator="{on}"><v-btn v-on="on" color="#000" icon outlined @click="switch_sides"><v-icon>fas fa-arrow-right-arrow-left</v-icon></v-btn></template>Switch Left/Right Frameworks</v-tooltip></b>
		</div>
		<b class="text-center orange darken-4 py-2 px-1" style="flex: 0 0 calc(50vw - 45px); display:block; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis">{{ framework_record_b.json.CFDocument.title }}<v-btn fab x-small color="orange lighten-4" class="ml-2" style="margin-top:-4px" @click="open_framework($event,framework_record_b.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn></b>
	</div>
	<!-- SETTINGS FOR COMPUTING MATCH SCORES -->
	<div class="d-flex pb-4 mb-1">
		<div class="mr-3" style="flex:0 1 50%">
			<div class="pb-1 ml-2" style="border-bottom:1px solid #ccc">
				<div class="d-flex mb-1" style="font-size:16px">
					<div style="width:250px"><b>Associate FROM item types...</b></div>
					<div><b>... TO types</b></div>
				</div>
				<div v-for="(option) in item_type_select_options_a" :key="`${option}-${a_index}`" class="d-flex align-center mt-1 pt-1" style="border-top:1px solid #ccc">
					<div style="width:250px"><v-checkbox @change="match_params_changed(true)" class="mt-0 pt-0" :label="option" v-model="item_type_params[option].alignable" hide-details></v-checkbox></div>
					<div v-visible="item_type_params[option].alignable">
						<v-select v-model="item_type_params[option].align_to" :items="item_type_select_options_b" @change="match_params_changed(true)" label="" dense outlined hide-details multiple small-chips deletable-chips></v-select>
					</div>
				</div>
			</div>
			<div class="mt-3 px-2 py-2" style="border-radius: 9px; border: 2px #666 solid;">
				<div><b>Education Level match tolerance:</b></div>
				<div>
					<v-radio-group v-model="ed_level_match_tolerance_for_radio" hide-details class="mt-2" @change="match_params_changed(true)">
						<v-radio label="Don’t require education levels to match" value="-1"></v-radio>
						<v-radio label="Require education levels to overlap" value="0"></v-radio>
						<v-radio label="Consider education levels, but allow variance of 1 grade level" value="1"></v-radio>
					</v-radio-group>
				</div>
			</div>
		</div>
		<div class="ml-3" style="flex:0 1 50%">
			<!-- <div class="d-flex align-center">
				<div class="mr-2"><b>Table spacing:</b></div>
				<v-btn-toggle :style="tree_mode ? 'opacity: .5;' : ''" v-model="table_spacing" class="" dense>
					<v-btn value="spacious" style="font-size: 12px;">spacious</v-btn>
					<v-btn value="comfy" style="font-size: 12px;">comfy</v-btn>
					<v-btn value="dense" style="font-size: 12px;">dense</v-btn>
				</v-btn-toggle>
				<v-spacer/>
				<v-checkbox class="mx-3 ml-2 mt-0 pt-0" v-model="show_diff_coloring" hide-details>
					<template v-slot:label><span style="font-size: 16px; text-wrap: nowrap;">Show Difference Coloring</span></template>
				</v-checkbox>
			</div> -->
			<!-- <div class="mt-4 d-flex align-center">
				<div class="mr-2"><nobr><b>Auto-associate thresholds:</b></nobr></div>
				<div class="ml-1" style="margin-bottom:-14px">0</div>
				<div style="flex:1 0 auto;padding-top:16px;"><v-range-slider @change="match_params_changed" v-model="relation_threshold_range" min="0" max="95" step="5" :thumb-size="20" thumb-label="always" hide-details dense ticks="always" tick-size="4"></v-range-slider></div>
				<div class="ml-0" style="margin-bottom:-14px">100</div>
			</div> -->
			<div class="mt-4 d-flex align-center">
				<div class="mr-2"><b>AI match use:</b></div>
				<div style="flex:1 0 auto;padding-top:16px;"><v-slider v-model="match_params.sparkl_bot_weight" @change="match_params_changed" hide-details dense min="0" max="100" step="10" ticks="always" tick-size="4" :thumb-size="20" thumb-label="always"></v-slider></div>
			</div>
			<div class="mt-4 d-flex align-center">
				<div><nobr><b>Factor weights:</b></nobr></div>
				<div class="ml-4"><nobr>Cross-Crosswalks:</nobr></div>
				<div style="flex:1 0 auto; padding-top:16px;"><v-slider v-model="match_params.cross_crosswalk_weight" @change="match_params_changed" hide-details dense min="0" max="5" ticks="always" tick-size="4" :thumb-size="20" thumb-label="always"></v-slider></div>
				<div class="ml-4">Statement:</div>
				<div style="flex:1 0 auto; padding-top:16px;"><v-slider v-model="match_params.full_statement_weight" @change="match_params_changed" hide-details dense min="0" max="5" ticks="always" tick-size="4" :thumb-size="20" thumb-label="always"></v-slider></div>
				<!-- <div><nobr>Parent Item:</nobr></div>
				<div style="flex:1 0 auto; padding-top:16px;"><v-slider v-model="match_params.parent_weight" @change="match_params_changed" hide-details dense min="0" max="5" ticks="always" tick-size="4" :thumb-size="20" thumb-label="always"></v-slider></div> -->
				<div class="ml-4"><nobr>Human Code:</nobr></div>
				<div style="flex:1 0 auto; padding-top:16px;"><v-slider v-model="match_params.hcs_weight" @change="match_params_changed" hide-details dense min="0" max="5" ticks="always" tick-size="4" :thumb-size="20" thumb-label="always"></v-slider></div>
			</div>
			<div v-show="match_params.hcs_weight>0" class="mt-4 px-2 py-2" style="border-radius: 9px; border: 2px #666 solid;">
				<div class="mb-1"><b>Human Coding Scheme settings:</b></div>
				<div class="mt-2 d-flex align-center">
					<div class="mr-2"><b>Human code exponent conversion:</b></div>
					<div style="width:172px; padding-top:16px;"><v-slider v-model="match_params.hcs_power_adjustment" hide-details dense min="1" max="9" ticks="always" tick-size="4" :thumb-size="20" thumb-label="always"></v-slider></div>
				</div>
				<div class="ml-2">
					<div class="d-flex align-center mt-2"><div style="width:160px">LEFT HCS Regex 1:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_a[0].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_a[0].replace" placeholder=""></v-text-field></div></div>
					<div class="d-flex align-center mt-2" v-if="hcs_regex_params_a[0].search&&(hcs_regex_params_a[1].search||add_hcs_regex_a)"><div style="width:160px">LEFT HCS Regex 2:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_a[1].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_a[1].replace" placeholder=""></v-text-field></div></div>
					<div class="d-flex align-center mt-2" v-if="hcs_regex_params_a[1].search&&(hcs_regex_params_a[2].search||add_hcs_regex_a)"><div style="width:160px">LEFT HCS Regex 3:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_a[2].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_a[2].replace" placeholder=""></v-text-field></div></div>
					<div class="d-flex align-center mt-2" v-if="hcs_regex_params_a[2].search&&(hcs_regex_params_a[3].search||add_hcs_regex_a)"><div style="width:160px">LEFT HCS Regex 4:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_a[3].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_a[3].replace" placeholder=""></v-text-field></div></div>
					<div class="text-center" v-if="!add_hcs_regex_a"><v-btn x-small text color="secondary" @click="add_hcs_regex_a=true">Add another regex</v-btn></div>

					<div class="d-flex align-center mt-5"><div style="width:160px">RIGHT HCS Regex 1:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_b[0].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_b[0].replace" placeholder=""></v-text-field></div></div>
					<div class="d-flex align-center mt-2" v-if="hcs_regex_params_b[0].search&&(hcs_regex_params_b[1].search||add_hcs_regex_b)"><div style="width:160px">RIGHT HCS Regex 2:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_b[1].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_b[1].replace" placeholder=""></v-text-field></div></div>
					<div class="d-flex align-center mt-2" v-if="hcs_regex_params_b[1].search&&(hcs_regex_params_b[2].search||add_hcs_regex_b)"><div style="width:160px">RIGHT HCS Regex 3:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_b[2].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_b[2].replace" placeholder=""></v-text-field></div></div>
					<div class="d-flex align-center mt-2" v-if="hcs_regex_params_b[2].search&&(hcs_regex_params_b[3].search||add_hcs_regex_b)"><div style="width:160px">RIGHT HCS Regex 4:</div><div class="mr-2"><v-text-field @change="match_params_changed" outlined hide-details dense label="search pattern" v-model="hcs_regex_params_b[3].search" placeholder=""></v-text-field></div><div><v-text-field @change="match_params_changed" outlined hide-details dense label="replace pattern" v-model="hcs_regex_params_b[3].replace" placeholder=""></v-text-field></div></div>
					<div class="text-center" v-if="!add_hcs_regex_b"><v-btn x-small text color="secondary" @click="add_hcs_regex_b=true">Add another regex</v-btn></div>
				</div>
			</div>
		</div>
	</div>

	<!-- LIMITERS FOR ITEMS WE'RE CURRENTLY WORKING ON MATCHING -->
	<div class="mt-1 pt-2 pb-3 d-flex align-center flex-wrap" style="border-top:3px solid #000">
		<div class="d-flex mr-12 align-center flex-wrap mt-2">
			<b><span style="font-size:1.3em">Limit</span> to LEFT branches:</b>
			<v-chip v-for="(identifier) in include_branches_left" :key="identifier" small close class="my-1 ml-1" @click:close="remove_branch('include_branches_left', identifier)"><div v-html="item_html(framework_record_a.cfo.cfitems[identifier], 20)"></div></v-chip>
			<v-btn x-small color="primary" outlined class="k-tight-btn ml-2 mr-4" @click="select_branches('include_branches_left')">{{include_branches_left.length?'Edit':'Add'}}</v-btn>

			<b>Limit to RIGHT branches:</b>
			<v-chip v-for="(identifier) in include_branches_right" :key="identifier" small close class="my-1 ml-1" @click:close="remove_branch('include_branches_right', identifier)"><div v-html="item_html(framework_record_b.cfo.cfitems[identifier], 20)"></div></v-chip>
			<v-btn x-small color="primary" outlined class="k-tight-btn ml-2" @click="select_branches('include_branches_right')">{{include_branches_right.length?'Edit':'Add'}}</v-btn>
		</div>
		<div class="d-flex align-center mt-3 flex-wrap">
			<b>Exclude LEFT branches:</b>
			<v-chip v-for="(identifier) in exclude_branches_left" :key="identifier" small close class="my-1 ml-1" @click:close="remove_branch('exclude_branches_left', identifier)"><div v-html="item_html(framework_record_a.cfo.cfitems[identifier], 20)"></div></v-chip>
			<v-btn x-small color="primary" outlined class="k-tight-btn ml-2 mr-4" @click="select_branches('exclude_branches_left')">{{exclude_branches_left.length?'Edit':'Add'}}</v-btn>

			<b>Exclude RIGHT branches:</b>
			<v-chip v-for="(identifier) in exclude_branches_right" :key="identifier" small close class="my-1 ml-1" @click:close="remove_branch('exclude_branches_right', identifier)"><div v-html="item_html(framework_record_b.cfo.cfitems[identifier], 20)"></div></v-chip>
			<v-btn x-small color="primary" outlined class="k-tight-btn ml-2" @click="select_branches('exclude_branches_right')">{{exclude_branches_right.length?'Edit':'Add'}}</v-btn>
		</div>
	</div>
</div>





<!-- MAKING-ASSOCIATIONS INTERFACE -->
<div v-show="interface_showing=='assocs'">
	<!-- TABLE OF ITEMS THAT CAN BE ALIGNED -->
	<div v-if="!tree_mode" class="d-flex" style="position:fixed; z-index: 5; top:112px; left:0px; width:100vw; color:#fff; height: 42px;">
		<!-- 62 - 126 - (50vw - 210px) - 68 - 32 - (50vw - 210px) -->
		<b class="pt-2 pl-4 blue darken-4" style="width: 62px; text-wrap: nowrap;">#</b>
		<b class="pl-3 pt-1 blue darken-4" style="width: 56px; text-wrap: nowrap;">
			<v-icon @click="select_all_items" style="margin-top:4px;" dark>{{ all_items_selected ? "fas fa-square-check" : "far fa-square" }}</v-icon>
			<!-- <div class="mt-1 pl-1" style="display: inline-block;">{{ all_items_selected?'Deselect All':'Select All' }}</div> -->
		</b>
		<b class="py-2 blue darken-4 white--text text-center" style="width: calc(50vw - 120px); color:#000; padding:0 6px; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis">{{ framework_record_a.json.CFDocument.title }}<v-btn fab x-small color="blue lighten-4" class="ml-2" style="margin-top:-4px" @click="open_framework($event,framework_record_a.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn></b>
		<b class="text-center" style="display:block; width: 90px; background-color:#ccc; border-top:1px solid #000; border-bottom:1px solid #000; padding-top:2px;"><v-tooltip><template v-slot:activator="{on}"><v-btn v-on="on" color="#000" icon @click="switch_sides"><v-icon>fas fa-arrow-right-arrow-left</v-icon></v-btn></template>Switch Left/Right Frameworks</v-tooltip></b>
		<b class="py-2 orange darken-4 white--text text-center" style="width: calc(50vw - 86px); color:#000; padding:0 6px; transition: none; text-wrap: nowrap; overflow: hidden; text-overflow:ellipsis">{{ framework_record_b.json.CFDocument.title }}<v-btn fab x-small color="orange lighten-4" class="ml-2" style="margin-top:-4px" @click="open_framework($event,framework_record_b.json.CFDocument.identifier)"><v-icon small color="#000">fas fa-arrow-up-right-from-square</v-icon></v-btn></b>
		<!-- <v-spacer/> -->
	</div>
	<div v-show="!tree_mode" style="background-color: white; margin-top:94px;" :class="table_spacing">
		<v-hover v-for="(item, index) in table_items" :key="item.table_key" v-model="item.hovering" v-if="(current_page - 1) * items_per_page <= index && index < (current_page) * items_per_page"><div :ref="'table_item_' + item.identifier" :class="`${(!spot_check_candidates || !spot_check_candidates[0] || spot_check_candidates[0].left_identifier != item.cfitem.identifier) ? row_class(item) : (match_level_class(item.match_level) + ' lighten-5')} text--darken-4 d-flex k-crosswalk-curated-match-item ${(spot_check_candidates && spot_check_candidates[0] && spot_check_candidates[0].left_identifier != item.cfitem.identifier) ? 'k-crosswalk-not-spot-checked' : ''}`" :style="`transition: background-color .25s, opacity .125s; ${ item.primary_association ? 'border-top-style: solid; border-color: #555!important;' : 'border-top-style: dotted; border-color: #555!important;' }`">
			<div class="d-flex">
				<div class="d-flex flex-column pl-3 ml-1" style="width: 60px;"><v-spacer/>
					<div v-if="item.primary_association" class="k-crosswalk-curated-text-item" :style="item.hovering?'font-weight:bold':''">{{ item.overall_sequence }}</div>
				<v-spacer/></div>
				<v-spacer/>
			</div>
			
			<div class="d-flex float-right">
				<div class="d-flex float-right flex-column">
					<!-- "NORMAL" ROW, SHOWING TOP MATCH CANDIDATE -->
					<div v-show="!spot_check_candidates || !spot_check_candidates[0] || spot_check_candidates[0].left_identifier != item.cfitem.identifier">
						<div class="d-flex">
							<div class="d-flex flex-column" style="width: 40px;"><v-spacer/>
								<div class="k-crosswalk-curated-text-item" style="margin-left:10px;" :style="((item.right_identifier=='') ? 'opacity: 0.0;' : '') + ' transition: opacity: .25s;'"><v-icon @click="match_box_clicked(item, item.right_identifier)" style="padding-bottom: 4px;">{{ selected_items[item.identifier] ? (selected_items[item.identifier] == item.right_identifier ? "fas fa-square-check" : "far fa-square-check") : "far fa-square" }}</v-icon></div>
							<v-spacer/></div>
							<div :class="'d-flex flex-column mx-2 ' + match_level_class(item.match_level) + '--text text--darken-3'" style="width: 75px; line-height:14px!important;" :style="item.hovering?'font-weight:bold':''">
								<v-spacer/>
								<div style="font-size: 12px; align-self: end; text-align: right;">{{ item.grade_level_display }}</div>
								<div style="font-size: 12px; align-self: end; text-align: right; margin-top:2px;">{{ item.item_type }}</div>
								<v-spacer/>
							</div>
							<div>
								<div class="float-right ml-1 mb-1 mr-3 mt-1"><v-btn x-small style="width:24px;height:24px;" fab outlined :color="item.hovering?'#222':'#999'" @click="open_full_item(item)"><v-icon small>fas fa-tree</v-icon></v-btn></div>
								<div v-html="item.item_html_a" :class="'k-crosswalk-curated-text-box mr-2 ' + table_spacing + ((!show_diff_coloring||!item.hovering) ? ' k-crosswalk-hide-diff-coloring' : '')"></div>
							</div>
							
							<!-- menu for choosing assoc -->
							<v-menu :disabled="false&&item.right_identifier==''" offset-y transition="slide-y-transition" dense nudge-top="10">
								<template v-slot:activator="{ on, attrs }">
									<v-tooltip :disabled="!item.assoc_icon || item.match_sort_value == -1" top nudge-bottom="20">
										<template v-slot:activator="{ on: tooltip_on, attrs: tooltip_attrs}">
											<div v-bind="{...attrs, ...tooltip_attrs}" v-on="{...on, ...tooltip_on}" class="d-flex flex-column" style="width: 44px; justify-content: center; cursor: pointer;">
												<div v-if="table_cells_html(item).center" v-html="table_cells_html(item).center" :class="`${match_level_class(item.match_level)}--text text--darken-3 k-crosswalk-curated-text-item k-crosswalk-match-score-btn elevation-1`"></div>
												<v-icon v-if="item.assoc_icon" medium :class="`${match_level_class(item.match_level)}--text text--darken-3 k-crosswalk-curated-text-item`">{{item.assoc_icon}}</v-icon>
												<v-icon v-if="!table_cells_html(item).center&&!item.assoc_icon">fas fa-minus</v-icon>
											</div>
										</template>
										<span :class="`${match_level_class(item.match_level)}--text text--lighten-3`">{{ item.match_sort_value }}%</span>
									</v-tooltip>

								</template>
								<v-list @mousemove="e=>item.hovering = true" @mouseleave="item.hovering = false" dense>
									<v-list-item v-if="item.assoc_icon" @click="delete_associations(item)"><v-list-item-icon><v-icon>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove {{item.assoc_icon=='fas fa-ban'?'“No Match” Designation':'Association'}}</v-list-item-title></v-list-item>
									<v-divider v-if="table_cells_html(item).center&&item.assoc_icon"/>
									<v-list-item v-if="table_cells_html(item).center" @click="add_associations('exact', {item})"><v-list-item-icon><v-icon>fas fa-bullseye</v-icon></v-list-item-icon><v-list-item-title>Exact Match</v-list-item-title></v-list-item>
									<v-list-item v-if="table_cells_html(item).center" @click="add_associations('nearexact', {item})"><v-list-item-icon><v-icon>fas fa-circle-dot</v-icon></v-list-item-icon><v-list-item-title>Near-Exact Match</v-list-item-title></v-list-item>
									<v-list-item v-if="table_cells_html(item).center" @click="add_associations('close', {item})"><v-list-item-icon><v-icon>fas fa-circle</v-icon></v-list-item-icon><v-list-item-title>Closely Related</v-list-item-title></v-list-item>
									<v-list-item v-if="table_cells_html(item).center" @click="add_associations('moderate', {item})"><v-list-item-icon><v-icon>fas fa-circle-half-stroke</v-icon></v-list-item-icon><v-list-item-title>Moderately Related</v-list-item-title></v-list-item>
									<v-divider v-if="table_cells_html(item).center"/>
									<v-list-item v-if="!item.assoc_icon" @click="add_associations('nomatch', {item})"><v-list-item-icon><v-icon>fas fa-ban</v-icon></v-list-item-icon><v-list-item-title>No Match</v-list-item-title></v-list-item>
								</v-list>

							</v-menu>

							<v-tooltip bottom nudge-top="24"><template v-slot:activator="{on}"><v-icon v-show="item.right_identifier!=''" v-on="on" @click.stop="toggle_suggestions_for_item(item)" color="#333" class="pl-1" style="width: 32px;">fas fa-circle-chevron-down</v-icon></template>Show suggestions</v-tooltip>

							<div v-show="item.right_identifier!=''">
								<div class="float-right ml-1 mb-1 mr-3 mt-1"><v-btn x-small style="width:24px;height:24px;" fab outlined :color="item.hovering?'#222':'#999'" @click="open_full_item(item)"><v-icon small>fas fa-tree</v-icon></v-btn></div>
								<div v-html="item.item_html_b" :class="'k-crosswalk-curated-text-box ml-2 ' + table_spacing + ((!show_diff_coloring||!item.hovering) ? ' k-crosswalk-hide-diff-coloring' : '')"></div>
							</div>
							<div v-show="item.right_identifier==''" :class="'k-crosswalk-curated-text-box ml-2'" style="background-color: transparent; box-shadow: none; padding-top: 4px; padding-bottom: 4px; padding-left: 6px; align-self: center; cursor:pointer;" @click.stop="toggle_suggestions_for_item(item)"><i style="font-size:12px">No items meet the criteria for suggestions (check item type/ed. level settings)</i></div>
							<div :class="'d-flex flex-column mx-2 ' + match_level_class(item.match_level) + '--text text--darken-3'" style="width: 77px; line-height:14px;">
								<v-spacer/>
								<div style="font-size: 12px; align-self: start; text-align: left;">{{ item.right_grade_level_display }}</div>
								<div style="font-size: 12px; align-self: start; text-align: left; margin-top:2px;">{{ item.right_item_type }}</div>
								<v-spacer/>
							</div>
	
						</div>
					</div>
					
					<!-- ALL CANDIDATES FOR A ROW -->
					<div v-if="spot_check_candidates && spot_check_candidates[0] && spot_check_candidates[0].left_identifier == item.cfitem.identifier && item.primary_association">
						<div :class="match_level_class(get_match_level(item.match_sort_value)) + '--text text--darken-4 mt-1 text-center'" style="font-size: 20px; margin-left:-39px;">
							<b>Suggestions</b>
							<v-icon @click.stop="spot_check_candidates=null;spot_check_row=null;" color="#333" class="pl-1">fas fa-circle-chevron-up</v-icon>
						</div>
						<div class="my-1" style="border-radius: 9px; overflow: hidden; border-style: solid; border-width: 2px; border-color: #555!important;">
							<v-hover v-for="(candidate, index) in spot_check_candidates" :key="candidate.right_identifier" v-model="candidate.hovering"><div :class="`d-flex ${row_class({match_level: get_match_level(candidate.match_sort_value)})} lighten-5`" :style="`${index > 0 ? 'border-top-style: solid; border-width: 2px; border-color: #555!important;' : ''} transition: background-color .25s;`">
	
								<div class="d-flex flex-column" style="width: 40px;"><v-spacer/>
									<div class="mx-1 px-1 k-crosswalk-curated-text-item"><v-icon @click=" match_box_clicked(spot_check_row, candidate.right_identifier)" style="padding-bottom: 4px;">{{ selected_items[candidate.left_identifier] ? (selected_items[candidate.left_identifier] == candidate.right_identifier ? "fas fa-square-check" : "far fa-square") : "far fa-square" }}</v-icon></div>
								<v-spacer/></div>
								
								<div :class="'d-flex flex-column mx-2 ' + match_level_class(get_match_level(candidate.match_sort_value)) + '--text text--darken-3'" style="width: 75px; line-height:14px!important;" :style="candidate.hovering?'font-weight:bold':''">
									<v-spacer/>
									<div style="font-size: 12px; align-self: end; text-align: right;">{{ candidate.grades.left }}</div>
									<div style="font-size: 12px; align-self: end; text-align: right;">{{ candidate.item_types.left }}</div>
									<v-spacer/>
								</div>
								<div>
									<div class="float-right ml-1 mb-1 mr-3 mt-1"><v-btn x-small style="width:24px;height:24px;" fab outlined :color="item.hovering?'#222':'#999'" @click="open_full_item(candidate)"><v-icon small>fas fa-tree</v-icon></v-btn></div>
									<div v-html="candidate.diffs.left" :class="'k-crosswalk-curated-text-box mr-2 ' + table_spacing + ((!(no_spot_check_candidates_hovering&&index==0)&&(!show_diff_coloring||!candidate.hovering)) ? ' k-crosswalk-hide-diff-coloring' : '')" :style="(candidate.hovering||(no_spot_check_candidates_hovering&&index==0))?'':'opacity:0.2'"></div>
								</div>

								<v-menu offset-y transition="slide-y-transition" dense nudge-top="10">
									<template v-slot:activator="{ on, attrs }">
										<v-tooltip :disabled="!candidate.assoc_icon" top nudge-bottom="20">
											<template v-slot:activator="{ on: tooltip_on, attrs: tooltip_attrs}">
												<div v-bind="{...tooltip_attrs, ...attrs}" v-on="{...tooltip_on, ...on}" class="d-flex flex-column" style="width: 44px; justify-content: center; cursor: pointer; margin:0 16px" @mouseenter="candidate.hovering = true" @mouseleave="candidate.hovering = false">
													<div v-if="candidate.table_cell_center" v-html="candidate.table_cell_center" :class="`${match_level_class(get_match_level(candidate.match_sort_value))}--text text--darken-3 k-crosswalk-curated-text-item k-crosswalk-match-score-btn elevation-1`" style="text-align: center; font-weight: bold;"></div>
													<v-icon v-if="candidate.assoc_icon" medium :class="`${match_level_class(get_match_level(candidate.match_sort_value))}--text text--darken-3 k-crosswalk-curated-text-item`">{{candidate.assoc_icon}}</v-icon>
												</div>
											</template>
											<span :class="`${match_level_class(get_match_level(candidate.match_sort_value))}--text text--lighten-3`">{{ candidate.match_sort_value }}%</span>
										</v-tooltip>

									</template>
									<v-list dense>
										<v-list-item v-if="candidate.assoc_icon" @click="delete_associations(candidate)"><v-list-item-icon><v-icon>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove Association</v-list-item-title></v-list-item>
										<v-divider v-if="candidate.assoc_icon"/>
										<v-list-item @click="add_associations('exact', {item: candidate})"><v-list-item-icon><v-icon>fas fa-bullseye</v-icon></v-list-item-icon><v-list-item-title>Exact Match</v-list-item-title></v-list-item>
										<v-list-item @click="add_associations('nearexact', {item: candidate})"><v-list-item-icon><v-icon>fas fa-circle-dot</v-icon></v-list-item-icon><v-list-item-title>Near-Exact Match</v-list-item-title></v-list-item>
										<v-list-item @click="add_associations('close', {item: candidate})"><v-list-item-icon><v-icon>fas fa-circle</v-icon></v-list-item-icon><v-list-item-title>Closely Related</v-list-item-title></v-list-item>
										<v-list-item @click="add_associations('moderate', {item: candidate})"><v-list-item-icon><v-icon>fas fa-circle-half-stroke</v-icon></v-list-item-icon><v-list-item-title>Moderately Related</v-list-item-title></v-list-item>
										<v-divider/>
										<v-list-item @click="add_associations('nomatch', {item: candidate})"><v-list-item-icon><v-icon>fas fa-ban</v-icon></v-list-item-icon><v-list-item-title>No Match</v-list-item-title></v-list-item>
									</v-list>

								</v-menu>
	
								<div>
									<div class="float-right ml-1 mb-1 mr-3 mt-1"><v-btn x-small style="width:24px;height:24px;" fab outlined :color="item.hovering?'#222':'#999'" @click="open_full_item(candidate)"><v-icon small>fas fa-tree</v-icon></v-btn></div>
									<div v-html="candidate.diffs.right" :class="'k-crosswalk-curated-text-box ml-2 ' + table_spacing + ((!(no_spot_check_candidates_hovering&&index==0)&&(!show_diff_coloring||!candidate.hovering)) ? ' k-crosswalk-hide-diff-coloring' : '')"></div>
								</div>
								<!-- <div v-html="candidate.diffs.right" style="cursor:pointer" @click="open_full_item(candidate)" :class="'k-crosswalk-curated-text-box ml-2 ' + table_spacing + ((!show_diff_coloring||!candidate.hovering) ? ' k-crosswalk-hide-diff-coloring' : '')"></div> -->
								<div :class="'d-flex flex-column mx-2 ' + match_level_class(get_match_level(candidate.match_sort_value)) + '--text text--darken-3'" style="width: 75px; line-height:14px!important;" :style="candidate.hovering?'font-weight:bold':''">
									<v-spacer/>
									<div style="font-size: 12px; align-self: start; text-align: left;">{{ candidate.grades.right }}</div>
									<div style="font-size: 12px; align-self: start; text-align: left;">{{ candidate.item_types.right }}</div>
									<v-spacer/>
								</div>
	
							</div></v-hover>
						</div>
						<v-btn @click="e=>{show_spot_check(item, spot_check_candidates.length + ((match_scores[0][item.cfitem.identifier].candidates.length > (spot_check_candidates.length + 5)) ? 5 : match_scores[0][item.cfitem.identifier].candidates.length - spot_check_candidates.length), false)}" v-if="match_scores[0][item.cfitem.identifier] && match_scores[0][item.cfitem.identifier].candidates.length > spot_check_candidates.length" :class="match_level_class(item.match_level) + ' lighten-1 mb-1'" style="width: 100%; color: white;" depressed>{{ "Show " + ((match_scores[0][item.cfitem.identifier].candidates.length > (spot_check_candidates.length + 5)) ? 5 : match_scores[0][item.cfitem.identifier].candidates.length - spot_check_candidates.length) + " more" }}</v-btn>
						
					</div>

				</div>
			</div>
			<div style="width: 8px;"></div>
		</div></v-hover>

		<div class="d-flex float-right mx-2 my-2" style="padding-bottom: 112px;">
			
			<v-select :items="[{name: 10, value: 10}, {name: 20, value: 20}, {name: 50, value: 50}, {name: 100, value: 100}]" item-value="value" item-text="name" v-model="items_per_page" label="Items Per Page" class="mx-2" style="max-width: 150px;" hide-details outlined dense></v-select>
			<v-select :items="page_options" v-model="current_page" label="Current Page" class="mx-2" style="max-width: 150px;" hide-details outlined dense></v-select>
			<v-icon class="mx-2" @click="e=>{if (current_page > 1) {spot_check_candidates = null; spot_check_row = null; current_page--;}}">fas fa-arrow-left</v-icon>
			<v-icon class="mx-2" @click="e=>{if (current_page < (table_items.length / items_per_page)) {spot_check_candidates = null; spot_check_row = null; current_page++;}}">fas fa-arrow-right</v-icon>
		</div>
		
	</div>

	<div v-show="tree_mode" ref="tree_view" style="position: relative; margin-top:52px; height:calc(100vh - 110px); overflow:hidden;">
		<div style="position: absolute;">
			<!-- <div class="mx-2 mt-2">
				<v-btn color="secondary" small class="ml-2" style="float: right;" @click="show_next_spot_check">Next <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
				<v-btn color="secondary" small style="float: right;" @click="show_last_spot_check"><v-icon small class="mr-2">fas fa-arrow-left</v-icon>Previous</v-btn>
			</div> -->

			<CASETree ref="crosswalk_left_viewer_tree"
				:framework_record="framework_record_a"
				:show_checkbox_fn="false"
				:show_move_fn="false"
				:show_chooser_fn="left_tree_show_chooser_fn"
				:crosswalk="this"
				:open_nodes_override="open_tree_nodes_left"
				:tree_scroll_wrapper_style="left_tree_scroll_wrapper_style"
			/>
		</div>

		<div style="float: right; width: 50vw; position: relative;">
			<div style="position:absolute"><CASETree ref="crosswalk_right_viewer_tree"
				:framework_record="framework_record_b"
				:show_chooser_fn="right_tree_show_chooser_fn"
				:crosswalk="this"
				:chosen_items="right_associated_items"
				:open_nodes_override="open_tree_nodes_right"
				:tree_scroll_wrapper_style="right_tree_scroll_wrapper_style"
			/></div>

			<div style="position:fixed; bottom:-1px; right:-1px; xx-max-height:30vh; width:50vw; overflow: auto; border: 1px solid black !important; border-radius:8px 0 0 0;" id="k-crosswalk-tree-suggestions"><div class="grey lighten-4 pl-2 pt-1 pb-1'">
				<div v-if="spot_check_candidates&&spot_check_candidates.length>0" class="k-association-assistant-instructions mt-0 mb-1 text-left">
					<div v-if="tree_suggestions_showing" v-for="(candidate, index) in spot_check_candidates" @click="show_right_item_in_tree(candidate.right_identifier)">
						<v-tooltip left>
							<template v-slot:activator="{on}">
								<div class="k-associations-maker-suggestion" v-on="on">
									<span class="k-associations-maker-suggestion-sim-score" :class="(candidate.right_identifier==tree_right_revealed_node.cfitem.identifier) ? 'k-cw-associations-maker-suggestion-sim-score-selected' : 'k-cw-associations-maker-suggestion-sim-score-not-selected'"><v-icon x-small v-if="candidate.assoc_icon" color="#fff" style="padding-top: 2px;">{{ candidate.assoc_icon }}</v-icon><div v-if="!candidate.assoc_icon" v-html="candidate.match_sort_value"></div></span>
									<div v-html="candidate.diffs.right" :class="(candidate.right_identifier==tree_right_revealed_node.cfitem.identifier) ? 'k-cw-associations-maker-suggestion-text-selected' : 'k-crosswalk-hide-diff-coloring'" class="k-cw-associations-maker-suggestion-text"></div>
								</div>
							</template>
							Grade {{ candidate.grades.right }} - {{ candidate.item_types.right }}
						</v-tooltip>
					</div>
					<div class="d-flex align-center" :class="tree_suggestions_showing?'mt-2':''" v-if="spot_check_candidates">
						<v-btn v-if="spot_check_candidates.length > 0" x-small outlined class="mr-2" color="primary" @click="toggle_tree_suggestions"><v-icon x-small class="mr-1">fas {{tree_suggestions_showing?'fa-eye-slash':'fa-eye'}}</v-icon>{{tree_suggestions_showing?'Hide':'Show'}} Suggestions</v-btn>
						<v-spacer/>
						<v-btn v-if="tree_suggestions_showing && tree_items.right.length > spot_check_candidates.length" x-small outlined color="primary" @click="e=>{show_spot_check(table_items_hash[tree_left_chosen_node.cfitem.identifier], spot_check_candidates.length + ((tree_items.right.length > (spot_check_candidates.length + 5)) ? 5 : tree_items.right.length - spot_check_candidates.length), false)}"><v-icon x-small class="mr-1">fas fa-plus</v-icon>Show More Suggestions</v-btn>
						<v-btn v-if="tree_suggestions_showing && spot_check_candidates.length > 5" x-small outlined class="ml-2" color="primary" @click="e=>{show_spot_check(table_items_hash[tree_left_chosen_node.cfitem.identifier], spot_check_candidates.length - ((tree_items.right.length > (spot_check_candidates.length + 5)) ? 5 : tree_items.right.length - spot_check_candidates.length), false)}"><v-icon x-small class="mr-1">fas fa-minus</v-icon>Show Fewer Suggestions</v-btn>
					</div>
				</div>
				<div v-else style="font-size:16px; line-height:19px; padding-bottom:4px;">
					<i v-if="!tree_left_chosen_node">Start by selecting an item on the left</i>
					<i v-if="tree_left_chosen_node">Select an item on the right to create an association <span style="font-size:12px">(no items meet the criteria for suggestions)</span></i>
				</div>
				<!-- This btn should not be relevant anymore; we will always have match scores -->
				<!-- <v-btn v-if="(!spot_check_candidates||spot_check_candidates.length==0)&&tree_left_chosen_node&&table_items_hash[tree_left_chosen_node.cfitem.identifier]" @click="compute_match_scores_for_item(table_items_hash[tree_left_chosen_node.cfitem.identifier])" class="mt-12" color="secondary" small>Compute Suggestions</v-btn> -->
			</div></div>
		</div>
	</div>
	
<!-- ASSOCIATE MENU -->
	<div v-show="n_items_to_associate>0&&!tree_mode" style="position:fixed; left:12px; bottom:12px; background-color:#aaa; padding:6px; border-radius:5px;">
		<v-menu top left nudge-top="40">
			<template v-slot:activator="{on}">
				<v-btn v-on="on" dark color="pink darken-3" class="k-tight-btn k-nocaps-btn ml-1 mr-3" style="font-weight:normal" @click=""><v-icon small class="mr-2">fas fa-arrows-left-right</v-icon>Associate <b class="ml-1">{{`${n_items_to_associate} item${n_items_to_associate>1?'s':''}`}}</b>…</v-btn>
			</template>
			<v-list>
				<v-list-item v-show="n_items_to_associate_deletable" @click="delete_associations(null)"><v-list-item-icon><v-icon>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete current associations</v-list-item-title></v-list-item>
				<v-divider />

				<v-list-item @click="add_associations('exact')"><v-list-item-icon><v-icon>fas fa-bullseye</v-icon></v-list-item-icon><v-list-item-title>Exact Match</v-list-item-title></v-list-item>
				<v-list-item @click="add_associations('nearexact')"><v-list-item-icon><v-icon>fas fa-circle-dot</v-icon></v-list-item-icon><v-list-item-title>Near-Exact Match</v-list-item-title></v-list-item>
				<v-list-item @click="add_associations('close')"><v-list-item-icon><v-icon>fas fa-circle</v-icon></v-list-item-icon><v-list-item-title>Closely Related</v-list-item-title></v-list-item>
				<v-list-item @click="add_associations('moderate')"><v-list-item-icon><v-icon>fas fa-circle-half-stroke</v-icon></v-list-item-icon><v-list-item-title>Moderately Related</v-list-item-title></v-list-item>
				<v-list-item @click="add_associations('nomatch')"><v-list-item-icon><v-icon>fas fa-ban</v-icon></v-list-item-icon><v-list-item-title>No Match</v-list-item-title></v-list-item>

				<!-- CURRENTLY NOT DOING THIS TYPE OF AUTO-ASSOCIATION -->
				<!-- <v-divider /> -->
				<!-- <v-list-item @click="add_associations('auto')"><v-list-item-icon><v-icon>fas fa-wand-magic-sparkles</v-icon></v-list-item-icon><v-list-item-title>Auto-associate based on match</v-list-item-title></v-list-item> -->
			</v-list>
		</v-menu>

		<v-btn color="primary" class="mr-1 k-tight-btn k-nocaps-btn" @click="show_spot_check('next')">Spot Check Next <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
		<v-btn color="primary" class="mr-1 k-tight-btn k-nocaps-btn" @click="show_spot_check('random')">Spot Check Random <v-icon small class="ml-2">fas fa-shuffle</v-icon></v-btn>
		<v-btn v-if="spot_check_index>-1" color="primary" class="mr-1" @click="show_spot_check('prev')"><v-icon small class="mr-2">fas fa-arrow-left</v-icon>Back</v-btn>

		<!-- <v-btn color="green darken-3" dark class="mr-1 ml-2 k-tight-btn k-nocaps-btn" @click="add_associations('ai-only')"><v-icon small class="mr-2">fas fa-wand-magic-sparkles</v-icon>Save AI Associations</v-btn> -->
	</div>
</div>	<!-- end of assoc mode stuff -->
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASETree from '../CASEFrameworkViewer/CASETree'
import CASEFVAssociationsMixin from '../CASEFrameworkViewer/CASEFVAssociationsMixin'

export default {
	components: { CASETree, },
	mixins: [CASEFVAssociationsMixin],
	props: {
		crosswalk_lsdoc_identifiers: { type: Array, required: true },
		viewer: { type: Object, required: false },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		initialized: false,
		assocs_interface_shown: false,
		// interface_showing: 'settings',	// summary, settings, assocs
		table_showing: true,
		crosswalk_framework_checked_out: false,

		match_params: {},
		item_type_params: {},
		computed_match_params: '',
		computed_settings: '',
		match_scores: [{}, {}],
		match_level_counts: [],
		n_aligned_items: 0,
		n_not_suggestible: 0,
		mean_match_score: 0,
		mean_fs_sb_score: 0,
		mean_n_candidates: 0,

		// if selected_items === false, the item isn't selected at all; otherwise it's the identifier selected
		selected_items: {},

		table_items_hash: {},
		spot_checked_items: {},
		spot_check_row: null,
		spot_check_candidates: null,
		spot_check_index: -1,
		spot_check_candidates_to_show: 5,

		add_hcs_regex_a: false,
		add_hcs_regex_b: false,

		table_options: {
			itemsPerPage: 50,
			page: 1,
		},
		current_page: 1,
		settings_showing: false,

		all_items_selected: false,

		total_matches_to_process: 0,
		matches_processed_so_far: 0,
		compute_progress: 0,
		scores_loading: false,

		tree_mode: false,
		tree_left_chosen_node: undefined,
		tree_right_revealed_node: undefined,
		open_tree_nodes_left: {},
		open_tree_nodes_right: {},
		associating_tree_item: false,
		left_tree_scroll_wrapper_style: '',
		right_tree_scroll_wrapper_style: '',
		tree_suggestions_showing: true,

		// hash to hold normalized fullStatements, to save on computing time
		normalized_fullStatements: {},

		match_level_options: [
			{value: '100', text: '100'},
			{value: '95', text: '95-99'},
			{value: '85', text: '85-94'},
			{value: '60', text: '60-84'},
			{value: '40', text: '40-59'},
			{value: '0', text: '<40'},
			{value: '-1', text: 'N/A'},
		],

		association_type_mappings: {
			'exact': { associationType: 'exactMatchOf' },
			'nearexact': { associationType: 'ext:isNearExactMatch' },
			'close': { associationType: 'ext:isCloselyRelatedTo' },
			'moderate': { associationType: 'ext:isModeratelyRelatedTo' },
			'nomatch': { associationType: 'ext:hasNoMatch' },
			// note that for items saved earlier than 4/2024, these values will be translated from an old scheme in case_data_structures
		},

		cross_crosswalks: [],

		align_resources: false,
	}},
	computed: {
		...mapState(['grades', 'user_info']),
		...mapGetters([]),
		interface_showing: {		// summary, settings, assocs, x
			get() { 
				// when we first open a framework, always go to the summary interface; but after that if we reload, go back to the last-opened interface
				let s = JSON.stringify(this.crosswalk_lsdoc_identifiers)
				if (this.$store.state.last_crosswalk_opened != s) {
					this.$store.commit('lst_set', ['crosswalk_interface_showing', 'summary'])
					this.$store.commit('set', ['last_crosswalk_opened', s])
				}
				let x = this.$store.state.lst.crosswalk_interface_showing
				if (x == 'assocs') this.assocs_interface_shown = true
				return x
			},
			set(val) { this.$store.commit('lst_set', ['crosswalk_interface_showing', val]) }
		},
		lst_key() { 
			// we only save one crosswalk per pair of frameworks; index the crosswalk settings by the order the framework identifiers appear in CFDocument.extensions.crosswalkSourceFrameworkIdentifiers;
			// noting that crosswalk_lsdoc_identifiers tells us which framework we're currently showing on the left and right sides
			return this.crosswalk_framework_record.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.join('-')
			// return `${this.crosswalk_lsdoc_identifiers[0]}-${this.crosswalk_lsdoc_identifiers[1]}` 
		},

		// this is the identifier for the framework on the left -- according to this.crosswalk_lsdoc_identifiers (the left-most identifier in the URL)
		a_identifier() { return this.crosswalk_lsdoc_identifiers[0] },
		// this is the identifier for the framework on the right -- according to this.crosswalk_lsdoc_identifiers (the right-most identifier in the URL)
		b_identifier() { return this.crosswalk_lsdoc_identifiers[1] },
		
		// a_index and b_index are used to reference settings and params from lst/the document:
			// some params/settings are different for the two sides, so are stored in arrays.
			// array[0] refers to the first identifier from crosswalk_framework_record.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers
			// array[1] refers to the second identifier from crosswalk_framework_record.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers
			// if a_index is 0, the left side of the crosswalk editor is governed by array[0]
			// if a_index is 1, the left side of the crosswalk editor is governed by array[1]
		a_index() {
			if (this.crosswalk_framework_record.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers[0] == this.a_identifier) return 0
			if (this.crosswalk_framework_record.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers[0] == this.b_identifier) return 1
			console.error('ERROR COMPUTING a_index')
			return 0
		},
		b_index() { return (this.a_index == 0) ? 1 : 0 },

		include_branches_left: {
			get() { 
				if (this.a_index == 0) return this.$store.state.lst.crosswalk_include_branches_0[this.lst_key] ?? [] 
				else return this.$store.state.lst.crosswalk_include_branches_1[this.lst_key] ?? [] 
			},
			set(val) { 
				if (this.a_index == 0) this.$store.commit('lst_set_hash', ['crosswalk_include_branches_0', this.lst_key, val]) 
				else this.$store.commit('lst_set_hash', ['crosswalk_include_branches_1', this.lst_key, val]) 
			}
		},
		include_branches_right: {
			get() { 
				if (this.b_index == 0) return this.$store.state.lst.crosswalk_include_branches_0[this.lst_key] ?? [] 
				else return this.$store.state.lst.crosswalk_include_branches_1[this.lst_key] ?? [] 
			},
			set(val) { 
				if (this.b_index == 0) this.$store.commit('lst_set_hash', ['crosswalk_include_branches_0', this.lst_key, val]) 
				else this.$store.commit('lst_set_hash', ['crosswalk_include_branches_1', this.lst_key, val]) 
			}
		},
		exclude_branches_left: {
			get() { 
				if (this.a_index == 0) return this.$store.state.lst.crosswalk_exclude_branches_0[this.lst_key] ?? [] 
				else return this.$store.state.lst.crosswalk_exclude_branches_1[this.lst_key] ?? [] 
			},
			set(val) { 
				if (this.a_index == 0) this.$store.commit('lst_set_hash', ['crosswalk_exclude_branches_0', this.lst_key, val]) 
				else this.$store.commit('lst_set_hash', ['crosswalk_exclude_branches_1', this.lst_key, val]) 
			}
		},
		exclude_branches_right: {
			get() { 
				if (this.b_index == 0) return this.$store.state.lst.crosswalk_exclude_branches_0[this.lst_key] ?? [] 
				else return this.$store.state.lst.crosswalk_exclude_branches_1[this.lst_key] ?? [] 
			},
			set(val) { 
				if (this.b_index == 0) this.$store.commit('lst_set_hash', ['crosswalk_exclude_branches_0', this.lst_key, val]) 
				else this.$store.commit('lst_set_hash', ['crosswalk_exclude_branches_1', this.lst_key, val]) 
			}
		},

		make_alignments_for_types: {
			get() { return this.$store.state.lst.crosswalk_make_alignments_for_types[this.lst_key] ?? [[],[]] },
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_make_alignments_for_types', this.lst_key, val]) }
		},
		match_levels: {
			get() { 
				let arr = this.$store.state.lst.crosswalk_match_levels[this.lst_key] ?? []
				// make sure arr is filled in and has exactly match_level_options.length items
				if (arr.length == 0 || arr.length != this.match_level_options.length) {
					arr = []
					for (let i = 0; i < this.match_level_options.length; ++i) arr[i] = true
				}
				return arr
			},
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_match_levels', this.lst_key, val]) }
		},
		show_already_aligned: {
			// can be true or false (default)
			get() { return this.$store.state.lst.crosswalk_show_already_aligned[this.lst_key] ?? true },
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_show_already_aligned', this.lst_key, val]) }
		},
		show_not_suggestible: {
			// can be true or false (default)
			get() { return this.$store.state.lst.crosswalk_show_not_suggestible[this.lst_key] ?? true },
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_show_not_suggestible', this.lst_key, val]) }
		},
		
		show_diff_coloring() { return true },
		// show_diff_coloring: {
		// 	get() { return this.$store.state.lst.crosswalk_show_diff_coloring[this.lst_key] ?? true },
		// 	set(val) { this.$store.commit('lst_set_hash', ['crosswalk_show_diff_coloring', this.lst_key, val]) }
		// },
		table_spacing: {
			get() { 
				// for now we'll always use 'comfy'
				return 'comfy'
				// return this.$store.state.lst.crosswalk_table_spacing[this.lst_key] ?? 'comfy' 
			},
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_table_spacing', this.lst_key, val]) }
		},
		items_per_page: {
			get() { return this.$store.state.lst.crosswalk_items_per_page[this.lst_key] ?? 20 },
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_items_per_page', this.lst_key, val]) }
		},
		show_alignable_only: {
			// can be true or false (default)
			get() { return this.$store.state.lst.crosswalk_show_alignable_only[this.lst_key] ?? true },
			set(val) { this.$store.commit('lst_set_hash', ['crosswalk_show_alignable_only', this.lst_key, val]) }
		},
		relation_threshold_range: {
			get() { return [this.match_params.moderate_relation_threshold, this.match_params.close_relation_threshold] },
			set(val) {[this.match_params.moderate_relation_threshold, this.match_params.close_relation_threshold] = val },
		},
		ed_level_selected: {
			get() { 
				return this.match_params.ed_levels[0] != '' 
			},
			set(val) {
				if (val) this.match_params.ed_levels.shift()
				else this.match_params.ed_levels.unshift('')
			}
		},
		ed_level_range: {
			get() { 
				return [
					this.grades.findIndex(x=>x.value == this.match_params.ed_levels[0]),
					this.grades.findIndex(x=>x.value == this.match_params.ed_levels[this.match_params.ed_levels.length-1]),
				]
			},
			set(val) {
				let arr = []
				for (let i = val[0]; i <= val[1]; ++i) {
					arr.push(this.grades[i].value)
				}
				this.match_params.ed_levels = arr
			},
		},
		ed_level_match_tolerance_for_radio: {
			get() { return this.match_params.ed_level_match_tolerance + '' },
			set(val) { this.match_params.ed_level_match_tolerance = val * 1 }
		},
		no_spot_check_candidates_hovering() {
			// if any spot-check candidate is being hovered over, return false -- something *is* being hovered
			for (let candidate of this.spot_check_candidates) {
				if (candidate.hovering) return false
			}
			return true
		},
		search() { 
			// TODO: we're no longer showing the viewer in all cases where the crosswalk tool is shown, so if we want to support search, we need to show a separate search input
			// if (this.viewer) return this.viewer.search_terms 
			return ''
		},
		search_re() {
			let s = $.trim(this.search)
			if (empty(s)) return null
			return new RegExp(s, 'i')
		},
		framework_records() {
			let arr = []

			let o = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.a_identifier)
			if (empty(o)) o = {}
			arr[0] = o

			o = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.b_identifier)
			if (empty(o)) o = {}
			arr[1] = o

			return arr
		},
		framework_record_a() { return this.framework_records[0] },
		framework_record_b() { return this.framework_records[1] },

		crosswalk_framework_record() {
			let o = U.get_crosswalk_framework_record(this.crosswalk_lsdoc_identifiers[0], this.crosswalk_lsdoc_identifiers[1])
			return (empty(o)) ? {} : o
		},

		hcs_regex_params_a() {
			return this.match_params.hcs_regex_params[this.a_index]
		},
		hcs_regex_params_b() {
			return this.match_params.hcs_regex_params[this.b_index]
		},

		// return true if we need to show transformed hcs values in text (for comparison purposes)
		show_transformed_hcs_values() {
			if (this.hcs_regex_params_a.length == 0) return false
			if (this.match_params.hcs_weight == 0) return false
			return true
		},

		item_type_params_old() {
			return this.match_params.item_types
		},

		match_scores_a_computed() {
			return U.object_has_keys(this.match_scores[0])
		},

		item_type_select_options() {
			let arr = []
			let arr2 = []
			for (let item_type of this.framework_record_a.cfo.item_types) arr2.push(item_type)
			arr2.push('[no item type]')
			arr[0] = arr2

			arr2 = []
			for (let item_type of this.framework_record_b.cfo.item_types) arr2.push(item_type)
			arr2.push('[no item type]')
			arr[1] = arr2

			return arr
		},
		item_type_select_options_a() { return this.item_type_select_options[0] },
		item_type_select_options_b() { return this.item_type_select_options[1] },

		table_no_items_msg() {
			// if we're here, nothing is showing in the table...
			// if we have items to show but no filters are selected, say that
			let mlc
			for (mlc of this.match_level_counts) {
				if (mlc > 0) return 'All items are being hidden by the “Show match level” filters.'
			}
			// else if we have aligned items and aren't showing them, say that
			if (this.n_aligned_items > 0 && !this.show_already_aligned) {
				return `Note that ${this.n_aligned_items} item(s) are being hidden by the “Show already-associated items” filter.`
			}
			// else if something is in the search string say that
			if (this.search_re) {
				return 'No items match your search terms.'
			}
			return ''
		},

		table_items() {
			// can't render anything until initialized and assocs_interface_shown
			if (!this.initialized || !this.assocs_interface_shown) return []

			// and for now don't render until we finish computing
			if (this.compute_progress < 100) return []

			let table_items_hash = {}
			let arr = []
			let tree_sequence = [0]
			let match_level_counts = [0,0,0,0,0,0,0]
			let sum_max_match_score = 0, sum_max_fs_sb_score = 0, sum_n_candidates = 0, sum_n = 0
			let n_aligned_items = 0
			let n_not_suggestible = 0
			let overall_sequence = 0

			/////////////////////////////////// start of add item
			let add_item = (node, level) => {
				let cfitem = node.cfitem
				++overall_sequence

				++tree_sequence[level]
				let tree_sequence_value = ''
				for (let i = 0; i < 30; ++i) {
					if (i <= level) {
						if (tree_sequence_value) tree_sequence_value += '.'
						tree_sequence_value += tree_sequence[i]
					} else {
						tree_sequence[i] = 0
					}
				}

				// if we have exclude branches for the left side, skip the item *and its children*
				if (this.exclude_branches_left.includes(cfitem.identifier)) {
					return
				}

				let msa = this.match_scores[0][cfitem.identifier]
				let item_type = U.item_type_string(cfitem)
				if (empty(item_type)) item_type = '[no item type]'
				let statement_sort_value = cfitem.humanCodingScheme + ' ' + cfitem.fullStatement
				let match_sort_value = (!msa || msa.candidates.length == 0) ? -1 : msa.candidates[0].match_score

				if (msa && msa.candidates.length > 0) {
					// get array here for ai-only assocs
					let arr = []
					let max_fs_sb_score = 0		// calculate the max fs_sb_score, which may not be the first candidate
					for (let candidate of msa.candidates) {
						// always store at least ai_only_min_n candidate(s)
						if (arr.length < this.match_params.ai_only_min_n || candidate.match_score > this.match_params.ai_only_threshold) {
							// console.log(`match for ${origin_cfitem.humanCodingScheme}: ${candidate.match_score} - ${candidate.match_object.cfitem.humanCodingScheme}: ${candidate.match_object.cfitem.fullStatement.substr(0,20)}`)
							// for each candidate, push [identifier, overall match_score, FS-only sb_score]
							arr.push([candidate.match_object.cfitem.identifier, candidate.match_score, candidate.fs_sb_score])
							if (candidate.fs_sb_score > max_fs_sb_score) max_fs_sb_score = candidate.fs_sb_score
						}
						if (arr.length >= this.match_params.ai_only_max_n) break
					}

					// calculate averages as we go
					sum_max_fs_sb_score += max_fs_sb_score
					sum_max_match_score += msa.candidates[0].match_score * 1
					sum_n_candidates += arr.length
					sum_n += 1

					// JSON.stringify the array for use if/when we create the ai-only associations
					msa.ai_only_assoc_simscores = JSON.stringify(arr)
				}

				// skip if show_alignable_only is true and we've computed match_scores and the item isn't in match_scores
				// NOTE: currently show_alignable_only is always true
				let include = true
				//if (this.show_alignable_only && this.match_scores_a_computed) {
				//	if (!this.match_scores[0][cfitem.identifier]) include = false
				//}
				if (!this.item_type_params[item_type]?.alignable) {
					include = false
				}

				// also check the grade filter for the left-side item
				if (include && this.match_params.ed_levels.length > 0 && this.match_params.ed_levels[0] != '') {
					let found_ed_match = false
					for (let el of this.match_params.ed_levels) {
						if (cfitem.educationLevel.includes(el)) found_ed_match = true
					}
					if (!found_ed_match) include = false
				}

				// check to see if we already have at least one association for this left-side item
				let existing_assocs = this.crosswalk_framework_record.cfo.associations_hash[cfitem.identifier]
				let has_existing_assoc = false
				if (include && existing_assocs) {
					// NOTE: currently, we're only dealing with allowing for a single bidirectional association for each item; this will be more complex when we allow for multiple

					for (let assoc of existing_assocs) {
						// we only show/deal with relatedTo, exactMatch, and their extensions in the crosswalk tool
						if (['isRelatedTo', 'exactMatchOf', 'ext:isNearExactMatch', 'ext:isCloselyRelatedTo', 'ext:isModeratelyRelatedTo', 'ext:hasNoMatch'].includes(assoc.associationType)) {
							has_existing_assoc = true
							// increment n_aligned_items count if the item has alignments
							++n_aligned_items
							break
						}
					}
				}

				// check to see if the left-side item has suggestions, which will be the case IFF it has at least one item on the right that meets the item type/education level filters
				let not_suggestible = false
				if (include && (!msa || msa.candidates.length == 0)) {
					not_suggestible = true
					++n_not_suggestible
				}

				// now, after dealing with n_aligned_items/n_not_suggestible, skip if filters say to do so...
				// so skip if it has an existing assoc and we're not supposed to be showing existing assocs
				if (has_existing_assoc && !this.show_already_aligned) include = false
				// and skip if it doesn't have suggestions and we're not supposed to be showing items that don't have suggestions
				if (not_suggestible && !this.show_not_suggestible) include = false

				// if we don't have computed match scores, only include already-associated items (for which we will have a match_sort_value from the association record)
				// if (!this.match_scores_a_computed && !existing_assocs) include = false

				// search string filter
				if (include && this.search_re) {
					include = false
					if (cfitem.identifier.search(this.search_re) > -1) include = true
					if (statement_sort_value.search(this.search_re) > -1) include = true
				}

				let add_one_item = (existing_assoc, match_sort_value, primary_association) => {
					let _include = include
	
					// compute match level
					let match_level = (existing_assoc) ? this.get_match_level(existing_assoc) : this.get_match_level(match_sort_value)
	
					// if we get to here and the item is going to be included so far, add to match_level_counts
					if (_include && match_level > -1) ++match_level_counts[match_level]
	
					// now filter based on match level
					if (!this.match_levels[match_level] && match_level != -1) {
						_include = false
					}

					// exclude items not on the current page
					// if (_include) _include = (this.current_page - 1) * this.items_per_page <= arr.length && arr.length < (this.current_page) * this.items_per_page
	
					// if (!_include) console.log('not including: ', this.item_html(cfitem))
					
					// _include this item if it meets criteria above
					if (_include) {
						let row = {}
	
						row.hovering = false
						row.checked = false
						row.primary_association = primary_association
	
						row.cfitem = cfitem
						row.match_score_object = msa
	
						row.identifier = cfitem.identifier
						row.statement_sort_value = cfitem.humanCodingScheme + ' ' + cfitem.fullStatement
						row.item_html_a = this.item_html(cfitem)
						// show transformed hcs if necessary
						if (this.show_transformed_hcs_values && msa > 0 && msa.normalized_hcs) row.item_html_a += ` [${msa.normalized_hcs}]`
	
						row.overall_sequence = overall_sequence
						row.tree_sequence = tree_sequence_value
						row.item_type = item_type
						row.grade_level_display = U.grade_level_display(cfitem.educationLevel)
	
						row.match_sort_value = match_sort_value
						row.match_level = match_level
	
						if (match_sort_value == -1) {
							row.table_cell_center = ''
						} else {
							row.table_cell_center = `<nobr>${this.format_match_value(match_sort_value, 1)}</nobr>`
						}
	
						// if we already have an association...
						if (existing_assoc) {
							row.existing_assoc = existing_assoc
							row.associationType = existing_assoc.associationType
							row.assoc_icon = this.assoc_icon(existing_assoc)
	
							// get right_identifier from the other side of the assoc
							row.right_identifier = (existing_assoc.destinationNodeURI.identifier == row.identifier) ? existing_assoc.originNodeURI.identifier : existing_assoc.destinationNodeURI.identifier
							if (row.right_identifier == CFAssociation.nomatch_guid) {
								row.right_grade_level_display = ''
								row.right_item_type = ''
								row.item_html_b = 'NO MATCH'
							} else {
								let assoc_cfitem = this.framework_record_b.cfo.cfitems[row.right_identifier]
								row.right_grade_level_display = U.grade_level_display(assoc_cfitem.educationLevel)
								row.right_item_type = assoc_cfitem.CFItemType
								row.item_html_b = this.item_html(assoc_cfitem)
								// show transformed hcs if we have one
								if (msa && msa.candidates && msa.candidates[0]) {
									let msb = msa.candidates[0].match_object
									if (this.show_transformed_hcs_values && msb && msb.normalized_hcs) row.item_html_b += ` [${msb.normalized_hcs}]`
								}
								if (this.show_diff_coloring) {
									let ds = U.diff_string(row.item_html_a, row.item_html_b)
									row.item_html_a = ds.o
									row.item_html_b = ds.n
								} else {
									// don't use diff_string if show_diff_coloring is off
									row.item_html_a = U.marked_latex(row.item_html_a)
									row.item_html_b = U.marked_latex(row.item_html_b)
								}
							}

						} else {
							if (!msa || msa.candidates.length == 0) {
								row.right_identifier = ''
							} else {
								let msb = msa.candidates[0].match_object
								row.right_identifier = msb.cfitem.identifier
								row.right_grade_level_display = U.grade_level_display(msb.cfitem.educationLevel)
								row.right_item_type = msb.item_type
								row.item_html_b = this.item_html(msb.cfitem)
								if (this.show_transformed_hcs_values && msb && msb.normalized_hcs) row.item_html_b += ` [${msb.normalized_hcs}]`
								if (this.show_diff_coloring) {
									let ds = U.diff_string(row.item_html_a, row.item_html_b)
									row.item_html_a = ds.o
									row.item_html_b = ds.n
								} else {
									// don't use diff_string if show_diff_coloring is off
									row.item_html_a = U.marked_latex(row.item_html_a)
									row.item_html_b = U.marked_latex(row.item_html_b)
								}
							}
						}

						row.table_key = row.identifier + row.associationType + row.right_identifier
	
						arr.push(row)
						table_items_hash[row.identifier] = row
					}

					return _include
				}

				// if the item has one or more existing assocs, show them
				if (has_existing_assoc) {
					let last_assocs_included = []
					for (let existing_assoc of existing_assocs) {
						// skip associations we don't deal with in the crosswalk tool
						if (!['isRelatedTo', 'exactMatchOf', 'ext:isNearExactMatch', 'ext:isCloselyRelatedTo', 'ext:isModeratelyRelatedTo', 'ext:hasNoMatch'].includes(existing_assoc.associationType)) continue
						let _match_sort_value
						if (msa) {
							_match_sort_value = msa.candidates.find(x=>x.match_object.cfitem.identifier==existing_assoc.destinationNodeURI.identifier)?.match_score
						}
						last_assocs_included.push(add_one_item(existing_assoc, _match_sort_value || match_sort_value, existing_assoc == existing_assocs[0] || !last_assocs_included.includes(true)))
					}
				} else {
					add_one_item(null, match_sort_value, true)
				}

				// then process the item's children
				for (let child_node of node.children) {
					add_item(child_node, level + 1)
				}
			}
			/////////////////////////////////// end of add item

			// only include included branches if any are marked as include only
			let items_successfully_limited = false
			for (let identifier of this.include_branches_left) {
				if (!this.framework_record_a.cfo.cfitems[identifier]) continue
				let node = this.framework_record_a.cfo.cfitems[identifier].tree_nodes[0]
				add_item(node, 0)
				items_successfully_limited = true
			}
			// if none of the limiters worked for the left, process each top-level item in the tree
			if (!items_successfully_limited) {
				for (let child_node of this.framework_record_a.cfo.cftree.children) {
					add_item(child_node, 0)
				}
			}
			
			this.n_aligned_items = n_aligned_items
			this.n_not_suggestible = n_not_suggestible

			if (sum_n == 0) {
				this.mean_match_score = 0
				this.mean_fs_sb_score = 0
				this.mean_n_candidates = 0
			} else {
				this.mean_match_score = (sum_max_match_score / sum_n).toFixed(1)
				this.mean_fs_sb_score = (sum_max_fs_sb_score / sum_n).toFixed(1)
				this.mean_n_candidates = (sum_n_candidates / sum_n).toFixed(1)
			}
			this.match_level_counts = match_level_counts
			this.table_items_hash = table_items_hash

			this.$store.commit('set', ['crosswalk_available_left_items', this.lst_key, Object.keys(table_items_hash)])

			// if on a page that does not have any items on it, move to the last possible page
			if (((this.current_page - 1) * this.items_per_page > (arr.length - 1)) || this.current_page < 0) this.current_page = Math.max(Math.floor((arr.length - 1) / this.items_per_page) + 1, 1)
			// console.log(this.current_page)
			// console.log(arr)
			if (this.spot_check_row && !Object.keys(this.table_items_hash).includes(this.spot_check_row.identifier)) {
				this.spot_check_candidates = null
				this.spot_check_row = null
			}
			if (this.spot_check_row) {
				this.current_page = Math.max(Math.floor((arr.indexOf(this.table_items_hash[this.spot_check_row.identifier])) / this.items_per_page) + 1, 1)
			}

			return arr
		},

		tree_items() {
			let right_items = []
			let right_hash = {}
			if (this.tree_left_chosen_node && this.framework_record_a.cfo.cfitems[this.tree_left_chosen_node.cfitem.identifier] && this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier]?.match_score_object) {
				right_items = this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier].match_score_object.candidates
				for (let candidate of this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier].match_score_object.candidates) {
					right_hash[candidate.match_object.cfitem.identifier] = candidate
				}
			}

			return {
				left: this.table_items, right: right_items, left_hash: this.table_items_hash, right_hash: right_hash
			}
		},
		right_associated_items() {
			if (!this.tree_left_chosen_node) return []
			let left_selected_id = this.tree_left_chosen_node.cfitem.identifier
			let associations = this.crosswalk_framework_record.cfo.associations_hash[left_selected_id]
			let association_ids = []
			if (associations == undefined) return []
			for (let association of associations) {
				let id = association.destinationNodeURI.identifier
				if (id == left_selected_id) id = association.originNodeURI.identifier
				association_ids.push(id)
			}
			return association_ids
		},

		n_items_to_associate() {
			let n = 0
			for (let id in this.selected_items) {
				if (this.selected_items[id] && this.table_items_hash[id]) {
					++n
				}
			}
			return n
		},
		n_items_to_associate_deletable() {
			let n = 0
			for (let id in this.selected_items) {
				if (this.selected_items[id] && this.table_items_hash[id]) {
					if (this.table_items_hash[id].existing_assoc) ++n
				}
			}
			return n
		},

		page_options() {
			let arr = [1]
			for (let i = 1; i < Math.ceil(this.table_items.length / this.items_per_page); i++) arr.push(i + 1)
			return arr
		},

		expanded_mlos_showing() { return this.$vuetify.breakpoint.width > 1600 },

		// this returns true iff all filtered items' ai-only simscores match what is currently calculated
		ai_only_matches_unchanged() {
			if (this.compute_progress < 100) return false	// don't start processing this until we're done computing

			for (let item of this.table_items) {
				let ai_only_assoc = this.crosswalk_framework_record.cfo.ai_only_associations_hash[item.identifier]
				// if we don't have an ai_only_assoc, or the ai_only_assoc's simscores value doesn't match the currently-calculated value, return false -- something doesn't match
				if (empty(ai_only_assoc)) return false
				// console.warn(ai_only_assoc.extensions?.simscores, item.match_score_object?.ai_only_assoc_simscores)
				if (ai_only_assoc.extensions?.simscores != item.match_score_object?.ai_only_assoc_simscores) return false
			}
			// if we get to here everything is unchanged!
			return true
		},

		framework_a_stats() {
			return U.get_crosswalk_framework_stats(
				this.crosswalk_framework_record,
				this.framework_record_a,
			)
		},

		framework_b_stats() {
			return U.get_crosswalk_framework_stats(
				this.crosswalk_framework_record,
				this.framework_record_b,
			)
		},
	},

	watch: {
		interface_showing: { immediate: true, handler(val) {
			if (this.interface_showing == 'assocs') {
				if (this.match_params.hcs_regex_params) {
					// console.warn('computing match scores after interface_showing change')
					this.compute_all_match_scores()
				}
			}
		}},

		tree_mode() {
			if (this.tree_mode) {
				this.$refs.main_container.scrollTo({top: this.$refs.main_container.clientHeight, behavior: "smooth"})
				if (!this.spot_check_row && this.tree_left_chosen_node) {
					this.left_tree_show_chooser_fn(null, this.tree_left_chosen_node, null)
					
				}
			}
			else {
				if (this.spot_check_row) {
					this.current_page = Math.max(Math.floor(this.table_items.indexOf(this.spot_check_row) / this.items_per_page) + 1, 1)
					window.setTimeout(()=>{
						this.$refs.main_container.scrollTo({top: this.$refs['table_item_' + this.spot_check_row.identifier][0].offsetTop - 105, behavior: "smooth"})
					}, 0)
				}
			}
			this.update_associations_to_show_for_crosswalk()
		},
	},
	created() {
		document.addEventListener("keydown", (ev) => {
			if (ev.key == "ArrowLeft") this.show_last_spot_check()
			if (ev.key == "ArrowRight") this.show_next_spot_check()
		})
	},
	mounted() {
		this.initialize()
		vapp.crosswalk_editor_component = this
	},
	methods: {
		initialize() {
			// set itemsPerPage to value from store
			this.table_options.itemsPerPage = this.$store.state.lst.crosswalk_table_items_per_page

			// check for left and right frameworks
			if (!this.framework_record_a.json) {
				this.$alert(`No framework with the identifier ${this.crosswalk_lsdoc_identifiers[0]} is available on this instance of ${this.$store.state.site_config.app_name}.`)
				vapp.go_to_route('')
				return
			}
			if (!this.framework_record_b.json) {
				this.$alert(`No framework with the identifier ${this.crosswalk_lsdoc_identifiers[1]} is available on this instance of ${this.$store.state.site_config.app_name}.`)
				vapp.go_to_route('')
				return
			}

			// for now, at least, we'll only open this interface to users that have framework editor rights to at least one of the two frameworks
			if (!vapp.is_granted('edit_framework', this.framework_record_a.lsdoc_identifier) && !vapp.is_granted('edit_framework', this.framework_record_b.lsdoc_identifier)) {
				this.$alert(`Only users with editing rights to at least one of the frameworks can create and edit crosswalks.`)
				vapp.go_to_route('')
				return
			}

			// now check for a crosswalk framework; 
			let crosswalk_framework_being_created = false
			if (!this.crosswalk_framework_record.json) {
				// for now, if the crosswalk framework doesn't already exist, we can just create it now, since we know the user has create_new_framework rights
				// TODO: once we allow for selective access to crosswalks, this might get more complicated...
				crosswalk_framework_being_created = true
				U.create_crosswalk_framework(this.framework_record_a, this.framework_record_b).then(x=>{
					this.initialize_2()
				})
			} else {
				// if the crosswalk framework does already exist, check it out for editing now
				this.check_out_crosswalk_framework_for_editing()
			}

			// make sure the user has access to both frameworks
			if (!vapp.is_granted('view_framework', this.crosswalk_lsdoc_identifiers[0]) || !vapp.is_granted('view_framework', this.crosswalk_lsdoc_identifiers[0])) {
				this.$confirm({
				    text: 'At least one of the frameworks specified to be crosswalked is not publicly available at this time. Would you like to sign in?',
				    acceptText: 'Sign In',
				    cancelText: 'View Public Frameworks',
					dialogMaxWidth: 600
				}).then(y => {
					vapp.sign_in()
				}).catch(n=>{
					vapp.go_to_route('')
				}).finally(f=>{})
				return
			}

			// now that we've established the left and right frameworks, find any cross_crosswalks
			this.$nextTick(x=>this.find_cross_crosswalks())

			// if both frameworks to be crosswalked, as well as the crosswalk_framework_record, are loaded, go to initialize_2
			if (!empty(this.framework_record_a.cfo) && !empty(this.framework_record_b.cfo) && !empty(this.crosswalk_framework_record.cfo)) {
				this.initialize_2()
				return
			}

			// else load whatever we need to load
			U.loading_start('Loading framework(s)…', 'refresh_lsdoc')
			if (!this.framework_record_a.framework_json_loaded) this.load_lsdoc(this.framework_record_a)
			if (!this.framework_record_b.framework_json_loaded) this.load_lsdoc(this.framework_record_b)
			// if the crosswalk framework doesn't exist at all, we initiated creating it above; that will call initialize_2 like load_lsdoc does
			if (!this.crosswalk_framework_record.cfo && !crosswalk_framework_being_created) this.load_lsdoc(this.crosswalk_framework_record, 'crosswalk')

			this.set_tree_scroll_wrapper_style()
		},

		open_framework($evt, framework_identifier) {
			vapp.framework_list_component.view_framework(framework_identifier, framework_identifier, $evt)
		},

		set_tree_scroll_wrapper_style() {
			// style for left tree
			this.left_tree_scroll_wrapper_style = `height: calc(100vh - 110px);`

			// right tree starts with same style, but has to accommodate the suggestions box
			this.right_tree_scroll_wrapper_style = this.left_tree_scroll_wrapper_style
			let ht = $('#k-crosswalk-tree-suggestions').height()
			this.right_tree_scroll_wrapper_style += `padding-bottom:calc(${ht+16}px)`
			// console.warn(`set_tree_scroll_wrapper_style: ${ht}`)

			// recalculate as the suggestions change
			setTimeout(x=>{this.set_tree_scroll_wrapper_style()}, 50)

			// original div from CaseTree.vue
			// <div class="k-case-tree-scroll-wrapper" :class="scroll_wrapper_and_font_size_class" v-scroll.self="tree_scrolled" :style="crosswalk ? (`margin-bottom: 105px; height: calc(100vh - 160px);${framework_record!=crosswalk.framework_record_a ? 'padding-top:calc(30vh + 16px)':''}`) : ''">
		},

		check_out_crosswalk_framework_for_editing() {
			let payload = {
				'framework_identifier': this.crosswalk_framework_record.json.CFDocument.identifier,
				'edit_action': 'framework_checkout',
				'cf_item_count': this.crosswalk_framework_record.json.CFItems.length,
				'cf_association_count': this.crosswalk_framework_record.json.CFAssociations.length,
			}

			this.$store.dispatch('manage_edit_lock', payload).then((result)=>{
				// console.log('edit request: ' + result.status)
				this.crosswalk_framework_checked_out = true
			}).catch((e)=>{
				// if this doesn't work, set crosswalk_framework_checked_out to false
				this.crosswalk_framework_checked_out = false
				// console.log(e)
			})
		},

		check_in_crosswalk_framework_for_editing() {
			let payload = {
				'framework_identifier': this.crosswalk_framework_record.json.CFDocument.identifier,
				'edit_action': 'framework_checkin'
			}
			this.$store.dispatch('manage_edit_lock', payload)
			this.crosswalk_framework_checked_out = false
		},

		load_lsdoc(framework_record, flag) {
			console.warn('loading ' + framework_record.json.CFDocument.title)
			this.$store.dispatch('get_lsdoc', {lsdoc_identifier: framework_record.lsdoc_identifier}).then(()=>{
				// process each of the CFAssociations
				if (flag == 'crosswalk') for (let i = 0; i < framework_record.json.CFAssociations.length; ++i) {
					framework_record.json.CFAssociations[i] = new CFAssociation(framework_record.json.CFAssociations[i])
				}

				// note that while in CASEFVAssociationsMixin, we don't need a cfo for the crosswalks, here we do
				U.build_cfo(this.$worker, framework_record.json).then((cfo)=>{
					this.$store.commit('set', [framework_record, 'cfo', cfo])
					this.$store.commit('set', [framework_record, 'framework_json_loading', false])
					this.initialize_2()
				}) 

			}).catch((e)=>{
				// console.log(e)
				U.loading_stop('refresh_lsdoc')
				this.$alert('An error occurred when loading a competency framework.').then(x=>vapp.go_to_route(''))
			})
		},

		initialize_2() {
			// if one of the frameworks is still loading, return; we'll come back here when it's done
			if (empty(this.framework_record_a.cfo) || empty(this.framework_record_b.cfo) || empty(this.crosswalk_framework_record.cfo)) {
				return
			}

			U.loading_stop('refresh_lsdoc')

			this.get_match_params()

			// set initial item_type_params
			this.set_item_type_params()

			// set the CASEFVAssociationsMixin settings for the crosswalk
			this.set_associations_to_show_for_crosswalk()

			// if no types are chosen, open settings
			// TODO: need more here?
			if (!this.match_params || !this.match_params.item_type_alignments) this.settings_showing = true
			else if (this.make_alignments_for_types[this.a_index].length == 0) this.settings_showing = true

			// load the sparkl_bot_vectors for both frameworks
			// TODO: only if we don't already have them??
			let a_vectors_loaded = false, b_vectors_loaded = false
			this.$store.dispatch('get_framework_sparkl_bot_vectors', this.framework_record_a.lsdoc_identifier).then(x=>{
				a_vectors_loaded = true
				if (b_vectors_loaded && this.assocs_interface_shown) this.compute_all_match_scores()
			})
			this.$store.dispatch('get_framework_sparkl_bot_vectors', this.framework_record_b.lsdoc_identifier).then(x=>{
				b_vectors_loaded = true
				if (a_vectors_loaded && this.assocs_interface_shown) this.compute_all_match_scores()
			})

			this.initialized = true
		},

		// show_settings(evt) {
		// 	this.show_settings_evt = evt
		// 	this.settings_showing = true
		// },

		// close_settings() {
		// 	this.settings_showing = false
		// 	this.compute_all_match_scores()
		// 	setTimeout(()=>{ if (!empty(this.show_settings_evt?.target)) $(this.show_settings_evt.target).closest('button').blur() }, 0)
		// },
		
		switch_sides() {
			setTimeout(()=>{ vapp.go_to_route(`crosswalk/${this.crosswalk_lsdoc_identifiers[1]}/${this.crosswalk_lsdoc_identifiers[0]}`) }, 0)
			// setTimeout(()=>{ vapp.crosswalk_editor_component.show_settings() }, 1)
			vapp.go_to_route('')
		},

		item_html(item, statement_length, hcs) {
			if (!item) return ""
			let s = item.fullStatement
			if (statement_length) {
				s = s.substr(0, statement_length)
				if (s != item.fullStatement) s += '…'
			}
			// if we received a "custom" hcs value, use it
			if (!empty(hcs)) s = `<b>${hcs}</b> ${s}`
			else if (!empty(item.humanCodingScheme)) s = `<b>${item.humanCodingScheme}</b> ${s}`
			return s
		},

		match_level_class(match_level) {
			if (match_level == 0) return 'green'
			else if (match_level == 1) return 'teal'
			else if (match_level == 2) return 'blue'
			else if (match_level == 3) return 'indigo'
			else if (match_level == 4) return 'deep-orange'
			else if (match_level == 5) return 'amber'
			else return 'grey'
		},

		row_class(item) {
			let s = ''
			s += this.match_level_class(item.match_level) + ' lighten-4'
			return s
		},
		
		assoc_icon(association) {
			return this.$store.state.association_type_icons[association.associationType]
		},

		table_search_filter(value, search, item) {
			// NOT CURRENTLY USED

			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// console.log(extobj(this.match_params.ed_levels))

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true
			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			if (item.identifier == search) return true
			if (item.statement_sort_value.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		update_items_per_page(val) {
			// when the user chooses a different items-per-page value, save in store so we can restore that number when the table re-opens later
			this.$store.commit('lst_set', ['crosswalk_table_items_per_page', val])
		},

		get_match_params() {
			// console.warn('get_match_params!: ' + this.lst_key)
			// look for value in document.extensions.satchelSettings
			let o = null
			let crosswalk_match_params = this.crosswalk_framework_record.json.CFDocument.extensions.satchelSettings?.crosswalk_match_params
			// we save this as a stringified object
			if (crosswalk_match_params) o = JSON.parse(crosswalk_match_params)

			// if it's an array, it's a legacy value; have to reset
			if ($.isArray(o)) o = null
			
			// if still not found, start with empty object
			if (!o) o = {}

			// fill in default values as needed
			// o.item_type_alignments = []
			sdp(o, o, 'item_type_alignments', [])
			// sdp(o, o, 'item_types', {})

			// [[new RegExp('GSE-', 'gi'), '']]
			sdp(o, o, 'hcs_regex_params', [])

			// higher hcs_power_adjstment values cause exact matches to be weighted more heavily than partial matches
			sdp(o, o, 'hcs_power_adjustment', 5)

			// how much sparkl_bot is used in the match calculation
			sdp(o, o, 'sparkl_bot_weight', 100)

			// next set are esoteric, and currently not editable; see the code
			sdp(o, o, 'hcs_num_mult', 6)
			sdp(o, o, 'hcs_char_mult', 6)
			sdp(o, o, 'hcs_numeric_equivalent_val', 1)
			sdp(o, o, 'hcs_nonnumeric_equivalent_val', 1)

			// relative weights of cross_crosswalks, full statement, hcs, and parents in determining match
			sdp(o, o, 'cross_crosswalk_weight', 2)
			sdp(o, o, 'full_statement_weight', 1)
			sdp(o, o, 'hcs_weight', 0)
			sdp(o, o, 'parent_weight', 0)

			// if first value is a single empty string (['']), include all grades; after this it should include a list of educationLevels
			sdp(o, o, 'ed_levels', [])

			// can be: -1 (don't worry about ed level at all), 0 (ed levels must overlap precisely - default), 1 (ed levels can be off by at most 1), or > 1
			sdp(o, o, 'ed_level_match_tolerance', 0) 

			// determines the threshold between "close" and "moderate" relations when auto-associating
			sdp(o, o, 'close_relation_threshold', 85)
			// determines the threshold between "moderate" relations and items that won't get associated when auto-associating
			sdp(o, o, 'moderate_relation_threshold', 60)

			// create objects for hcs_regex params
			for (let k = 0; k <= 1; ++k) {
				if (!o.hcs_regex_params[k]) o.hcs_regex_params[k] = []
				for (let j = 0; j < 4; ++j) {
					if (!o.hcs_regex_params[k][j]) o.hcs_regex_params[k][j] = {}
					sdp(o.hcs_regex_params[k][j], o.hcs_regex_params[k][j], 'search', '')
					sdp(o.hcs_regex_params[k][j], o.hcs_regex_params[k][j], 'replace', '')
				}
			}

			// for now we'll disable limiting by ed_levels, and instead just go with branches
			o.ed_levels = ['']

			// currently not selectable, but should be
			sdp(o, o, 'ai_only_threshold', 40)
			sdp(o, o, 'ai_only_min_n', 5)
			sdp(o, o, 'ai_only_max_n', 20)

			this.match_params = o
		},

		select_all_match_levels() {
			this.match_levels = [true, true, true, true, true, true, true]
		},
		select_none_match_levels() {
			this.match_levels = [false, false, false, false, false, false, false]
		},

		match_params_changed() {
			// save to file, (used to save to lst)
			// console.log('match_params_changed', extobj(this.match_params))
			this.convert_from_item_type_params()
			this.save_match_params()
			this.add_hcs_regex_a = false
			this.add_hcs_regex_b = false
		},

		save_match_params() {
			// note that we also save the match_params any time we save associations (though that might be redundant)
			let data = {
				lsdoc_identifier: this.crosswalk_framework_record.lsdoc_identifier,
				show_spinner: false,
			}

			let cfd = new CFDocument(this.crosswalk_framework_record.json.CFDocument)
			cfd.extensions.satchelSettings.crosswalk_match_params = JSON.stringify(this.match_params)
			this.$store.commit('set', [this.crosswalk_framework_record.json, 'CFDocument', cfd.to_json()])
			data.CFDocument = cfd.to_json_for_update()
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// set lastChangeDateTime for CFDocument
				this.$store.commit('set', [this.crosswalk_framework_record.json.CFDocument, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])

				// if we didn't already check the crosswalk framework out for editing (because it just got created), do so now
				if (!this.crosswalk_framework_checked_out) this.check_out_crosswalk_framework_for_editing()
			})
		},

		set_item_type_params() {
			// convert from item_type_alignments / make_alignments_for_types to "original" structure
			let o = {}
			for (let itso of this.item_type_select_options_a) {
				o[itso] = {
					align_to: [], 
					alignable: false
				}
			}
			for (let ital of this.match_params.item_type_alignments) {
				let left_type, right_type
				if (this.a_index == 0) { left_type = ital[0]; right_type = ital[1]; }
				else { left_type = ital[1]; right_type = ital[0]; }

				if (empty(o[left_type])) {	// shouldn't happen
					console.warn(`creating item_type_params; can't deal with item_type ${left_type}`)
					continue
				}
				o[left_type].align_to.push(right_type)
				o[left_type].alignable = this.make_alignments_for_types[this.a_index].includes(left_type)
			}

			this.item_type_params = o
		},

		convert_from_item_type_params() {
			// console.log(object_copy(this.item_type_params))
			let ital_arr = []
			let maft_arr = this.make_alignments_for_types.concat([])
			for (let left_type in this.item_type_params) {
				if (this.item_type_params[left_type].alignable) maft_arr[this.a_index].push(left_type)
				for (let right_type of this.item_type_params[left_type].align_to) {
					if (this.a_index == 0) ital_arr.push([left_type, right_type])
					else ital_arr.push([right_type, left_type])
				}
			}
			// console.log('convert_from_item_type_params', ital_arr, maft_arr)
			this.match_params.item_type_alignments = ital_arr
			this.make_alignments_for_types = maft_arr
		},

		open_full_item(item) {
			let left_identifier = item.identifier || item.left_identifier
			let right_identifier = item.right_identifier

			this.show_spot_check(this.table_items_hash[left_identifier], undefined, false)
			this.show_left_item_in_tree(this.table_items_hash[left_identifier])
			this.show_right_item_in_tree(right_identifier)
			this.tree_mode = true
		},

		match_box_clicked(row, right_identifier) {
			let left_identifier = row.identifier || row.left_identifier
			// console.log(left_identifier)
			let val = (this.selected_items[left_identifier] && this.selected_items[left_identifier] == right_identifier) ? false : right_identifier
			this.$set(this.selected_items, left_identifier, val)

			// reset spot_checked_items whenever the set of selected items changes
			this.spot_checked_items = {}

			this.items_to_be_selected()
		},

		match_box_click_all(val) {
			for (let row of this.table_items) {
				// if we're unchecking all, set all selected_items to false
				if (!val) {
					this.$set(this.selected_items, row.identifier, false)
				// else...
				} else {
					// if it's already checked, leave it alone; if it wasn't checked...
					if (!this.selected_items[row.identifier]) {
						// else if already assoc, check it
						if (row.existing_assoc) {
							this.$set(this.selected_items, row.identifier, row.right_identifier)
						} else {
							// else set to candidate 0's identifier if we have candidates
							let msa = this.match_scores[0][row.identifier]
							if (msa && msa.candidates.length > 0) {
								this.$set(this.selected_items, row.identifier, msa.candidates[0].match_object.cfitem.identifier)
							}
						}
					}
				}
			}
			this.spot_checked_items = {}
			this.all_items_selected = val
		},

		find_existing_sme_association(item_identifier_a, item_identifier_b) {
			// look for an existing bidirectional association between item_identifier_a and item_identifier_b; we don't care here which is the destination and which is the origin
			// for this version we *don't* want ai-only assocs
			return this.crosswalk_framework_record.cfo.associations_hash[item_identifier_a]?.find(x=>(x.destinationNodeURI.identifier == item_identifier_b || x.originNodeURI.identifier == item_identifier_b) && x.associationType != 'ext:aiOnly')
		},

		select_branches(param) {
			let data = {}
			if (param.includes('left')) data.framework_identifier = this.framework_record_a.lsdoc_identifier
			else data.framework_identifier = this.framework_record_b.lsdoc_identifier

			let selected_items = this[param].concat([])
			if (this[param].length > 0) {
				data.selected_items = selected_items
				// for (let identifier of this[param]) data.selected_items.push(identifier)
			}

			let show_data = { 
				// fn called when embedded satchel is hidden
				// embed_hide_callback_fn: ()=>{ this.aligning_to_standards = false },

				// fn called continuously while embedded satchel is open; if it returns true, embedded satchel will be closed
				// hide_fn: ()=>{ return ($(vapp.course_unit_editor?.$el).is(':visible') == false) },
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// console.log(aligned_item)
						// if user previously chose this item, remove it
						let i = selected_items.findIndex(identifier=>identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							selected_items.splice(i, 1)

						} else {
							// else add it
							selected_items.push(aligned_item.cfitem.identifier)
						}
						this[param] = selected_items
						// re-initialize the chooser to show the updated choices
						this.select_branches(param)
					})
				})
			}).catch(()=>console.log('catch of vapp.$refs.satchel.execute(\'show\')'))	// this will execute when the standards are hidden
		},

		remove_branch(param, identifier) {
			let selected_items = this[param].concat([])
			let index = selected_items.findIndex(x=>x == identifier)
			selected_items.splice(index, 1)
			this[param] = selected_items
		},
		
		////////////////////////////////////////////
		find_cross_crosswalks() {
			// find any crosswalk frameworks that have alignments to both the left framework and the right framework
			let arr = []
			// for each framework_record...
			// console.warn('find_cross_crosswalks', this.a_identifier, this.b_identifier)
			for (let fr1 of this.$store.state.framework_records) {
				// if this is a crosswalk that includes the left framework...
				if (fr1.json.CFDocument.frameworkType == "crosswalk" && fr1.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.includes(this.a_identifier)) {
					// we may already have a crosswalk from a<->b; ignore this one
					if (fr1.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.includes(this.b_identifier)) continue

					// get the other framework for this crosswalk
					let csfis = fr1.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers
					let candidate_framework_identifier = (csfis[0] == this.a_identifier) ? csfis[1] : csfis[0]

					// see if we also have a crosswalk from candidate_framework_identifier to the right framework...
					let fr2 = this.$store.state.framework_records.find(x=>{
						if (!x.json.CFDocument.extensions?.crosswalkSourceFrameworkIdentifiers.includes(candidate_framework_identifier)) return false
						if (!x.json.CFDocument.extensions?.crosswalkSourceFrameworkIdentifiers.includes(this.b_identifier)) return false
						return true
					})
					// found one! push candidate_framework_identifier onto arr
					if (fr2) {
						// console.log('from a: ' + fr1.json.CFDocument.title)
						// console.log('  to b: ' + fr2.json.CFDocument.title)
						// console.log('c_identifier: ' + candidate_framework_identifier)
						arr.push({c_identifier: candidate_framework_identifier, crosswalk_fr_1: fr1, crosswalk_fr_2: fr2})
						// and load the crosswalks
						if (!fr1.cfo) this.load_lsdoc(fr1, 'crosswalk')
						if (!fr2.cfo) this.load_lsdoc(fr2, 'crosswalk')
					}
				}
			}
			// console.warn(`found ${arr.length} cross-crosswalk(s)`)
			this.cross_crosswalks = arr
		},

		////////////////////////////////////////////
		add_associations(level_param, options) {
			options ||= {}
			let level_counts = {exact: 0, nearexact: 0, close: 0, moderate: 0, nomatch: 0, none: 0}

			let data = {
				lsdoc_identifier: this.crosswalk_framework_record.lsdoc_identifier,
				CFAssociations: [],
			}

			// always save the crosswalk document, to save the most-recent settings used when creating associations,
			// and in case this is the first time we're saving the crosswalk framework
			let cfd = new CFDocument(this.crosswalk_framework_record.json.CFDocument)
			cfd.extensions.satchelSettings.crosswalk_match_params = JSON.stringify(this.match_params)
			this.$store.commit('set', [this.crosswalk_framework_record.json, 'CFDocument', cfd.to_json()])
			data.CFDocument = cfd.to_json_for_update()

			// for associating multiple items we use this.selected_items
			let selected_items = JSON.parse(JSON.stringify(this.selected_items))

			// for ai-only associations we use all items in the table...
			if (level_param == 'ai-only') {
				selected_items = {}
				for (let row of this.table_items) selected_items[row.identifier] = true
			}

			// for associating a single item...
			if (options.item) {
				this.selected_items[options.item.identifier || options.item.left_identifier] = undefined
				selected_items = {}
				if (level_param == 'nomatch') selected_items[options.item.identifier || options.item.left_identifier] = 'nomatch'
				else selected_items[options.item.identifier || options.item.left_identifier] = options.item.right_identifier
			}
			if (options.left_identifier && options.right_identifier) {
				this.selected_items[options.left_identifier] = undefined
				selected_items = {}
				selected_items[options.left_identifier] = options.right_identifier
			}

			// for every row item that's selected and not filtered out...
			for (let identifier in selected_items) {
				// store ai-only associations
				if (level_param == 'ai-only') {
					// here we store some of the match candidates in a custom-formatted-for-satchel association, as calculated in the table_items computed
					let origin_cfitem = this.framework_record_a.cfo.cfitems[identifier]
					
					// re-use existing assoc if found; otherwise create a new one
					let assoc = this.crosswalk_framework_record.cfo.ai_only_associations_hash[origin_cfitem.identifier]
					if (assoc) {
						assoc = new CFAssociation(assoc)
					} else {
						assoc = new CFAssociation({
							originNodeURI: {
								title: U.generate_cfassociation_node_uri_title(origin_cfitem, true) + sr(' (:$1:)', this.framework_record_a.lsdoc_identifier),
								identifier: origin_cfitem.identifier,
								uri: origin_cfitem.uri,
							},
							associationType: 'ext:satchelAIOnly',
							destinationNodeURI: {		// destination is framework_b document
								title: this.framework_record_b.json.CFDocument.title,
								identifier: this.framework_record_b.json.CFDocument.identifier,
								uri: this.framework_record_b.json.CFDocument.uri,
							},
						})
						assoc.complete_data(this.crosswalk_framework_record.json.CFDocument)
					}

					// delete old ratedMatchLevel if there -- TODO take this back out
					delete assoc.extensions.ratedMatchLevel

					// add calculated simscores array, then push to data.CFAssociations
					assoc.extensions.simscores = this.table_items_hash[identifier].match_score_object.ai_only_assoc_simscores
					data.CFAssociations.push(assoc)

				} else if (selected_items[identifier]) {
					// console.log(identifier)
					// console.log(this.table_items_hash[identifier])
					let right_identifier = selected_items[identifier]
					let origin_cfitem = this.framework_record_a.cfo.cfitems[identifier]
					// console.log(origin_cfitem)

					// get the cfitem of the to-be-associated item
					let dest_cfitem = this.framework_record_b.cfo.cfitems[right_identifier]

					// if we're not auto-associating or ai-only-associating, level_param should be specified directly as 'exact', 'nearexact', 'close', 'moderate', or 'nomatch'
					// console.log(level_param)
					let level = 'none'

					// base on match_score, along with auto-association thresholds, if we have them
					if (level_param == 'auto') {
						// try to get match_score -- this will work if the destination item is a "candidate"
						let candidate = this.table_items_hash[identifier].match_score_object.candidates.find(x=>x.match_object.cfitem.identifier == right_identifier)
						let match_score = (candidate) ? candidate.match_score : -1
						
						// if we're trying to base on match_score and the selected item doesn't have an match_score, skip it
						// note that this will automatically skip pre-existing associations
						if (empty(match_score) || match_score <= 0) continue

						if (match_score >= 100) level = 'exact'
						else if (match_score > this.match_params.close_relation_threshold) level = 'close'
						else if (match_score > this.match_params.moderate_relation_threshold) level = 'moderate'

						// if the item doesn't meet the moderate_relation_threshold, skip it
						else {
							++level_counts.none
							continue
						}
					
					// level was explicitly specified
					} else {
						level = level_param
					}

					// extract associationType from our constants
					let associationType = this.association_type_mappings[level].associationType
					++level_counts[level]

					let assoc

					// if an association between these items already exists...
					let existing_assoc = this.find_existing_sme_association(origin_cfitem.identifier, dest_cfitem?.identifier)
					if (existing_assoc) {
						// if the existing_assoc is identical to what we would add, no need to do anything
						if (existing_assoc.associationType == associationType) {
							// console.log("SAME ASSOCIATION!!!!")
							continue
						} else {
							// else set assoc to a duplicate of the existing_assoc, so that we reuse its guid, and fill in the new associationType
							assoc = new CFAssociation(existing_assoc)
							assoc.associationType = associationType
						}

					} else {
						let dnu
						if (level == 'nomatch') {
							dnu = {
								title: 'NO MATCH' + sr(' (:$1:)', this.framework_record_b.lsdoc_identifier),
								identifier: CFAssociation.nomatch_guid,
								uri: U.generate_child_uri(this.crosswalk_framework_record.json.CFDocument, CFAssociation.nomatch_guid, 'CFAssociations'),
							}
						} else {
							dnu = {
								title: U.generate_cfassociation_node_uri_title(dest_cfitem, true) + sr(' (:$1:)', this.framework_record_b.lsdoc_identifier),
								identifier: dest_cfitem.identifier,
								uri: dest_cfitem.uri,
							}
						}
						assoc = new CFAssociation({
							originNodeURI: {
								title: U.generate_cfassociation_node_uri_title(origin_cfitem, true) + sr(' (:$1:)', this.framework_record_a.lsdoc_identifier),
								identifier: origin_cfitem.identifier,
								uri: origin_cfitem.uri,
							},
							associationType: associationType,
							destinationNodeURI: dnu,
						})
						assoc.complete_data(this.crosswalk_framework_record.json.CFDocument)
					}

					// delete old ratedMatchLevel if there
					delete assoc.extensions.ratedMatchLevel

					// console.log(assoc)
					
					// not currently saving match_scores in the associations, because how the match_scores are calculated is too variable
					// assoc.extensions.autoMatchScore = match_score

					// add assoc to data to be saved
					data.CFAssociations.push(assoc)

					if (options.item) {
						// console.warn('setting assoc_icon')
						options.item.assoc_icon = this.assoc_icon(assoc)
						options.item.table_cell_center = ''
					}
				}
			}

			// console.log(data, level_counts)

			if (data.CFAssociations.length == 0) {
				this.$alert('None of the selected items would be associated.')
			} else if (options.item || (options.left_identifier && options.right_identifier)) {
				this.add_associations_finish(data, options, level_param)
				U.loading_stop()
			} else {
				let title, msg

				if (level_param == 'ai-only') {
					// calculate stats
					let sum_max_match = 0, sum_max_fs_sb_score = 0, sum_n = 0
					for (let assoc of data.CFAssociations) {
						if (assoc.extensions?.simscores == null) {
							console.warn('no simscores extensions: ', assoc.originNodeURI.title, assoc)
							continue
						}
						let arr = JSON.parse(assoc.extensions.simscores)
						let max_match = 0
						let max_fs_sb_score = 0
						for (let i = 0; i < arr.length; ++i) {
							if (arr[i][1] > max_match) max_match = arr[i][1]
							if (arr[i][2] > max_fs_sb_score) max_fs_sb_score = arr[i][2]
						}
						sum_max_match += max_match
						sum_max_fs_sb_score += max_fs_sb_score
						sum_n += arr.length
					}
					let avg_max_match = (sum_max_match / data.CFAssociations.length).toFixed(1)
					let avg_max_fs_sb_score = (sum_max_fs_sb_score / data.CFAssociations.length).toFixed(1)
					let avg_n = (sum_n / data.CFAssociations.length).toFixed(1)
					title = 'Confirm Auto Associations'
					msg = `This action will create <b>${data.CFAssociations.length} Auto ${U.ps('Association', data.CFAssociations.length)}</b>.<ul>`
					msg += `<li>Average maximum overall match score: <b>${avg_max_match}</b></li>`
					msg += `<li>Average maximum statement similarity score: <b>${avg_max_fs_sb_score}</b></li>`
					msg += `<li>Average number of candidate matches (min ${this.match_params.ai_only_min_n} – max ${this.match_params.ai_only_max_n}): <b>${avg_n}</b></li>`
					msg += `</ul>`

				} else {
					title = 'Confirm Batch Associations'
					msg = `This action will:<ul>`
					if (level_counts.exact > 0) msg += `<li>Create <b>${level_counts.exact} Exact Match</b> ${U.ps('association',level_counts.exact)}</li>`
					if (level_counts.nearexact > 0) msg += `<li>Create <b>${level_counts.nearexact} Near-Exact Match</b> ${U.ps('association',level_counts.nearexact)}</b></li>`
					if (level_counts.close > 0) msg += `<li>Create <b>${level_counts.close} Closely Related</b> ${U.ps('association',level_counts.close)}</b></li>`
					if (level_counts.moderate > 0) msg += `<li>Create <b>${level_counts.moderate} Moderately Related</b> ${U.ps('association',level_counts.moderate)}]</b></li>`
					if (level_counts.nomatch > 0) msg += `<li>Create <b>${level_counts.nomatch} “No Match”</b> ${U.ps('association',level_counts.nomatch)}</li>`	// I don't think this will be relevant, but there's no harm in it
					if (level_counts.none > 0) msg += `<li><b>${level_counts.none} ${U.ps('item',level_counts.none)} will not be associated</b>, since their match score is < ${this.match_params.moderate_relation_threshold}%</li>`
				}
				msg += '</ul>'
				this.$confirm({
					title: title,
					text: msg,
					acceptText: 'Save Associations',
					dialogMaxWidth: 640,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.add_associations_finish(data)
					// do this to hide any suggestions that are currently showing
					this.spot_check_candidates = null
					this.spot_check_row = null

				}).catch(n=>{console.log(n)}).finally(f=>{})
			}
		},

		add_associations_finish(data, options, level_param) {
			// clear selected items -- have to do this before we dispatch, because after we dispatch, what's in this.table_items will change
			this.match_box_click_all(false)

			this.$store.dispatch('save_framework_data', data).then(()=>{
				// add CFAssociations to json
				for (let cfa of data.CFAssociations) {
					// update lastChangeDateTime json before pushing or updating CFAssociation
					cfa.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime

					let index = this.crosswalk_framework_record.json.CFAssociations.findIndex(x=>x.identifier == cfa.identifier)
					if (index == -1) {
						this.$store.commit('set', [this.crosswalk_framework_record.json.CFAssociations, 'PUSH', cfa])
					} else {
						this.$store.commit('set', [this.crosswalk_framework_record.json.CFAssociations, 'SPLICE', index, cfa])
					}

					// also add to/update associations_hash or ai_only_associations_hash for the cfo
					if (cfa.associationType == 'ext:satchelAIOnly') {
						this.$store.commit('set', [this.crosswalk_framework_record.cfo.ai_only_associations_hash, cfa.originNodeURI.identifier, cfa])
					 } else {
						U.update_associations_hash(this.crosswalk_framework_record.cfo, cfa)				
					 }
				}

				// set lastChangeDateTime for CFDocument
				this.$store.commit('set', [this.crosswalk_framework_record.json.CFDocument, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])

				// if we didn't already check the crosswalk framework out for editing (because it just got created), do so now
				if (!this.crosswalk_framework_checked_out) this.check_out_crosswalk_framework_for_editing()

				console.log(`${data.CFAssociations.length} associations saved`, data)

				// if the user chose a match for a particular item using the drop-down in the middle of the table...
				if (options?.item) {
					// if we just made a "nomatch" assoc, make sure suggestions aren't showing for the item
					if (level_param == 'nomatch') this.toggle_suggestions_for_item(options.item, false)
					// (otherwise we leave suggestions showing, in case they want to make another association for this item)

				} else {
					// refresh spot_check_row in case it housed deleted associations -- but don't scroll or change what's showing on the right side (that's the final 'false' param)
					// TODO: this used to not be in this else, but that was causing all suggestions to be shown sometimes when we don't want it.  I think it's OK here inside the else, but I could be wrong...
					if (this.spot_check_row) this.show_spot_check(this.spot_check_row, null, false)
				}

				this.update_associations_to_show_for_crosswalk()

			}).catch((e)=>{
				console.log(e)
				// in case of failure...
				this.$alert('Error saving associations')
			})
		},

		ai_assoc_btn_clicked(evt) {
			console.log(evt)
			if (evt.shiftKey) {
				this.delete_associations('ai-only')
			} else {
				// if nothing would be change, say so
				if (this.ai_only_matches_unchanged) {
					this.$alert('The currently-specified items’ saved auto-associations already reflect the currently-calculated auto-associations.')
					return
				}
				this.add_associations('ai-only')
			}
		},

		delete_associations(param, confirmed) {
			console.log(param)
			let payload = {
				framework_record: this.crosswalk_framework_record, 
				associations_to_delete: []
			}

			if (param == 'ai-only') {
				// delete all ai associations
				// add all auto-assocs
				for (let assoc of this.crosswalk_framework_record.json.CFAssociations) {
					if (assoc.associationType == 'ext:satchelAIOnly') {
						payload.associations_to_delete.push(assoc)
					}
				}

				if (payload.associations_to_delete.length == 0) {
					this.$alert('There are no auto-associations to delete.')
					return
				}

				if (confirmed !== true) {
					this.$confirm({
						title: 'Are you sure?',
						text: `Are you sure you want to delete all ${payload.associations_to_delete.length} auto-associations?`,
						acceptText: 'Delete All',
						acceptColor: 'red darken-3',
					}).then(y => {
						this.delete_associations('ai-only', true)
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}

				// this is going to clear out the ai_only_associations_hash
				this.$store.commit('set', [this.crosswalk_framework_record.cfo, 'ai_only_associations_hash', {}])

			} else if (param) {
				// console.log(param)
				// if passed an item as a param, just delete the param
				let assocs = this.crosswalk_framework_record.cfo.associations_hash[param.identifier || param.left_identifier]
				if (assocs) { // if the item has associations
					// delete the association with matching the item selected
					for (let assoc of assocs) {
						if (assoc.destinationNodeURI.identifier == param.right_identifier || assoc.originNodeURI.identifier == param.right_identifier) {
							payload.associations_to_delete.push(assoc)
						}
					}
				}
			} else {
				// for every row item that's selected and not filtered out...
				for (let identifier in this.table_items_hash) {
					if (this.selected_items[identifier]) {
						let assocs = this.crosswalk_framework_record.cfo.associations_hash[identifier]
						if (assocs) { // if the item has associations
							// delete the association matching the item selected
							for (let assoc of assocs) {
								// we found the assoc in this identifier's associations_hash entry; the currently-associated identifier could be in destinationNodeURI or originNodeURI
								if (assoc.destinationNodeURI.identifier == this.selected_items[identifier] || assoc.originNodeURI.identifier == this.selected_items[identifier]) {
									payload.associations_to_delete.push(assoc)
									// we shouldn't need to do the below; delete_associations will do it
									// U.remove_from_associations_hash(this.crosswalk_framework_record.cfo, assoc)
								}
							}
						}
					}
				}
			}

			// clear selected items -- have to do this before we dispatch
			this.match_box_click_all(false)
			// also do this to hide any suggestions that are currently showing
			this.spot_check_candidates = null
			this.spot_check_row = null

			this.$store.dispatch('delete_associations', payload).then(() => {
				this.update_associations_to_show_for_crosswalk()
			})
			
			// refresh spot_check_row in case it housed deleted associations
			if (this.spot_check_row) this.show_spot_check(this.spot_check_row, undefined, false)
		},

		// called when user clicks on the +/- button for an item in the table
		toggle_suggestions_for_item(item, new_state) {
			if (typeof(new_state) != 'boolean') {
				// if we're already showing this item's suggestions, hide them
				new_state = !(this.spot_check_candidates && this.spot_check_candidates[0] && this.spot_check_candidates[0].left_identifier == item.cfitem.identifier)
			}

			if (new_state == false) { 
				this.spot_check_candidates = null; 
				this.spot_check_row = null; 
			} else {
				this.show_spot_check(item, 5);
			}
		},

		// TODO: allow param to be an identifier; if that's what it is, then if we're not showing that identifier's row, set spot_check_candidates, but set spot_check_row to null
		show_spot_check(param, spot_check_candidates_to_show, scroll_to) {
			spot_check_candidates_to_show ||= this.spot_check_candidates_to_show
			let spot_check_row
			if (param?.item_type) {
				spot_check_row = param

			} else {
				// select an item to spot check...
				// make a list of options -- selected items that we haven't spot checked in this "round"
				let options = []
				let previous_spot_checked_identifier
				for (let identifier in this.table_items_hash) {
					if (this.selected_items[identifier]) {
						if (!this.spot_checked_items[identifier]) {
							options.push(identifier)
						} else if (identifier != this.spot_check_row?.identifier) {
							previous_spot_checked_identifier = identifier
						}
					}
				}

				if (param == 'prev') {
					this.spot_checked_items[this.spot_check_row.identifier] = false
					if (previous_spot_checked_identifier) {
						spot_check_row = this.table_items_hash[previous_spot_checked_identifier]
					} else {
						this.$alert('No previous item to go to.')
						return
					}
				}

				if (!spot_check_row) {
					// if we didn't find any options, we must need to reset spot_checked_items
					if (options.length == 0) {
						// if spot_checked_items doesn't have any items, something went wrong...
						if (!U.object_has_keys(this.spot_checked_items)) {
							this.$alert('An error occurred when trying to spot-check items.')
							return
						}
						this.spot_checked_items = {}

						this.$confirm({
							text: 'You’ve spot-checked all currently-selected items. Do you want to go through them again?',
							acceptText: 'No',
							cancelText: 'Yes',
							// dialogMaxWidth: 800,
							// focusBtn: true,		// focus on the accept btn when dialog is rendered
						}).then(y => {
							this.spot_check_row = null

						}).catch(n=>{
							// re-call spot_check to try again
							this.show_spot_check()
							
						}).finally(f=>{})
						return
					}

					let spot_check_identifier
					// choose a random option or the first option
					if (param == 'random') {
						spot_check_identifier = options[U.random_int(options.length)]
					} else {
						spot_check_identifier = options[0]
					}

					spot_check_row = this.table_items_hash[spot_check_identifier]
				}
			}

			// switch to the page the spot check item row is on
			this.current_page = Math.max(Math.floor(this.table_items.indexOf(spot_check_row) / this.items_per_page) + 1, 1)
			// console.log(this.current_page)
			
			this.spot_check_candidates = []
			let msa = this.match_scores[0][spot_check_row.cfitem.identifier]
			if (!msa || msa.candidates.length == 0) {
				// decide what to do here
			} else {
				// get difference strings for candidates in this row
				for (let i = 0; i < spot_check_candidates_to_show; ++i) {
					let candidate = msa.candidates[i]
					if (candidate == undefined) continue
					let o = {
						candidates_index: i,
						candidate: candidate,
						match_sort_value: candidate.match_score,
						hovering: false,
					}

					// do the same thing here we do in table_cells_html, for each candidate
					let msb = candidate.match_object
					// add normalized_hcs to both sides if we have them
					let table_cell_left = this.item_html(msa.cfitem)
					if (this.show_transformed_hcs_values && msa && msa.normalized_hcs) table_cell_left += ` [${msa.normalized_hcs}]`					
					let table_cell_right = this.item_html(msb.cfitem)
					if (this.show_transformed_hcs_values && msb && msb.normalized_hcs) table_cell_right += ` [${msb.normalized_hcs}]`
					o.left_identifier = msa.cfitem.identifier
					o.right_identifier = msb.cfitem.identifier

					if (this.show_diff_coloring) {
						let ds = U.diff_string(table_cell_left, table_cell_right)
						o.diffs = {left:ds.o, right:ds.n}
					} else {
						// don't use diff_string if show_diff_coloring is off
						o.diffs = {left: U.marked_latex(table_cell_left), right: U.marked_latex(table_cell_right)}
					}

					o.grades = {left: U.grade_level_display(msa.cfitem.educationLevel), right: U.grade_level_display(msb.cfitem.educationLevel)}
					o.item_types = {left: msa.item_type, right: msb.item_type}

					// if there is an exising association, add assoc_icon to the candidate
					// console.log(msa, msb)
					let existing_assoc = this.crosswalk_framework_record.cfo.associations_hash[msa.cfitem.identifier] && this.crosswalk_framework_record.cfo.associations_hash[msa.cfitem.identifier].find(x=>x.destinationNodeURI.identifier==msb.cfitem.identifier)
					if (existing_assoc) {
						// console.log("EXISTING ASSOCIATION", existing_assoc)
						o.assoc_icon = this.assoc_icon(existing_assoc)
					}

					o.table_cell_center = !existing_assoc ? `<nobr>${this.format_match_value(o.match_sort_value, 1)}</nobr>` : ""


					this.spot_check_candidates.push(o)
				}
			}

			// show the item by setting this.spot_check_row
			this.spot_check_row = spot_check_row

			// if this item is selected, make note that we've spot-checked the item
			this.spot_check_index = -1
			let i = 0
			for (let identifier in this.table_items_hash) {
				++i
				if (this.selected_items[identifier] && identifier == spot_check_row.identifier) {
					// note its index, and set spot_checked_items to true for this identifier
					this.spot_check_index = i
					this.$set(this.spot_checked_items, spot_check_row.identifier, true)
					break
				}
			}
			// console.log(this.spot_check_candidates)

			if (scroll_to || scroll_to === undefined) {
				if (!this.tree_mode) {
					window.setTimeout(()=>{
						if (this.$refs.main_container) this.$refs.main_container.scrollTo({top: this.$refs['table_item_' + spot_check_row.identifier][0].offsetTop - 105, behavior: "smooth"})
					}, 0)
				}
				if (this.spot_check_candidates?.length > 0) {
					this.show_left_item_in_tree(spot_check_row)
					this.show_right_item_in_tree(this.spot_check_candidates[0].right_identifier)
				}
			}
		},
		show_last_spot_check() {
			let last_item
			if (!this.tree_left_chosen_node) {
				last_item = this.table_items[0]
			}
			else {
				let chosen_item_key = this.tree_left_chosen_node.tree_key
				// find the last item that is in the table
				for (let i = (this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier] ? this.table_items.indexOf(this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier]) - 1 : 0); i >= 0; i--) {
					let item = this.table_items[i]
					if (item.cfitem.tree_nodes[0].tree_key < chosen_item_key) {
						last_item = item
						break
					}
				}
				// if no last_item is found (presumably because we are at the start of the table), set to the last item
				if (last_item == undefined) last_item = this.table_items[this.table_items.length-1]
			}


			this.show_left_item_in_tree(last_item)
			this.show_spot_check(last_item)
		},
		show_next_spot_check() {
			let next_item
			if (!this.tree_left_chosen_node) {
				next_item = this.table_items[0]
			}
			else {
				let chosen_item_key = this.tree_left_chosen_node.tree_key
				// find the next item that is in the table
				for (let i = (this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier] ? this.table_items.indexOf(this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier]) + 1 : 0); i < this.table_items.length; i++) {
					let item = this.table_items[i]
					if (item.cfitem.tree_nodes[0].tree_key > chosen_item_key) {
						next_item = item
						break
					}
				}
				// if no next_item is found (presumably because we are at the end of the table), set to the first item
				if (next_item == undefined) next_item = this.table_items[0]				
			}


			this.show_left_item_in_tree(next_item)
			this.show_spot_check(next_item)
		},

		toggle_tree_suggestions(val) {
			if (typeof(val) != 'boolean') val = !this.tree_suggestions_showing
			this.tree_suggestions_showing = val
		},

		table_cells_html(row) {
			let o = {}
			let match_sort_value = -1
			// for now we assume one assoc per item
			if (row.existing_assoc) {
				let right_item = this.framework_record_b.cfo.cfitems[row.right_identifier]
				o.right = this.item_html(right_item)

			} else {
				let msa = this.match_scores[0][row.identifier]
				let candidate

				// for now we're assuming the selected item is always a candidate...
				if (this.selected_items[row.identifier]) {
					candidate = msa.candidates.find(x=>x.match_object.cfitem.identifier == this.selected_items[row.identifier])
				} else {
					// else we assume candidate 0
					if (!msa || msa.candidates.length == 0) return ''
					candidate = msa.candidates[0]
				}

				match_sort_value = (!candidate) ? -1 : candidate.match_score

				let msb = candidate.match_object
				o.right = this.item_html(msb.cfitem)
				// add normalized_hcs if we have it
				if (this.show_transformed_hcs_values && msb && msb.cfitem.humanCodingScheme) o.right += ` [${msb.normalized_hcs}]`
			}

			o.center = (match_sort_value == -1) ? '' : `<nobr>${this.format_match_value(match_sort_value, 0)}</nobr>`
			return o
		},

		get_match_level(param) {
			let match_level = -1
			// if param is a number, it's a match_value
			if (typeof(param) == 'number') {
				let last_mlo_value = 1000
				for (let i = 0; i < this.match_level_options.length; ++i) {
					let mlo = this.match_level_options[i]
					if (param < last_mlo_value && param >= mlo.value*1) {
						match_level = i
						break
					}
					last_mlo_value = mlo.value*1
				}
				if (match_level == -1) match_level = 6
			} else {
				// else it's an existing association
				if (param.associationType == 'exactMatchOf') match_level = 0
				else if (param.associationType == 'ext:isNearExactMatch') match_level = 1
				else if (param.associationType == 'ext:isCloselyRelatedTo') match_level = 2
				else if (param.associationType == 'ext:isModeratelyRelatedTo') match_level = 3
				else if (param.associationType == 'isRelatedTo') match_level = 3
				// use match_level 5 for no match
				else if (param.associationType == 'ext:hasNoMatch') match_level = 5
			}
			return match_level
		},

		format_match_value(match_sort_value, places) {
			let s = ''
			if (match_sort_value == 100) {
				s += '100'
				// s += '100%<span style="visibility:hidden">.</span>'
			} else {
				if (match_sort_value < 10) s += '<span style="visibility:hidden">0</span>'
				s += match_sort_value.toFixed(places)
				// s += match_sort_value.toFixed(1) + '%'
			}
			return s
		},

		////////////////////////////////////////////
		create_match_score_collection(framework_record, hcs_regex_params, item_type_params, to_or_from, include_branches, exclude_branches, filter_ed_levels) {
			// console.log(item_type_params)
			let add_item = (node, level) => {
				let cfitem = node.cfitem

				// if we have exclude branches for the this side, skip the item *and its children*
				if (exclude_branches.includes(cfitem.identifier)) {
					return
				}

				// if the cfitem doesn't meet the to-be-aligned criteria, skip it, but check children
				let include = true

				// item types
				let item_type = U.item_type_string(cfitem)
				if (empty(item_type)) item_type = '[no item type]'
				let include_from_item_type = false
				// for 'from', go based on alignable
				if (to_or_from == 'from') {
					if (!item_type_params[item_type]) console.error('warning: ' + item_type)
					else include_from_item_type = item_type_params[item_type].alignable
				} else {
					// for 'to', check 'align_to' values of alignable types
					for (let key in item_type_params) {
						if (item_type_params[key]?.alignable) {
							if (item_type_params[key].align_to.includes(item_type)) {
								include_from_item_type = true
								break
							}
						}
					}
				}
				if (!include_from_item_type) {
					include = false
				}

				// if we're limiting by ed level, skip items that don't match
				if (include && filter_ed_levels && this.match_params.ed_levels.length > 0 && this.match_params.ed_levels[0] != '') {
					let found_ed_match = false
					for (let el of this.match_params.ed_levels) {
						if (cfitem.educationLevel.includes(el)) found_ed_match = true
					}
					if (!found_ed_match) include = false
				}

				if (include) {
					// humanCodingScheme: apply regexpes
					let hcs = cfitem.humanCodingScheme
					if (hcs) {
						for (let i = 0; i < hcs_regexes.length; ++i) {
							hcs = hcs.replace(hcs_regexes[i], hcs_regex_params[i].replace)
						}
					}

					// if we're including, add to ms
					ms[cfitem.identifier] = {
						cfitem: cfitem,
						item_type: item_type,
						normalized_hcs: hcs,
						candidates: [],
						best_match_score: 0,
						top_candidate_count: 0,
						match_sort_value: this.match_sort_value, // this will be overriden by save data if no new items
						processed: false,
					}
				}

				// process the item's children
				for (let child_node of node.children) {
					add_item(child_node, level + 1)
				}
			}
			/////////////// end of add_item fn

			// set up HCS regexes
			let hcs_regexes = []
			for (let re of hcs_regex_params) {
				if (re.search == '') break
				hcs_regexes.push(new RegExp(re.search, 'gi'))
			}

			let ms = {}
			// if we don't have any 'limit to' items on the left, process each top-level item in the tree
			if (include_branches.length == 0) {
				for (let child_node of framework_record.cfo.cftree.children) {
					add_item(child_node, 0)
				}
			} else {
				// else only include included branches
				for (let identifier of include_branches) {
					if (framework_record.cfo.cfitems[identifier]) {
						let node = framework_record.cfo.cfitems[identifier].tree_nodes[0]
						add_item(node, 0)
					}
				}
			}

			return ms
		},

		ed_level_diff(a, b) {
			// if either a or b has no ed level specified, return either 0 if the other also has no ed specified, or 1000 (a very high value) otherwise
			if (empty(a) || a.length == 0) {
				return (empty(b) || b.length == 0) ? 0 : 1000
			}
			if (empty(b) || b.length == 0) {
				return (empty(a) || a.length == 0) ? 0 : 1000
			}

			// if we get to here, both a and b should be arrays with at least one item

			// if the a array includes either b.first or b.last, difference is 0
			if (a.includes(b[0]) || a.includes(b[b.length-1])) return 0

			// likewise, if the b array includes either a.first or a.last, difference is 0
			if (b.includes(a[0]) || b.includes(a[a.length-1])) return 0

			// from here on, if our store.grades array doesn't include the a or b value, assume no overlap

			// if we get to here, there's no exact overlap. so look for the closest a value to b.first and b.last
			let bg_lo = this.grades.find(x=>x.value == b[0])
			let bg_hi = this.grades.find(x=>x.value == b[b.length-1])
			if (!bg_lo || !bg_hi) return 1000

			let min_val = 1000
			for (let aval of a) {
				let ag = this.grades.find(x=>x.value == aval)
				if (!ag) return 1000

				let diff = Math.abs(ag.index - bg_lo.index)
				if (diff < min_val) min_val = diff
				diff = Math.abs(ag.index - bg_hi.index)
				if (diff < min_val) min_val = diff
			}

			return min_val
		},

		compute_all_match_scores() {
			// if match_params and filters haven't changed since the last time we computed, return
			let match_params_string = JSON.stringify(this.match_params)
			let settings_string = JSON.stringify([this.include_branches_left, this.include_branches_right, this.exclude_branches_left, this.exclude_branches_right, this.make_alignments_for_types])
			if (match_params_string == this.computed_match_params && settings_string == this.computed_settings) return
			// then save the computed_match_params and computed_settings we're processing
			this.computed_match_params = match_params_string
			this.computed_settings = settings_string

			// clear spot check stuff and selected_items
			this.spot_check_candidates = null
			this.spot_check_row = null
			this.spot_checked_items = {}
			this.selected_items = {}

			// get list of items to be matched on the left
			let msc_a = this.create_match_score_collection(this.framework_record_a, this.hcs_regex_params_a, this.item_type_params, 'from', this.include_branches_left, this.exclude_branches_left, true)
			this.match_scores.splice(0, 1, msc_a)

			// // take out any selected items that are not going to be selectable anymore
			// for (let id in this.selected_items) {
			// 	if (!msc_a[id]) this.selected_items[id] = undefined
			// }

			// get list of items to be matched on the right
			let msc_b = this.create_match_score_collection(this.framework_record_b, this.hcs_regex_params_b, this.item_type_params, 'to', this.include_branches_right, this.exclude_branches_right, true)
			this.match_scores.splice(1, 1, msc_b)

			// set scores_loading to true, and start showing the table
			this.scores_loading = true
			this.table_showing = true

			// then start processing scores; we work in batches so that the user can start interacting right away
			this.total_matches_to_process = Object.keys(msc_a).length
			this.matches_processed_so_far = 0
			this.compute_progress = 0
			setTimeout(x=>this.compute_match_scores_worker(), 0)
		},

		compute_match_scores_worker() {
			// Calculate match scores one "block" at a time
			return new Promise((resolve, reject)=>{
				let msc_a = this.match_scores[0]
				let msc_b = this.match_scores[1]
				let matches_processed_this_block = 0

				let matches_to_process = 5
				
				// for each item on the left...
				for (let ida in msc_a) {
					let msa = msc_a[ida]

					// skip through already-processed items
					if (msa.processed) continue

					// get list of alignable item types on the right, if we have one
					let item_type_a = U.item_type_string(msa.cfitem)
					if (empty(item_type_a)) item_type_a = '[no item type]'
					let item_type_params = this.item_type_params[item_type_a]
					if (!item_type_params || !item_type_params.alignable) {
						console.error('bad item type params?', item_type_a, this.item_type_params)
						item_type_params = null
					}
					if (item_type_params?.align_to.length == 0) item_type_params = null

					// --------------- for each item on the right...
					for (let idb in msc_b) {
						let msb = msc_b[idb]
						let match_score

						// if this right item isn't an item type match for this left item, continue
						let item_type_b = U.item_type_string(msb.cfitem)
						if (item_type_params && !item_type_params.align_to.includes(item_type_b)) continue

						// if education level tolerance criterion isn't satisfied, continue
						if (this.match_params.ed_level_match_tolerance > -1) {
							if (this.ed_level_diff(msa.cfitem.educationLevel, msb.cfitem.educationLevel) > this.match_params.ed_level_match_tolerance) continue
						}

						// get sim_score for fullStatement, based on SB, string_similarity, or both
						let fs_match = this.fs_match(ida, idb, msa.cfitem.fullStatement, msb.cfitem.fullStatement)

						// include factors as specified by weights
						let numerator = 0, denominator = 0
						if (this.match_params.full_statement_weight > 0) {
							numerator += this.match_params.full_statement_weight * fs_match.composite
							denominator += this.match_params.full_statement_weight
							// console.warn('fs_match: ', sb_score, manual_score, fs_match)
						}

						// include hcs match if directed to
						if (this.match_params.hcs_weight > 0) {
							let hcs_match = this.hcs_match(msa.normalized_hcs, msb.normalized_hcs, this.match_params)
							// adjust the hcs_match so that exact matches are weighted more heavily than partial matches. I.e. 1 will always be 1, but if hcs_power_adjustment is 5, 0.875 becomes 0.586
							hcs_match = Math.pow(hcs_match, this.match_params.hcs_power_adjustment)
							hcs_match = hcs_match * 100

							numerator += this.match_params.hcs_weight * hcs_match
							denominator += this.match_params.hcs_weight
						}

						// include parent match if directed to
						if (this.match_params.parent_weight > 0) {
							let parent_match = this.parent_match(msa.cfitem.tree_nodes[0]?.parent_node, msb.cfitem.tree_nodes[0]?.parent_node, 1)

							numerator += this.match_params.parent_weight * parent_match
							denominator += this.match_params.parent_weight
						}

						// include cross-crosswalks if specified:
						// If we're aligning A->B and we have crosswalks from A->C and C->B, use that data
						// e.g. for Carnegie Learning, A = Evidence Statements, C = Common Core, B = North Carolina
						if (this.match_params.cross_crosswalk_weight > 0 && this.cross_crosswalks.length > 0) {
							for (let cc of this.cross_crosswalks) {
								let cc_match = this.cross_crosswalk_match(cc, msa.cfitem.identifier, msb.cfitem.identifier)
								numerator += this.match_params.cross_crosswalk_weight * cc_match
								denominator += this.match_params.cross_crosswalk_weight
							}
						}

						// calculate match score and round to nearest tenth
						match_score = Math.round((numerator / denominator) * 10) / 10

						// push to candidates, if score is > 0
						if (match_score > 0) msa.candidates.push({
							match_object: msb,
							match_score: match_score,
							// we store the "raw" sb_score in ai-only associations
							fs_sb_score: Math.round(fs_match.sb_score),
						})
					}
					// ------------------ done processing right items for this left item

					// sort candidates by match_score
					msa.candidates.sort((a,b)=>b.match_score - a.match_score)

					if (msa.candidates.length > 0) msa.best_match_score = msa.candidates[0].match_score

					// get number of candidates to show in the middle column
					if (msa.candidates.length > 0) {
						msa.top_candidate_count = 1
						let top_candidate_ms = msa.candidates[0].match_score
						for (let i = 1; i < msa.candidates.length; ++i) {
							if (top_candidate_ms == 100) {
								if (msa.candidates[i].match_score == 100) ++msa.top_candidate_count
								else break
							} else {
								if (top_candidate_ms - msa.candidates[i].match_score <= 5) ++msa.top_candidate_count
								else break
							}
						}
					}

					msa.processed = true
					++this.matches_processed_so_far
					++matches_processed_this_block
					if (matches_processed_this_block >= matches_to_process) break
				}

				this.compute_progress = Math.round(this.matches_processed_so_far / this.total_matches_to_process * 100)

				// if we're not done processing all matches, re-call the worker
				if (this.matches_processed_so_far < this.total_matches_to_process) {
					// console.log(`computing next block; ${this.matches_processed_so_far}/${this.total_matches_to_process} complete`)
					setTimeout(x=>this.compute_match_scores_worker(), 10)
				} else {
					// console.log('done')
					// else finish up
					this.scores_loading = false
					if (this.tree_mode && this.tree_left_chosen_node && this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier]) this.show_spot_check(this.table_items_hash[this.tree_left_chosen_node.cfitem.identifier])
				}
			})
		},

		get_normalized_fs(identifier, fullStatement) {
			if (!this.normalized_fullStatements[identifier]) {
				this.normalized_fullStatements[identifier] = U.normalize_string_for_sparkl_bot(fullStatement)
			}
			return this.normalized_fullStatements[identifier]
		},

		fs_match(ida, idb, fullStatement_a, fullStatement_b) {
			// get sim_score for fullStatement, based on SB, string_similarity, or both
			let sparkl_bot_weight = this.match_params.sparkl_bot_weight / 100

			let sb_score = 0, manual_score = 0
			if (sparkl_bot_weight > 0) {
				if (!this.framework_record_a.sparkl_bot_vectors[ida]) {
					console.warn('no sb vector a!')
					return 0
				}
				if (!this.framework_record_b.sparkl_bot_vectors[idb]) {
					console.warn('no sb vector b')
					return 0
				}
				sb_score = U.cosine_similarity(this.framework_record_a.sparkl_bot_vectors[ida], this.framework_record_b.sparkl_bot_vectors[idb]) * 100
			}
			if (sparkl_bot_weight < 1) {
				let a_normalized_fs = this.get_normalized_fs(ida, fullStatement_a)
				let b_normalized_fs = this.get_normalized_fs(idb, fullStatement_b)
				manual_score = U.string_similarity_words(a_normalized_fs, b_normalized_fs) * 100
			}

			return {
				composite: (sb_score * sparkl_bot_weight) + (manual_score * (1-sparkl_bot_weight)),
				// we should almost always be using sparkl-bot, but if not for some reason, just use the manual_score for sb_score
				sb_score: (sparkl_bot_weight > 0) ? sb_score : manual_score,
			}
		},

		match_score_from_assoc_type(assoc) {
			// don't consider AI assocs here
			if (assoc.associationType == 'ext:satchelAIOnly') return 0

			if (assoc.associationType == 'exactMatchOf') return 1
			else if (assoc.associationType == 'isRelatedTo') return 0.80

			else if (assoc.associationType == 'ext:isNearExactMatch') return 0.95
			else if (assoc.associationType == 'ext:isCloselyRelatedTo') return 0.85
			else if (assoc.associationType == 'ext:isModeratelyRelatedTo') return 0.70

			// any other type of association: 0.5
			return 0.5
		},

		cross_crosswalk_match(cco, item_a_identifier, item_b_identifier) {
			// cco format: {c_identifier: candidate_framework_identifier, crosswalk_fr_1: fr1, crosswalk_fr_2: fr2})

			// find any item from framework c that are associated with the item from framework a (there could in theory be more than one such assoc)
			let best_match_ab = 0, n_ab = 0
			for (let assoc of cco.crosswalk_fr_1.json.CFAssociations) {
				let c_candidate = null
				if (assoc.originNodeURI.identifier == item_a_identifier) c_candidate = assoc.destinationNodeURI.identifier
				else if (assoc.destinationNodeURI.identifier == item_a_identifier) c_candidate = assoc.originNodeURI.identifier
				if (!c_candidate) continue

				// see if c_candidate is associated with the item from framework b (there could in theory be more than one such assoc)
				let best_match_cb = 0
				for (let assoc2 of cco.crosswalk_fr_2.json.CFAssociations) {
					if (assoc2.originNodeURI.identifier == c_candidate || assoc2.destinationNodeURI.identifier == c_candidate) {
						// if we find an AI assoc...
						if (assoc2.associationType == 'ext:satchelAIOnly') {
							// look for item b in the list of simscores
							if (assoc2.extensions.simscores.includes(item_b_identifier)) {
								let arr = JSON.parse(assoc2.extensions.simscores)
								let a2 = arr.find(x=>x[0] == item_b_identifier)
								let match_cb = a2[1] / 100
								// console.warn(`ext:satchelAIOnly: ${item_a_identifier} => ${c_candidate} => ${item_b_identifier}: ${match_cb}`)
								if (match_cb > best_match_cb) best_match_cb = match_cb
							}
							continue
						}

						// else if it's an SME assoc, calculate match score
						if (assoc2.originNodeURI.identifier == item_b_identifier || assoc2.destinationNodeURI.identifier == item_b_identifier) {
							let match_cb = this.match_score_from_assoc_type(assoc2)
							if (match_cb > best_match_cb) best_match_cb = match_cb
						}
					}
				}

				// if no cb match, move on
				if (best_match_cb == 0) continue

				// we found a cb match, so calculate the ac match, then combine them to make the overall match
				let match_ac = this.match_score_from_assoc_type(assoc)
				let match_ab = best_match_cb * match_ac
				// console.warn(`${item_a_identifier} => ${c_candidate} => ${item_b_identifier}: best_match_cb=${best_match_cb} * match_ac=${match_ac} = ${match_ab}`)
				
				if (match_ab > best_match_ab) best_match_ab = match_ab
				++n_ab
			}

			// return the best ab match
			return best_match_ab * 100
		},

		parent_match(a_parent_node, b_parent_node, up_level) {
			// if either side doesn't have a parent, or the parent doesn't have a fullStatement, match is 0
			if (!a_parent_node || !b_parent_node) return 0
			if (!a_parent_node.cfitem?.fullStatement || !a_parent_node.cfitem?.fullStatement) return 0

			// check to see if parents are explicitly related
			let assocs = this.crosswalk_framework_record.cfo.associations_hash[a_parent_node.cfitem.identifier]
			if (assocs) {
				let assoc = assocs.find(x=>x.destinationNodeURI.identifier == b_parent_node.cfitem.identifier || x.originNodeURI.identifier == b_parent_node.cfitem.identifier)
				if (assoc) {
					// if we find an association, use the following values
					if (assoc.associationType == 'exactMatchOf') return 100
					if (assoc.associationType == 'ext:isNearExactMatch') return 95
					if (assoc.associationType == 'ext:isCloselyRelatedTo') return 85
					if (assoc.associationType == 'ext:isModeratelyRelatedTo') return 70
					if (assoc.associationType == 'isRelatedTo') return 70
					return 60
				}
			}

			// else check sparkl-bot and/or manual match for parents' fullStatements
			return this.fs_match(a_parent_node.cfitem.identifier, b_parent_node.cfitem.identifier, a_parent_node.cfitem.fullStatement, b_parent_node.cfitem.fullStatement).composite
		},

		hcs_match(hcsa, hcsb, match_params) {
			// if left node doesn't have a hcs, match is 100 if the right side also doesn't have an hcs, or 0 otherwise
			if (!hcsa) {
				return (hcsb) ? 0 : 1
			
			// else left node has an hcs...
			} else {
				// so if right node doesn't have one, match is 0
				if (!hcsb) {
					return 0

				} else {
					function split_to_segs(hcs) {
						let a1 = hcs.split(/\b/)
						// convert numbers, K/PK, and single lower-case letters to numeric values
						for (let i = 0; i < a1.length; ++i) {
							let s = a1[i]
							if (is_numeric(s)) a1[i] = s * 1
							else if (s == 'K') a1[i] = 0
							else if (s == 'PK') a1[i] = -1
							else if (s.length == 1 && s >= 'a' && s <= 'z') a1[i] = s.charCodeAt(0) - 96 	// 'a' == 1
						}

						let arr = []
						for (let i = 0; i < a1.length; ++i) {
							let s = a1[i]

							// combine numbers separated by dashes into the average of the two numbers
							if ((s == '-' || s == '–') && arr.length > 0 && a1.length > i+1 && is_numeric(arr[arr.length-1]) && is_numeric(a1[i+1])) {
								arr[arr.length-1] = ((arr[arr.length-1]*1 + a1[i+1]*1) / 2) + ''
								++i
							} else if ([' ', '.', '-', '–', ':'].includes(s)) {
								continue
							} else {
								arr.push(s+'')
							}
						}
						return arr
					}

					// split codes into segments, then for each segment...
					let segs_a = split_to_segs(hcsa)
					let segs_b = split_to_segs(hcsb)
					let num = 0
					let den = 0
					for (let i = 0; i < segs_a.length || i < segs_b.length; ++i) {
						den += 1
						// if either segment is empty, the other must be filled, so num+=0
						if (!segs_a[i] || !segs_b[i]) continue	

						let ln = segs_a[i]
						let rn = segs_b[i]

						// if segments are exactly the same...
						if (ln == rn) {
							// allow for weighting numeric, or single lower-case letter, matches differently than non-numeric matches
							// note that we need to arrange things so that this value is greater than the maximum num addend for a number that differs by 1, below
							if (is_numeric(ln) || ln.search(/^[a-z]/) > -1) num += match_params.hcs_numeric_equivalent_val
							else num += match_params.hcs_nonnumeric_equivalent_val

						} else {
							// if both segments are numbers or single a-z characters (which would have been converted above), 
							if (is_numeric(ln) && is_numeric(rn)) {
								// compare by number/char sequence; by capping the match value at 5, we increase the separation between immediately adjacent grades (e.g. RL.5.3 - RL.6.3) and more distant grades (e.g. RL.5.3 - RL.7.3)
								let val = Math.abs(ln - rn)
								if (val > 5) val = 5
								num += ((5 - val) / 50) * match_params.hcs_num_mult

								// give a tiny bit higher weight to an "up" than a "down"
								if (rn > ln) num += 0.05

							} else {
								// else split into one-character "words" and use string_similarity_words to compare
								num += (U.string_similarity_words(ln.split('').join(' '), rn.split('').join(' '))) / 10 * match_params.hcs_char_mult
							}
						}
					}
					return (num / den)
				}
			}

		},

		items_to_be_selected() {
			let items = []
			let total_selectable_items = 0
			for (let item of this.table_items) {
				if (item.right_identifier != '') total_selectable_items++
				if (item.right_identifier != '' && !this.selected_items[item.identifier]) items.push(item)
			}
			this.all_items_selected = (total_selectable_items > 0 && items.length == 0)
			// console.log(total_selectable_items, this.all_items_selected, items)
			return items
		},
		select_all_items() {
			this.items_to_be_selected()
			// console.log(this.all_items_selected ? "deselect" : "select")
			for (let item of this.table_items) {
				// console.log(this.all_items_selected)
				if (!this.all_items_selected && !this.selected_items[item.identifier]) {
					this.$set(this.selected_items, item.identifier, item.right_identifier)
				}
				if (this.all_items_selected && this.selected_items[item.identifier]) {
					this.$set(this.selected_items, item.identifier, false)
				}
			}
			// reset spot_checked_items whenever the set of selected items changes
			this.spot_checked_items = {}
			this.items_to_be_selected()
		},

		left_tree_show_chooser_fn(component, chosen_node, $event) {
			// clear the previous right suggestion 
			this.$store.commit('set', ['crosswalk_revealed_right_item', this.lst_key, undefined])
			this.tree_right_chosen_node = undefined

			if (this.tree_left_chosen_node == chosen_node) {
				this.$store.commit('set', ['crosswalk_chosen_left_item', this.lst_key, undefined])
				this.$store.commit('set', ['crosswalk_revealed_left_item', this.lst_key, undefined])
				this.tree_left_chosen_node = null
				this.spot_check_candidates = null
				this.spot_check_row = null
				return
			}

			this.$store.commit('set', ['crosswalk_chosen_left_item', this.lst_key, chosen_node.tree_key+''])
			this.$store.commit('set', ['crosswalk_revealed_left_item', this.lst_key, chosen_node.tree_key+''])
			this.tree_left_chosen_node = chosen_node
			// this.show_spot_check(this.table_items_hash[chosen_node.cfitem.identifier], 5)
			// TODO: need to be able to show spot check items even if this item isn't showing in the table
			if (this.table_items_hash[chosen_node.cfitem.identifier]) this.show_spot_check(this.table_items_hash[chosen_node.cfitem.identifier], 5)
			else {
				this.spot_check_candidates = null
				this.spot_check_row = null
			}
		},
		right_tree_show_chooser_fn(component, chosen_node, $event) {
			if (this.tree_left_chosen_node) {
				let left_identifier = this.tree_left_chosen_node.cfitem.identifier
				let right_identifier = chosen_node.cfitem.identifier

				let existing_associations = this.crosswalk_framework_record.cfo.associations_hash[left_identifier]
				let already_associated = false
				if (existing_associations != undefined && existing_associations.length > 0) {
					for (let association of existing_associations) {
						if (association.destinationNodeURI.identifier == right_identifier || association.originNodeURI.identifier == right_identifier) already_associated = true
					}
				}
				if (!already_associated) {
					this.add_associations("exact", {left_identifier, right_identifier})
				}
				else {
					this.delete_associations({left_identifier, right_identifier})
				}
				component.node_is_chosen_local = false
			} else {
				if (this.tree_right_chosen_node == chosen_node) {
					this.$store.commit('set', ['crosswalk_chosen_right_item', this.lst_key, undefined])
					this.tree_right_chosen_node = undefined
				} else {
					this.$store.commit('set', ['crosswalk_chosen_right_item', this.lst_key, chosen_node.tree_key+''])
					this.tree_right_chosen_node = chosen_node
				}
				// TODO: left isn't pickable in this case...
			}
		},
		show_right_item_in_tree(identifier) {
			let node = this.framework_record_b.cfo.cfitems[identifier]?.tree_nodes[0]

			this.tree_right_revealed_node = node
			// console.log(node)
			this.$store.commit('set', ['crosswalk_revealed_right_item', this.lst_key, node ? node.tree_key+'' : undefined])

			if (node == undefined) return
			
			// start by hiding all nodes
			this.open_tree_nodes_right = {}

			// open the suggested node
			let parent_node = node.parent_node
			while (!empty(parent_node)) {
				this.$set(this.open_tree_nodes_right, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}

			// highlight it and set revealed_suggestion_node (setting highlighted_identifier_override will ensure that the item's statement will be wrapped)
			this.highlighted_identifier_override = node.cfitem.identifier
			this.revealed_suggestion_node = node

			// scroll to it if necessary
			this.$nextTick(x=>{
				let node_jq = $(this.$el).find(sr('[data-case-tree-item-tree-key=$1]', node.tree_key))
				if (node_jq.length == 0) {
					// console.log('can’t scroll in reveal_suggestion')
					return
				}

				// make sure the suggested item is showing in its tree-scroll-wrapper
				let $ctsr = node_jq.parents('.k-case-tree-scroll-wrapper')
				vapp.$vuetify.goTo(node_jq[0], {container: $ctsr[0], offset:50, duration:200})
			})
		},
		show_left_item_in_tree(item) {
			let node = item.cfitem.tree_nodes[0]
			this.$store.commit('set', ['crosswalk_chosen_left_item', this.lst_key, node.tree_key+''])
			this.tree_left_chosen_node = node

			this.$store.commit('set', ['crosswalk_revealed_left_item', this.lst_key, node.tree_key+''])

			// start by hiding all nodes
			this.open_tree_nodes_left = {}

			// open the suggested node
			let parent_node = node.parent_node
			while (!empty(parent_node)) {
				this.$set(this.open_tree_nodes_left, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}

			// highlight it and set revealed_suggestion_node (setting highlighted_identifier_override will ensure that the item's statement will be wrapped)
			this.highlighted_identifier_override = node.cfitem.identifier
			this.revealed_suggestion_node = node

			// scroll to it if necessary
			this.$nextTick(x=>{
				let node_jq = $(this.$el).find(sr('[data-case-tree-item-tree-key=$1]', node.tree_key))
				if (node_jq.length == 0) {
					// console.log('can’t scroll in reveal_suggestion')
					return
				}

				// make sure the suggested item is showing in its tree-scroll-wrapper
				let $ctsr = node_jq.parents('.k-case-tree-scroll-wrapper')
				vapp.$vuetify.goTo(node_jq[0], {container: $ctsr[0], offset:50, duration:200})
			})
		},

		cw_map_item_guids() {
			U.map_item_guids = function(json, mappings) {
				// json is the full CASE json for the framework
				// mappings should include an array of two-item arrays, each of which has the current guid in json, then the guid we want to map onto
				let item_count = 0
				for (let mapping of mappings) {
					++item_count
					// find the current item/guid in CFItems
					let item = json.CFItems.find(x=>x.identifier == mapping[0])
					if (!item) {
						console.log(`${item_count}. couldn’t find item for identifier ${mapping[0]}`)
						continue
					}

					// update the CFItem
					item.identifier = mapping[1]
					item.uri = item.uri.replace(mapping[0], mapping[1])

					// update any associations with the old identifier
					let assoc_count = 0
					let a1 = json.CFAssociations.filter(x=>x.destinationNodeURI.identifier == mapping[0])
					for (let assoc of a1) {
						assoc.destinationNodeURI.identifier = mapping[1]
						assoc.destinationNodeURI.uri = assoc.destinationNodeURI.uri.replace(mapping[0], mapping[1])
						++assoc_count
					}
					let a2 = json.CFAssociations.filter(x=>x.originNodeURI.identifier == mapping[0])
					for (let assoc of a2) {
						assoc.originNodeURI.identifier = mapping[1]
						assoc.originNodeURI.uri = assoc.originNodeURI.uri.replace(mapping[0], mapping[1])
						++assoc_count
					}

					console.log(`${item_count}. updated item and ${assoc_count} assocs: ${mapping[0]} => ${mapping[1]} : ${item.humanCodingScheme}. ${item.fullStatement.substr(0,50)}`)

					// if (item_count > 20) break
				}

				return json
			}

			let json = object_copy(this.framework_record_a.json)
			let mappings = []
			for (let assoc of this.crosswalk_framework_record.json.CFAssociations) {
				if (assoc.associationType == 'exactMatchOf') {
					mappings.push([assoc.originNodeURI.identifier, assoc.destinationNodeURI.identifier])
				}
			}
			console.log(mappings)
			let new_json = U.map_item_guids(json, mappings)
			console.log(JSON.stringify(new_json))

			let filename = `UPDATED-CASE-${json.CFDocument.title}.json`
			this.$prompt({
				title: 'File Name',
				text: 'Enter file name to download json:',
				initialValue: filename,
				disableForEmptyValue: true,
				acceptText: 'Save',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(filename => {
				filename = $.trim(filename)
				U.download_json_file(new_json, filename)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		close_crosswalk_editor() {
			this.check_in_crosswalk_framework_for_editing()
			vapp.go_to_route('')
		},
	},
}
</script>

<style lang="scss">
.k-crosswalk-editor-outer-wrapper {
	background-color:#fff;
 	color:#000;
	font-size:16px;
	overflow:auto;
	height: calc(100vh - 60px);
}

.k-crosswalk-editor-table {
	// padding-top:8px;
	border-top:1px solid #444;
	border-radius:0;
	th {
		white-space:nowrap;
	}
	td {
		font-size:12px!important;
		line-height:16px;
		vertical-align:top;
		padding-top:4px!important;
		padding-bottom:4px!important;
		height:24px!important;
		// border-color:transparent!important;
		p {
			margin:4px 0;
		}
	}
}

.k-crosswalk-table-center-column {
	border-left:1px solid #333; 
	border-right:1px solid #333;
}

.k-crosswalk-table-top-header {
	th {
		border-bottom:0!important;
		padding-bottom:0!important;
		height:12px!important;
		padding-top:8px!important;
		font-size:1.0em!important;
		line-height:1.0em!important;
	}
}

.k-crosswalk-table-spot-check-table {
	background-color:#ccc!important;
	border-radius:10px;
	padding:8px;
	td {
		padding:8px 8px;
		border-bottom:2px solid #ccc;
	}
}

.v-icon.v-icon::after {
	display: none;
}

.k-crosswalk-curated-match-item {
	border-width: 2px;
	transition: box-shadow .25s;
}

.k-crosswalk-curated-text-item {
	vertical-align: center;
}

.k-crosswalk-match-score-btn {
	text-align: center;
	font-weight: bold;
	border:1px solid #999;
	border-radius:4px;
	padding:0 2px;
	background-color:#fff;
	font-size:16px;
}

.k-crosswalk-not-spot-checked {
	opacity: 0.625;
}

.k-crosswalk-not-spot-checked:hover {
	opacity: 1.0;
}

.k-crosswalk-curated-text-box {
	padding: 8px 13px 8px 13px;
	margin: 2px;
	min-height: calc(100% - 4px);
	// border-style: solid;
	border-width: 1px;
	border-radius: 7px;
	box-shadow: 0 0 1px 0 rgba(0, 0, 0, .25);
	border-color: rgba(0, 0, 0, .125);
	background-color: rgba(255, 255, 255, .85);
	font-size: 15px;
	color: black;
	min-width: calc(50vw - 200px);
	max-width: calc(50vw - 200px);
}

// re-done for U.diff_string
.k-diff-old {
	background-color:$v-red-lighten-4!important;
	text-shadow: none!important;
}
.k-diff-new {
	background-color:$v-light-blue-lighten-4!important;
	text-shadow: none!important;
}

.k-crosswalk-hide-diff-coloring > * {
	background-color:transparent!important;
}

.k-crosswalk-hide-diff-coloring > * > * {
	background-color:transparent!important;
}

.k-crosswalk-hide-diff-coloring > * > * > * {
	background-color:transparent!important;
}

.k-crosswalk-hide-diff-coloring > * > * > * > * {
	background-color:transparent!important;
}

.k-crosswalk-hide-diff-coloring > * > * > * > * > * {
	background-color:transparent!important;
}

.spacious {
	font-size: 16px;
}

.comfy {
	font-size: 14px;
}

.dense {
	font-size: 12px;
}

.k-crosswalk-association-menu {
	opacity: 0.0;
	padding-right: 0px;
	transition: opacity .125s, padding-right .125s;
}
.k-crosswalk-association-menu:hover {
	opacity: 1.0;
	padding-right: 15px;
}

.k-cw-associations-maker-suggestion-text {
	display: inline;
	border:1px solid transparent;
	padding:1px 1px;
	border-radius:3px;
}
.k-cw-associations-maker-suggestion-text-selected {
	border-color: #000;
}
.k-cw-associations-maker-suggestion-sim-score-not-selected {
	background-color:#999!important;

}
.k-cw-associations-maker-suggestion-sim-score-selected {
	background-color:#444!important;
}

</style>
