<!-- Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams -->
<template><div>
	<div style="margin:12px -8px 0 -8px"><v-data-table light dense
		:headers="headers"
		hide-default-header
		:items="table_rows"
		:footer-props="footer_options"
		class="k-framework-updates-table"
	>
		<template v-slot:header><thead>
			<tr class="k-framework-updates-table-header">
				<th scope="col" rowspan="2" :class="sort=='title'?'k-sorted-col':''" class="k-framework-updates-table-th-left" style="white-space:nowrap; padding-left:24px; border-bottom:1px solid #ddd;"><div class="d-flex align-end">
					<div @click="sort='title'" style="font-size:16px; padding-bottom:4px;">Framework Title</div>
					<v-text-field class="ml-3" style="font-weight:normal" v-model="search" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" label="Search" outlined single-line hide-details dense background-color="#fff"></v-text-field>
				</div></th>
				<th scope="col" style="cursor:default"></th>
				<th scope="col" colspan="5" class="text-center" style="cursor:default; border-left:1px solid #ccc;border-right:1px solid #ccc; font-size:16px!important;">Updates Since...</th>
				<th scope="col" colspan="1" style="cursor:default" class="k-framework-updates-table-th-right"></th>
			</tr>
			<tr class="k-framework-updates-table-header">
				<!-- th rowspan 2 above -->
				<th scope="col" @click="sort='lastChangeDateTime'" :class="sort=='lastChangeDateTime'?'k-sorted-col':''" style="white-space:nowrap" class="text-center">Last Change Date</th>
				<th scope="col" @click="sort='day'" :class="sort=='day'?'k-sorted-col':''" style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">This morning<br>{{ref_dates.day}}</th>
				<th scope="col" @click="sort='week'" :class="sort=='week'?'k-sorted-col':''" style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">1 Week Ago<br>{{ref_dates.week}}</th>
				<th scope="col" @click="sort='month'" :class="sort=='month'?'k-sorted-col':''" style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">1 Month Ago<br>{{ref_dates.month}}</th>
				<th scope="col" @click="sort='3_months'" :class="sort=='3_months'?'k-sorted-col':''" style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">3 Months Ago<br>{{ref_dates['3_months']}}</th>
				<th scope="col" @click="sort='year'" :class="sort=='year'?'k-sorted-col':''" style="white-space:nowrap; border-left:1px solid #ccc; border-right:1px solid #ccc;" class="text-center">1 Year Ago<br>{{ref_dates.year}}</th>
				<th scope="col" @click="sort='date_added'" :class="sort=='date_added'?'k-sorted-col':''" style="white-space:nowrap;" class="text-center">Date Added</th>
			</tr>
		</thead></template>
		<template v-slot:item="{ item }"><tr :class="row_css_class(item)">
			<td class="text-left d-flex align-center" style="cursor:pointer" @click="$emit('show_framework_report', item.fr.lsdoc_identifier)"><v-btn fab icon x-small class="mr-2"><v-icon small>fas fa-level-down-alt</v-icon></v-btn><b :class="framework_color_class(item)">{{item.title}}</b></td>
			<td class="text-center" style="vertical-align:middle"><nobr>{{item.lastChangeDateTime}}</nobr></td>
			<td class="text-center" style="vertical-align:middle; border-left:1px solid #ccc;">{{item['day']}}</nobr></td>
			<td class="text-center" style="vertical-align:middle; border-left:1px solid #ccc;">{{item['week']}}</nobr></td>
			<td class="text-center" style="vertical-align:middle; border-left:1px solid #ccc;">{{item['month']}}</nobr></td>
			<td class="text-center" style="vertical-align:middle; border-left:1px solid #ccc;">{{item['3_months']}}</nobr></td>
			<td class="text-center" style="vertical-align:middle; border-left:1px solid #ccc;">{{item['year']}}</nobr></td>
			<td class="text-center" style="vertical-align:middle; border-left:1px solid #ccc;"><nobr>{{item.date_added}}</nobr></td>
		</tr></template>
	</v-data-table></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: { },
	props: {
		framework_records: { required: true },
		update_records: { type: Object, required: true },
		ref_dates: { type: Object, required: true },
		parent_component: { required: true },
	},
	data() { return {
		headers: [
			{ text: 'Framework Title', align: 'left', sortable: true, value:'title' },
			{ text: 'Last Change Date', align: 'left', sortable: true, value:'lastChangeDateTime' },
			{ text: 'day', align: 'center', sortable: true, value:'day' },
			{ text: 'week', align: 'center', sortable: true, value:'week' },
			{ text: 'month', align: 'center', sortable: true, value:'month' },
			{ text: '3_months', align: 'center', sortable: true, value:'3_months' },
			{ text: 'year', align: 'center', sortable: true, value:'year' },
			// { text: 'Since Date Added', align: 'center', sortable: true, value:'added' },
			{ text: 'Date Added', align: 'left', sortable: true, value:'date_added' },
		],
		footer_options: {
			itemsPerPageOptions: [50,100,-1],
		},
		search: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		sort: {
			get() { return this.$store.state.lst.update_report_by_update_type_sort },
			set(val) { this.$store.commit('lst_set', ['update_report_by_update_type_sort', val]) }
		},
		update_types() { return this.parent_component.update_types },
		table_rows() {
			let arr = []
			for (let fr of this.framework_records) {
				let title = fr.json.CFDocument.title
				if (fr.ss_framework_data.category.search(/\[\[(.*?)\]\]$/) > -1) {
					title = RegExp.$1 + ': ' + title
				}

				// if search is empty always show the row
				if (!empty(this.search)) {
					let search = this.search.toLowerCase()
					let re = new RegExp(search, 'i')

					// currently searching by title and document identifier
					let include = false
					if (title.toLowerCase().search(re) > -1) include = true
					else if (fr.lsdoc_identifier.toLowerCase() == search) include = true
					if (!include) continue
				}

				let ur = this.update_records[fr.lsdoc_identifier]
				let o = {
					fr: fr,
					ur: ur,

					title: title,
					lastChangeDateTime: this.parent_component.format_date((fr.ss_framework_data.computed_lastChangeDateTime) ? fr.ss_framework_data.computed_lastChangeDateTime : fr.json.CFDocument.lastChangeDateTime, 'time'),
					date_added: this.parent_component.format_date(ur.framework_record_created_at),
				}

				o.day = this.calculate_update_total(ur, 'day')
				o.week = this.calculate_update_total(ur, 'week')
				o.month = this.calculate_update_total(ur, 'month')
				o['3_months'] = this.calculate_update_total(ur, '3_months')
				o.year = this.calculate_update_total(ur, 'year')
				// o.added = this.calculate_update_total(ur, 'added')

				arr.push(o)
			}

			arr.sort((a,b) => {
				if (!empty(a[this.sort]) && empty(b[this.sort])) return -1
				if (empty(a[this.sort]) && !empty(b[this.sort])) return 1

				// for update or date columns, sort descending; otherwise sort ascending
				// if (['day', 'week', 'month', '3_months', 'year'].includes(this.sort)) {
				// for title, sort ascending; otherwise sort descending
				if (['title'].includes(this.sort)) {
					if (a[this.sort] < b[this.sort]) return -1
					if (a[this.sort] > b[this.sort]) return 1
				} else {
					if (a[this.sort] > b[this.sort]) return -1
					if (a[this.sort] < b[this.sort]) return 1
				}

				// if sort columns are equal and sort != lastChangeDateTime, sort by lastChangeDateTime
				if (this.sort != 'lastChangeDateTime') {
					if (a.lastChangeDateTime > b.lastChangeDateTime) return -1
					if (a.lastChangeDateTime < b.lastChangeDateTime) return 1
				}
				return 0
			})
			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.framework_update_report_by_type = this
	},
	mounted() {
	},
	methods: {
		calculate_update_total(ur, key) {
			if (empty(ur) || empty(ur[key]) || ur[key] == '-') return ''
			let urs = ur[key].update_report_summary

			// calculate total changes the user has said they're interested in seeing
			let count = 0

			// for item updates, we add up all three, even though in theory an item could be moved, have its statement changed, and have another property changed
			if (this.update_types.item_updates_not_code_or_statement) count += urs.item_updates_not_code_or_statement ?? 0
			if (this.update_types.item_updates_code_or_statement) count += urs.item_updates_code_or_statement ?? 0
			if (this.update_types.item_updates_moved) count += urs.item_updates_code_or_statement ?? 0

			if (this.update_types.items_created) count += urs.items_created ?? 0
			if (this.update_types.items_deleted) count += urs.items_deleted ?? 0

			if (this.update_types.assocs_updated) count += urs.assocs_updated ?? 0
			if (this.update_types.assocs_created) count += urs.assocs_created ?? 0
			if (this.update_types.assocs_deleted) count += urs.assocs_deleted ?? 0

			if (this.update_types.document_updated) count += urs.document_updated ?? 0

			return count
		},
		row_css_class(item) {
			if (this.$store.state.last_framework_update_report == item.fr.lsdoc_identifier) {
				return U.framework_color(item.fr.lsdoc_identifier)
			} else return ''
		},
		framework_color_class(item) {
			return U.framework_color(item.fr.lsdoc_identifier) + '-text'
		},
	}
}
</script>

<style lang="scss">
.k-framework-updates-table-last-viewed-framework {
	td {
		background-color:$v-yellow-lighten-4;
	}
}
</style>
