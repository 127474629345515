<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-card class="mx-2 mb-8">
		<v-card-title class="py-2 pr-2 pl-5" style="border-bottom:1px solid #ccc">
			<b>Framework Associations <v-btn class="ml-2" x-small text color="primary" @click="cancel_table_mode"><v-icon small class="mr-1">fas fa-tree</v-icon>Return to Tree View</v-btn></b>
			<v-spacer></v-spacer>
			<v-btn v-if="this.table_items.length>0" small color="primary" class="mr-4" @click="filters_showing=!filters_showing"><v-icon small class="mr-2">fas fa-filter</v-icon>{{filters_showing?'Hide':'Show'}} Filters</v-btn>
			<v-btn small color="primary" @click="export_data" class="mr-4"><v-icon small class="mr-2">fas fa-file-arrow-down</v-icon>Export</v-btn>
			<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="table_mode" mandatory>
				<v-btn small light value="items" @click.stop="$emit('switch_table')" aria-pressed="false">Items</v-btn>
				<v-btn small light value="associations" @click.stop="" aria-pressed="true">Associations</v-btn>
			</v-btn-toggle>
			<!-- <v-btn fab x-small color="primary" class="ml-3" @click="cancel_table_mode"><v-icon>fas fa-tree</v-icon></v-btn> -->
		</v-card-title>
		<v-card-text style="font-size:16px; color:#000;">
			<div class="pt-4 d-flex">
				<div  style="flex:1 0 60%" class="mr-2 d-flex">
				</div>
			</div>
			<div v-if="filters_showing||this.table_items.length==0" class="pt-4 d-flex">
				<div class="ml-6 mr-8">
					<div class="mb-1"><b>Show associations to/from:</b></div>
					<v-checkbox v-for="(option, lsdoc_identifier) in viewer.assoc_framework_menu_items" :key="lsdoc_identifier" v-if="lsdoc_identifier!='copies'" v-model="option.showing" @click="" hide-details dense style="max-width:440px;"><template v-slot:label><b v-if="option.count" class="mr-1" style="margin-left:-4px;font-size:10px;background-color:#666; border-radius:8px; padding:2px 5px; line-height:12px; color:#fff;">{{option.count}}</b>{{option.title}}</template></v-checkbox>
				</div>
				<div class="mr-8">
					<div class="mb-1"><b>Show association types:</b></div>
					<v-checkbox v-for="(option, name) in viewer.assoc_type_menu_options" :key="name" v-show="option.used" v-model="option.showing" @click="viewer.update_associations_to_show" hide-details dense><template v-slot:label><b v-if="option.count" class="mr-1" style="margin-left:-4px;font-size:10px;background-color:#666; border-radius:8px; padding:2px 5px; line-height:12px; color:#fff;">{{option.count}}</b>{{option.name}}</template></v-checkbox>
					<v-checkbox class="mt-5 pt-0" label="Show Is Child Of associations" v-model="show_is_child_of" hide-details></v-checkbox>
					<v-checkbox class="mt-1 pt-0" label="Show Document-Document associations" v-model="show_framework_framework" hide-details></v-checkbox>

					<div v-if="hide_associations" class="mt-4" style="max-width:240px;"><i>Note that some association types have been hidden to most users.</i></div>
				</div>
				<div>
					<div class="mb-1"><b>Show fields:</b></div>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Association Identifier" v-model="associations_table_fields_to_show.identifier" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Association URI" v-model="associations_table_fields_to_show.uri" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Origin Framework Category" v-model="associations_table_fields_to_show.origin_category" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Origin Framework Identifier" v-model="associations_table_fields_to_show.origin_framework_identifier" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Origin Identifier" v-model="associations_table_fields_to_show.origin_identifier" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Origin URI" v-model="associations_table_fields_to_show.origin_uri" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Origin Item" v-model="associations_table_fields_to_show.origin" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Association Type" v-model="associations_table_fields_to_show.associationType" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Destination Framework Category" v-model="associations_table_fields_to_show.destination_category" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Destination Framework Identifier" v-model="associations_table_fields_to_show.destination_framework_identifier" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Destination Identifier" v-model="associations_table_fields_to_show.destination_identifier" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Destination URI" v-model="associations_table_fields_to_show.destination_uri" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Destination Item" v-model="associations_table_fields_to_show.destination" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Sequence Number" v-model="associations_table_fields_to_show.sequenceNumber" hide-details></v-checkbox>
					<v-checkbox @change="update_associations_table_fields_to_show" class="mt-0 pt-0" label="Last Modified" v-model="associations_table_fields_to_show.lastChangeDateTime" hide-details></v-checkbox>
				</div>
			</div>

			<v-data-table light dense
				:headers="headers"
				:items="table_items"
				:custom-filter="table_search_filter"
				:search="search"
				:footer-props="footer_options"
				:items-per-page="10"
				:options="table_options"
				:no-data-text="no_data_text"
				@update:items-per-page="update_items_per_page"
				class="k-framework-associations-table"
			>
				<template v-slot:item="{ item }"><tr>
					<td v-if="editing_enabled(item)" class="pa-0"><v-btn small icon color="red darken-2" @click="delete_association(item)"><v-icon small>fas fa-circle-xmark</v-icon></v-btn></td>
					<!-- <td v-if="viewer.editing_enabled" class="px-0"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="checkbox"></v-checkbox></td> -->
					<td v-if="associations_table_fields_to_show.identifier" class="px-1" style="font-size:9px!important;cursor:copy;" @click="copy_identifier(item)"><p><nobr>{{item.identifier}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.uri" style="font-size:9px!important;"><p>{{item.uri}}</p></td>
					<td v-if="associations_table_fields_to_show.origin_category" class="text-center"><p><nobr>{{item.origin_category}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.origin_framework_identifier" class="text-center" style="font-size:9px!important;"><p><nobr>{{item.origin_framework_identifier}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.origin_identifier" class="px-1" style="font-size:9px!important;"><p><nobr>{{item.origin_identifier}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.origin_uri" class="px-1" style="font-size:9px!important;"><p><nobr>{{item.origin_uri}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.origin" style="width:50%; padding:0; min-width:300px;"><div class="k-associations-table-item-cell" @click="item_clicked($event,item.origin_item)" :class="item.origin_color_class" v-html="item.origin_html"></div></td>
					<td v-if="associations_table_fields_to_show.associationType" class="text-left"><p style="white-space:nowrap" v-html="item.associationType"></p></td>
					<td v-if="associations_table_fields_to_show.destination_category" class="text-center"><p><nobr>{{item.destination_category}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.destination_framework_identifier" class="text-center" style="font-size:9px!important;"><p><nobr>{{item.destination_framework_identifier}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.destination_identifier" class="px-1" style="font-size:9px!important;"><p><nobr>{{item.destination_identifier}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.destination_uri" class="px-1" style="font-size:9px!important;"><p><nobr>{{item.destination_uri}}</nobr></p></td>
					<td v-if="associations_table_fields_to_show.destination" style="width:50%; padding:0; min-width:300px;"><div class="k-associations-table-item-cell" @click="item_clicked($event,item.destination_item)" :class="item.destination_color_class" v-html="item.destination_html"></div></td>
					<td v-if="associations_table_fields_to_show.sequenceNumber" class="text-center"><p>{{item.sequenceNumber}}</p></td>
					<td v-if="associations_table_fields_to_show.lastChangeDateTime"><p><nobr>{{item.date_string}}</nobr></p></td>
				</tr></template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		framework_record: { type: Object, required: true },
		starting_comment_id: { type: Number, required: false, default() { return -1 } },
		hide_associations: { type: Boolean, required: false, default() { return false } },	// see CASEFrameworkViewer
		viewer: { required: false },
	},
	data() { return {
		table_mode: 'associations',
		footer_options: {
			itemsPerPageOptions: [20,50,100],
		},
		table_options: {
			itemsPerPage: 50,
			page: 1,
		},
		show_is_child_of: false,
		show_framework_framework: false,
		filters_showing: false,
		associations_table_fields_to_show: {identifier:false, uri:false, origin_framework_identifier:false, origin_category:false, origin_identifier:false, origin_uri:false, origin:true, associationType:true, destination_framework_identifier:false, destination_category:false, destination_identifier:false, destination_uri:false, destination:true, sequenceNumber:false, lastChangeDateTime:true},
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters([]),
		search() { return this.viewer.search_terms },
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		association_type_select_options() {
			let arr = []
			for (let associationType in this.$store.state.association_type_labels) {
				// if hide_associations is on, only show isChildOf
				if (this.hide_associations && associationType != 'isChildOf') continue

				// don't show the unofficial copiedFromSource, aliasOfExternal, and aliasOf types
				if (associationType == 'copiedFromSource' || associationType == 'aliasOf' || associationType == 'aliasOfExternal') continue

				arr.push({value:associationType, text:this.$store.state.association_type_labels[associationType]})
			}
			arr.push({value:'framework-framework', text:'Framework <-> Framework associations'})
			return arr
		},
		headers() {
			let arr = []
			if (this.viewer.editing_enabled) arr.push({text: '', sortable: false})
			if (this.associations_table_fields_to_show.identifier) arr.push({text: 'Identifier', align: 'left', sortable: true, value: 'identifier'})
			if (this.associations_table_fields_to_show.uri) arr.push({text: 'URI', align: 'left', sortable: true, value: 'uri'})
			if (this.associations_table_fields_to_show.origin_category) arr.push({text: 'Origin Category', align: 'center', sortable: true, value: 'origin_category'})
			if (this.associations_table_fields_to_show.origin_framework_identifier) arr.push({text: 'Origin Framework ID', align: 'center', sortable: true, value: 'origin_framework_identifier'})
			if (this.associations_table_fields_to_show.origin_identifier) arr.push({text: 'Origin Identifier', align: 'left', sortable: true, value: 'origin_identifier'})
			if (this.associations_table_fields_to_show.origin_uri) arr.push({text: 'Origin URI', align: 'left', sortable: true, value: 'origin_uri'})
			if (this.associations_table_fields_to_show.origin) arr.push({text: 'Origin Item', align: 'left', sortable: true, value: 'origin_string'})
			if (this.associations_table_fields_to_show.associationType) arr.push({text: 'Association Type', align: 'center', sortable: true, value: 'associationType_raw'})
			if (this.associations_table_fields_to_show.destination_category) arr.push({text: 'Destination Category', align: 'center', sortable: true, value: 'destination_category'})
			if (this.associations_table_fields_to_show.destination_framework_identifier) arr.push({text: 'Destination Framework ID', align: 'center', sortable: true, value: 'destination_framework_identifier'})
			if (this.associations_table_fields_to_show.destination_identifier) arr.push({text: 'Destination Identifier', align: 'left', sortable: true, value: 'destination_identifier'})
			if (this.associations_table_fields_to_show.destination_uri) arr.push({text: 'Destination URI', align: 'left', sortable: true, value: 'destination_uri'})
			if (this.associations_table_fields_to_show.destination) arr.push({text: 'Destination Item', align: 'left', sortable: true, value: 'destination_string'})
			if (this.associations_table_fields_to_show.sequenceNumber) arr.push({text: 'Sequence Number', align: 'center', sortable: false, value: 'sequenceNumber'})
			if (this.associations_table_fields_to_show.lastChangeDateTime) arr.push({text: 'Last Modified', align: 'left', sortable: true, value: 'lastChangeDateTime'})
			return arr
		},
		table_items() {
			// if we're not actually showing the table, don't take the time to compute the table_items
			if (this.$store.state.lst.viewer_mode != 'table' || this.$store.state.lst.viewer_table_mode != 'associations') return []

			let arr = []
			let framework_category_hash = {}

			let add_assoc = (assoc) => {
				let origin_item = this.association_item(assoc.originNodeURI)
				let destination_item = this.association_item(assoc.destinationNodeURI)

				// get a tree_sequence for the assoc, based on the tree_key of the item (origin or dest) in this framework; if both are from this framework, use the origin
				let tree_sequence = 999999
				if (origin_item.item && this.framework_record.cfo.cfitems[origin_item.item.identifier]) {
					tree_sequence = this.framework_record.cfo.cfitems[origin_item.item.identifier].tree_nodes[0]?.tree_key
				} else if (destination_item.item && this.framework_record.cfo.cfitems[destination_item.item.identifier]) {
					tree_sequence = this.framework_record.cfo.cfitems[destination_item.item.identifier].tree_nodes[0]?.tree_key
				}

				let origin_category, destination_category, origin_framework_identifier, destination_framework_identifier
				if (origin_item.framework) {
					origin_framework_identifier = origin_item.framework.lsdoc_identifier
					if (!framework_category_hash[origin_item.framework.lsdoc_identifier]) {
						let o = U.parse_framework_category(origin_item.framework.ss_framework_data.category)
						framework_category_hash[origin_item.framework.lsdoc_identifier] = (o.map_label ? o.map_label : o.title)
					}
					origin_category = framework_category_hash[origin_item.framework.lsdoc_identifier]
				}
				if (destination_item.framework) {
					destination_framework_identifier = destination_item.framework.lsdoc_identifier
					if (!framework_category_hash[destination_item.framework.lsdoc_identifier]) {
						let o = U.parse_framework_category(destination_item.framework.ss_framework_data.category)
						framework_category_hash[destination_item.framework.lsdoc_identifier] = (o.map_label ? o.map_label : o.title)
					}
					destination_category = framework_category_hash[destination_item.framework.lsdoc_identifier]
				}

				arr.push({
					assoc: assoc,
					tree_sequence: tree_sequence,
					identifier: assoc.identifier,
					uri: assoc.uri,
					origin_item: origin_item,
					origin_framework_identifier: origin_framework_identifier ?? '—',
					origin_category: origin_category ?? '—',
					origin_identifier: assoc.originNodeURI.identifier,
					origin_uri: assoc.originNodeURI.uri,
					origin_string: origin_item.string,
					origin_html: origin_item.html,
					origin_color_class: origin_item.color_class,
					associationType: `<i class="${this.$store.state.association_type_icons[assoc.associationType]}"></i> ${this.$store.state.association_type_labels[assoc.associationType]}`,
					associationType_raw: `${this.$store.state.association_type_order.indexOf(assoc.associationType)} ${assoc.associationType}`,
					destination_item: destination_item,
					destination_framework_identifier: destination_framework_identifier ?? '—',
					destination_category: destination_category ?? '—',
					destination_identifier: assoc.destinationNodeURI.identifier,
					destination_uri: assoc.destinationNodeURI.uri,
					destination_string: destination_item.string,
					destination_html: destination_item.html,
					destination_color_class: destination_item.color_class,
					sequenceNumber: (empty(assoc.sequenceNumber)) ? '' : assoc.sequenceNumber+'',
					date_string: assoc.lastChangeDateTime ? U.local_last_change_date_time(assoc.lastChangeDateTime) : '',
					lastChangeDateTime: assoc.lastChangeDateTime,
				})

				assoc_ids.push(assoc.identifier)
			}

			// for (let assoc of this.framework_record.json.CFAssociations) {
			let assoc_ids = []
			for (let identifier in this.framework_record.cfo.displayed_associations_hash) {
				for (let assoc of this.framework_record.cfo.displayed_associations_hash[identifier]) {
					// we may encounter the assoc multiple times (for intra-framework associations); if so only use the first one encountered
					if (assoc_ids.includes(assoc.identifier)) continue

					// if hide_associations is on, only show isChildOf -- which means that this loop probably won't net any assocs
					if (this.hide_associations && assoc.associationType != 'isChildOf') continue

					add_assoc(assoc)
				}
			}

			// add isChildOf and/or framework-framework assocs if directed to
			if (this.show_is_child_of || this.show_framework_framework) {
				for (let assoc of this.framework_record.json.CFAssociations) {
					if (this.show_framework_framework) {
						if (this.framework_records.find(x=>x.lsdoc_identifier==assoc.originNodeURI.identifier) && this.framework_records.find(x=>x.lsdoc_identifier==assoc.destinationNodeURI.identifier)) {
							add_assoc(assoc)
						}
					}

					if (this.show_is_child_of) {
						if (assoc.associationType == 'isChildOf') {
							add_assoc(assoc)
						}
					}
				}
			}

			// sort by tree_sequence
			arr.sort((a,b)=>a.tree_sequence-b.tree_sequence)

			return arr
		},
		no_data_text() {
			if (this.table_items.length == 0) return 'No associations match your chosen filters.'
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// set itemsPerPage to value from store
		this.table_options.itemsPerPage = this.$store.state.lst.associations_table_items_per_page

		if (this.$store.state.lst.associations_table_fields_to_show) {
			this.associations_table_fields_to_show = JSON.parse(this.$store.state.lst.associations_table_fields_to_show)
		}
	},
	methods: {
		update_associations_table_fields_to_show() {
			this.$store.commit('lst_set', ['associations_table_fields_to_show', JSON.stringify(this.associations_table_fields_to_show)])
		},
		
		update_items_per_page(val) {
			// when the user chooses a different items-per-page value, save in store so we can restore that number when the table re-opens later
			this.$store.commit('lst_set', ['associations_table_items_per_page', val])
		},

		association_framework_identifier(aid) {
			// look for the pattern / (:framework-identifier:)/ at the end of the associated_item_data's title
			if (aid.title.search(/\s*\(:(\S+):\)\s*$/) > -1) {
				// if we found it, this will be the identifier for the framework this item comes from
				let framework_identifier = RegExp.$1
				return framework_identifier

			} else {
				// if the associated item is part of the home framework, return it
				if (this.framework_record.cfo.cfitems[aid.identifier]) {
					return this.framework_record.lsdoc_identifier
				}
			}
			// if we get to here return empty string
			return ''
		},

		load_framework(lsdoc_identifier) {
			// first load the framework from the server
			U.loading_start('Loading framework…')
			this.$store.dispatch('get_lsdoc', lsdoc_identifier).then(()=>{
				U.loading_stop()

				// then build the cfo for the framework
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
				U.build_cfo(this.$worker, fr.json).then((cfo)=>{
					this.$store.commit('set', [fr, 'cfo', cfo])
					this.$store.commit('set', [fr, 'framework_json_loading', false])
					U.loading_stop()
				})
				.catch((e)=>{
					this.$store.commit('set', [fr, 'framework_json_load_failed', true])
					U.loading_stop()
					console.log(e)
				})

			}).catch((e)=>{
				console.log(e)
				U.loading_stop()
				// fail silently, but set framework_json_load_failed to true so we don't keep trying to load
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
				if (fr) this.$store.commit('set', [fr, 'framework_json_load_failed', true])
			})
		},

		association_item(aid) {
			// adapted from CASEItemAssociations
			// aid is "associated_item_data", and should be either the originNodeURI or the destinationNodeURI
			let o = {
				openable: false,
				expandable: false,
				html: '',
				color_class: '',
			}

			// if we have the associated framework in memory, get the html from there
			let afid = this.association_framework_identifier(aid)	// this will be a CFDocument (or something similar at least)
			if (!empty(afid)) {
				let af = this.framework_records.find(x=>x.lsdoc_identifier == afid)
				if (af) {
					if (af.framework_json_loaded) {
						// the cfo might take a few ticks to be processed after the framework is loaded
						if (af.cfo) {
							o.framework = af
							let item = af.cfo.cfitems[aid.identifier]
							if (item) {
								// if we find the item, it's expandable
								o.expandable = true

								// record the item; then if the item is from the current framework, it's openable
								o.item = item
								if (af.lsdoc_identifier == this.framework_record.lsdoc_identifier) {
									o.openable = true

									// also apply item type color for items from this framework
									let item_type_level = this.framework_record.cfo.item_type_levels.find(x=>x.item_type == U.item_type_string(item))
									if (item_type_level) {
										o.color_class = 'k-case-tree-item-type-color-' + item_type_level.level_index
									} else {
										o.color_class = 'k-case-tree-item-type-color-neutral'
									}
								} else {
									// else the item is from another framework; use a color_class that shows a border with that framework's color
									o.color_class = U.framework_color(af.lsdoc_identifier) + '-border k-case-tree-associations-table-item-other-framework'
								}

								// start with icon for the type
								if (item.tree_nodes && item.tree_nodes.length > 0 && item.tree_nodes[0].children.length>0) {
									o.html = '<i class="fas fa-chevron-circle-right grey--text text--darken-0" style="margin-right:3px"></i>'
								} else {
									o.html = '<i class="fas fa-dot-circle grey--text text--darken-0" style="margin-right:3px"></i>'
								}
								// console.log(item)
								if (item.humanCodingScheme) o.html += sr('<b class="grey--text text--darken-3">$1</b> $2', item.humanCodingScheme, item.fullStatement)
								else o.html += item.fullStatement
							}
						}
					} else if (!af.framework_json_load_failed && !af.framework_json_loading) {
						this.load_framework(afid)
					}
				}
			}

			if (empty(o.html)) {
				// the item might be a document; check for that
				if (this.framework_records.find(x=>x.lsdoc_identifier==aid.identifier)) {
					o.html = sr('<i class="fas fa-map grey--text text--darken-0" style="margin-right:3px"></i>$1', aid.title)
				} else {
					// if we found the item's framework but not the item, note that it's orphaned
					if (o.framework) {
						o.orphaned = true
						o.html = '<i class="mr-1 red--text fas fa-triangle-exclamation"></i>'
					}
					// get the item from the association that isn't this item; then take out the framework pattern (see above) if there
					o.html += aid.title.replace(/\s*\(:(\S+):\)\s*$/, '')
				}
			}
			o.html = U.marked_latex(o.html)
			o.string = U.html_to_text(o.html)
			return o
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			if (this.associations_table_fields_to_show.identifier && item.identifier.search(re) > -1) return true
			if (this.associations_table_fields_to_show.uri && item.uri.search(re) > -1) return true
			if (this.associations_table_fields_to_show.origin_framework_identifier && item.origin_framework_identifier.search(re) > -1) return true
			if (this.associations_table_fields_to_show.origin_category && item.origin_category.search(re) > -1) return true
			if (this.associations_table_fields_to_show.origin_identifier && item.origin_identifier.search(re) > -1) return true
			if (this.associations_table_fields_to_show.origin_uri && item.origin_uri.search(re) > -1) return true
			if (this.associations_table_fields_to_show.origin && item.origin_string.search(re) > -1) return true
			if (this.associations_table_fields_to_show.associationType && item.associationType.search(re) > -1) return true
			if (this.associations_table_fields_to_show.destination_framework_identifier && item.destination_framework_identifier.search(re) > -1) return true
			if (this.associations_table_fields_to_show.destination_category && item.destination_category.search(re) > -1) return true
			if (this.associations_table_fields_to_show.destination_identifier && item.destination_identifier.search(re) > -1) return true
			if (this.associations_table_fields_to_show.destination_uri && item.destination_uri.search(re) > -1) return true
			if (this.associations_table_fields_to_show.destination && item.destination_string.search(re) > -1) return true
			if (this.associations_table_fields_to_show.sequenceNumber && item.sequenceNumber.search(re) > -1) return true
			if (this.associations_table_fields_to_show.lastChangeDateTime && item.date_string.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		copy_identifier(item) {
			U.copy_to_clipboard(item.identifier)
			this.$inform('Identifier copied to clipboard')
		},

		item_clicked(evt, o) {
			let switched_to_tree = false

			if (o.openable && o.item && o.item.tree_nodes && o.item.tree_nodes.length > 0) {
				// do the same thing we would do if we'd found the item in a search and clicked on it
				this.$emit('search_result_clicked', {tree_key:o.item.tree_nodes[0].tree_key})

				// open in tree view if cmd key is held down
				if (U.meta_or_alt_key(evt)) {
					this.$emit('switch_to_tree')
					switched_to_tree = true
				}
			}

			if (U.meta_or_alt_key(evt)) {
				if (!switched_to_tree) {
					this.$inform('That item cannot be opened in this framework’s tree view.')
				}

			} else if (o.orphaned) {
				this.$alert('This association appears to be “orphaned”: the item you clicked on does not appear to exist in its framework. You can remove orphaned associations by editing the framework, then choosing “Clean Framework JSON” from the EDIT FRAMEWORK menu, then choosing the ‘Remove “orphans”’ option.')

			} else if (o.expandable) {
				// show the item in the expanded view. we have to send a node to expanded_item, but it doesn't really matter which one we send, so send the first one
				// note that the second param is the framework where the item came from
				this.viewer.show_expanded_item(o.item.tree_nodes[0], o.framework)
			}
		},

		export_data() {
			if (vapp.signed_in_only('export CASE data in CSV format')) return

			// filteredItems would in theory give us items that are also filtered through table_search_filter, but this would be hackish, and it's not clear at this time if that would be more useful than getting the "raw" items list
			// let items = this.$refs.data_table.$children[0].filteredItems
			let items = this.table_items
			let msg = sr('This will export a CSV spreadsheet with the currently-visible columns for the $1 $2 that match the current table filters. Associations will be exported in “Last Modified” order (from newest to oldest).', items.length, U.ps('association', items.length))
			this.$confirm({
			    title: 'Export Association Data',
			    text: msg,
			    acceptText: 'Proceed',
				dialogMaxWidth: 600,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				// construct array to export
				let arr = [[]]

				// start with headers row
				for (let header of this.headers) arr[0].push(header.text)

				// now a row for each item
				for (let row of items) {
					let rarr = []
					for (let header of this.headers) {
						rarr.push(row[header.value])
					}
					arr.push(rarr)
				}

				let filename = sr('$1-CASE-associations.csv', this.framework_record.json.CFDocument.title)
				U.download_file(CSV.stringify(arr), filename)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		cancel_table_mode() { this.viewer.cancel_tile_mode() },

		editing_enabled(item) { return this.viewer.association_removable(item.assoc) },
		delete_association(item) {
			// viewer.remove_association will make the user confirm
			this.viewer.remove_association(item.assoc)
		},
	}
}
</script>

<style lang="scss">
.k-framework-associations-table {
	margin-top:8px;
	th {
		// white-space:nowrap;
		line-height:15px;
		vertical-align:bottom;
		position:relative;
		padding-bottom:4px!important;
		.v-icon {
			position:absolute;
			left:0;
			bottom:6px;
		}
	}
	td {
		font-size:12px!important;
		line-height:16px;
		vertical-align:top;
		padding-top:2px!important;
		padding-bottom:2px!important;
		// height:20px!important;
		// border-color:transparent!important;
		p {
			margin:4px 0;
		}
	}

	.k-associations-table-item-cell {
		padding:0px 6px;
		border-radius:8px;
		border:2px solid transparent;
		cursor:pointer;
	}

	.k-associations-table-item-cell:hover {
		text-decoration:underline;
	}

	.k-case-tree-associations-table-item-other-framework {
	}

}
</style>
