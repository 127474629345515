var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.expanded&&!_vm.minimized)?_c('div',{staticClass:"k-help-viewer-overlay-scrim"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"k-help-viewer-outer",attrs:{"role":"dialog","tabindex":-1,"aria-modal":"true","aria-labelledby":"user-guide-label"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('hide_help')}}},[_c('div',{staticClass:"k-help-viewer-title"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.minimized),expression:"!minimized"}],staticClass:"k-help-viewer-control-btn-holder",staticStyle:{"right":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.expanded&&!_vm.minimized),expression:"!expanded&&!minimized"}],staticClass:"k-help-viewer-control-btn elevation-0",staticStyle:{"margin-right":"2px"},attrs:{"color":"#333","fab":"","x-small":"","aria-label":_vm.show_toc_on_side ? 'Hide table of contents' : 'Show table of contents',"aria-expanded":_vm.show_toc_on_side ? 'true' : 'false'},on:{"click":function($event){_vm.show_toc_on_side=!_vm.show_toc_on_side}}},on),[_c('v-icon',{staticStyle:{"font-size":"15px!important"},attrs:{"large":"","color":"#fff"}},[_vm._v("fas fa-list-ul")])],1)]}}])},[_vm._v(_vm._s(_vm.show_toc_on_side ? 'Hide' : 'Show')+" user guide contents")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"k-help-viewer-control-btn elevation-0",staticStyle:{"margin-right":"2px"},attrs:{"color":"#333","fab":"","x-small":"","disabled":_vm.current_doc_index==0,"aria-label":"Back to the previously-viewed user guide page"},on:{"click":_vm.back}},on),[_c('v-icon',{staticStyle:{"font-size":"17px!important"},attrs:{"large":"","color":"#fff"}},[_vm._v("fas fa-arrow-left")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_vm._v("Back to the previously-viewed"),_c('br'),_vm._v("user guide page")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"k-help-viewer-control-btn elevation-0",attrs:{"color":"#333","fab":"","x-small":"","disabled":_vm.current_doc_index==_vm.queue.length-1,"aria-label":"Forward to the next-viewed user guide page"},on:{"click":_vm.forward}},on),[_c('v-icon',{staticStyle:{"font-size":"17px!important"},attrs:{"large":"","color":"#fff"}},[_vm._v("fas fa-arrow-right")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_vm._v("Forward to the next-viewed"),_c('br'),_vm._v("user guide page")])])],1),_vm._v(" "),_c('div',{staticClass:"k-help-viewer-control-btn-holder",staticStyle:{"left":"8px"}},[_c('v-btn',{staticClass:"k-help-viewer-control-btn elevation-0",staticStyle:{"margin-right":"2px"},attrs:{"color":"#333","aria-label":"Close user guide","fab":"","x-small":""},on:{"click":function($event){return _vm.$emit('hide_help')}}},[_c('v-icon',{staticStyle:{"font-size":"20px!important"},attrs:{"large":"","color":"#fff"}},[_vm._v("fas fa-xmark")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.minimized),expression:"!minimized"}],staticClass:"k-help-viewer-control-btn elevation-0",staticStyle:{"margin-right":"2px"},attrs:{"aria-label":_vm.expanded ? 'Move user guide to the side' : 'Expand user guide',"color":"#333","fab":"","x-small":""},on:{"click":_vm.toggle_size}},on),[_c('v-icon',{staticStyle:{"font-size":"16px!important"},attrs:{"small":"","color":"#fff"}},[_vm._v("fas "+_vm._s(_vm.expanded?'fa-compress':'fa-expand'))])],1)]}}])},[_vm._v(_vm._s(!_vm.expanded?'Expand user guide':'Move user guide to side'))]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":!_vm.minimized,"top":_vm.minimized},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"k-help-viewer-control-btn elevation-0",attrs:{"aria-label":_vm.minimized ? 'Show user guide' : 'Minimize user guide',"color":"#333","fab":"","x-small":""},on:{"click":_vm.toggle_minimize}},on),[_c('v-icon',{staticStyle:{"font-size":"16px!important"},attrs:{"small":"","color":"#fff"}},[_vm._v("fas "+_vm._s(_vm.minimized?'fa-angle-up':'fa-angle-down'))])],1)]}}])},[_vm._v(_vm._s(_vm.minimized?'Show user guide':'Minimize user guide'))])],1),_vm._v(" "),_c('div',{attrs:{"id":"user-guide-label"}},[_c('v-icon',{staticStyle:{"font-size":"18px","margin-top":"-2px","margin-right":"6px"},attrs:{"color":"#fff"}},[_vm._v("fas fa-circle-info")]),_vm._v("User Guide")],1)]),_vm._v(" "),_c('div',{staticClass:"k-help-viewer-body"},[_c('HelpTOC',{directives:[{name:"show",rawName:"v-show",value:(_vm.toc_showing),expression:"toc_showing"}],ref:"help_toc",attrs:{"current_doc":_vm.current_doc}}),_vm._v(" "),_c('div',{staticClass:"k-help-page"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-btn',_vm._g({staticClass:"k-help-link-btn",attrs:{"icon":"","small":"","color":"light-blue darken-3","aria-label":"Copy a link to this help doc"},on:{"click":function($event){$event.stopPropagation();return _vm.copy_link.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-link")])],1):_vm._e()]}}])},[_vm._v("Copy a link to this help doc")]),_vm._v(" "),_c(_vm.current_doc_component,{ref:"help_doc",tag:"component"})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }