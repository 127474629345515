<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable :fullscreen="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm" @keydown.esc="$emit('dialog_cancel')">
		<v-card>
			<v-card-text class="k-ctmi-text">
				<div class="k-ctmi-title">
					<div v-html="title"></div>
					<v-spacer></v-spacer>
					<div class="float-right mx-3"><CopyBtn :val="title_for_copy"/></div>
					<v-btn icon @click="show_help('more_info')" color="light-blue"><v-icon large>fas fa-info-circle</v-icon></v-btn>
					<v-btn class="ml-3" color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
				</div>

				<CASEJSONView :object_type="object_type" :object="flattened_object" :object_to_copy="flattened_object" />

				<div v-if="has_extended_field" class="mt-3 pa-1 mx-auto text-center grey lighten-3" style="max-width:800px; border-radius:8px">*Starred fields are CASE 1.1 or “extended CASE” properties, and will not be exported when accessing the CASE 1.0 REST APIs.<br>Extended properties <i>will</i> be exported as part of the CASE JSON file if you use the {{$store.state.site_config.app_name}} <nobr><a @click="show_help('case_rest_api')"><v-icon color="light-blue" class="mx-1" small>fas fa-info-circle</v-icon>CASE 1.1 or “Extended CASE” APIs</a></nobr>.</div>

				<div class="mt-3 text-center">
					<v-btn v-show="!show_delta" small color="secondary" class="k-tight-btn" @click="show_json=!show_json"><v-icon small class="mr-2">fas fa-code</v-icon>{{show_json?'Hide':'Show'}} {{(object_type=='document')?'Document':''}} JSON</v-btn>
					<CopyBtn v-show="show_json" :val="json" class="ml-2"/>
					<v-btn v-show="!show_delta" v-if="object_type=='document'" small class="ml-3 k-tight-btn" color="secondary" @click="$emit('show_framework_json')"><v-icon small class="mr-2">fas fa-code</v-icon>Show Framework JSON</v-btn>
					<v-btn v-if="enable_archive_access" v-show="!show_json" small color="secondary" class="ml-3 k-tight-btn" @click="show_delta=!show_delta"><span class="mr-1" style="font-size:18px">Δ</span>{{show_delta?'Hide':'Show'}} Change History</v-btn>
				</div>
				
				<v-expand-transition><div v-show="show_json" class="k-ctmi-json elevation-3 mt-2" v-html="json"></div></v-expand-transition>
				<v-expand-transition><CASEEntityHistory v-if="show_delta" :object_type="object_type" :object="object" :framework_record="framework_record" @dialog_cancel="$emit('dialog_cancel')" /></v-expand-transition>

			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CopyBtn from '../utilities/CopyBtn'
import CASEJSONView from './CASEJSONView'
import CASEEntityHistory from './CASEEntityHistory'

export default {
	components: { CopyBtn, CASEJSONView, CASEEntityHistory },
	props: {
		object_type: { type: String, required: true },
		object: { type: Object, required: true },
		framework_record: { type: Object, required: true },
		show_delta_at_start: { type: Boolean, required: false, default() { return false} },
	},
	data() { return {
		dialog_open: true,
		show_json: (this.object_type != 'item' && this.object_type != 'document'),
		show_delta: false,
	}},
	computed: {
		...mapState(['user_info',]),
		...mapGetters(['enable_archive_access']),
		flattened_object() { return U.flatten_extensions(this.object) },
		title() {
			if (this.object_type == 'item') {
				if (!empty(this.object.humanCodingScheme)) return sr('<b class="mr-1">$1</b> $2', this.object.humanCodingScheme, this.object.fullStatement)
				else return sr('<b class="mr-1">$1</b>', this.object.fullStatement)
			} else if (this.object_type == 'document') {
				return sr('<b class="mr-1">$1</b>', this.object.title)
			}
			return sr('<b class="mr-1">$1</b>', U.capitalize_word(this.object_type))
		},
		title_for_copy() { return $(sr('<div>$1</div>', this.title)).text() },
		has_extended_field() {
			if (U.object_has_keys(this.object.extensions)) return true
			let exts = (this.object_type == 'item') ? CFItem.fields.case_1_1_fields : CFDocument.fields.case_1_1_fields
			for (let key in this.object) {
				if (!empty(exts[key])) return true
			}
			return false
		},
		json() {
			return JSON.stringify(this.object, null, 2).replace(/</g, '&lt;')
		},
	},
	watch: {
	},
	created() {
		console.log('more_info component created')
		this.show_delta = this.show_delta_at_start
	},
	mounted() {
	},
	methods: {
		show_help(doc) { vapp.show_help(doc) },
	}
}
</script>

<style lang="scss">
.k-ctmi-text {
	font-size:14px;
	line-height:19px;
	color:#000;
	padding:12px!important;
	// margin:0;
	li {
		margin-top:4px;
		margin-bottom:4px;
	}
}

.k-ctmi-title {
	display:flex;
	font-size:18px!important;
	line-height:24px;
	border-bottom:1px solid #999;
	padding-bottom:8px;
	margin-bottom:8px;
}

.k-ctmi-json {
	white-space: pre-wrap;
	font-size: 12px;
	line-height: 15px;
	font-family: monospace;
	padding:8px;
	border-radius:6px;
}

</style>
