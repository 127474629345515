<!-- Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="95vw" persistent scrollable @keydown.esc="$emit('dialog_cancel')">
	<v-card class="orange lighten-5">
		<v-card-title style="border-bottom:1px solid #999">
			<v-icon color="deep-orange darken-4" class="mr-3">fas fa-bolt-lightning</v-icon>
			<b class="deep-orange--text text--darken-4">Framework Update Report</b>
			<v-btn v-show="return_to_report_value" class="ml-10 mr-3" fab small color="primary" @click="return_to_report" aria-label="Return to report"><v-icon>fas fa-arrow-left</v-icon></v-btn>
			<div style="flex:1 0 auto"><v-autocomplete v-if="report_type!='update_type'" v-model="framework_identifier" :items="framework_options" style="font-weight:bold; font-size:18px;" outlined dense hide-details :class="framework_color_class(this.framework_identifier)"><template v-slot:label><span style="font-weight:normal">Framework</span></template></v-autocomplete></div>
			<v-btn fab small color="secondary" @click="$emit('dialog_cancel')" style="margin:0px -12px 0 12px" aria-label="Close Framework Update Report"><v-icon>fas fa-times</v-icon></v-btn>
		</v-card-title>
		<v-card-text class="mt-2" style="font-size:16px; color:#000;">
			<div v-if="report_type=='update_type'||report_type=='framework_archive_compare'" class="d-flex align-center">
				<v-menu right nudge-top="-30"><template v-slot:activator="{on}"><v-btn small color="primary" class="k-tight-btn" v-on="on">Update types to show <v-icon small class="ml-2">fas fa-caret-down</v-icon></v-btn></template>
					<v-list dense min-width="250">
						<v-list-item @click.stop=""><v-checkbox @click="update_update_types" class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="update_types_items_all"></v-checkbox><b class="mr-2">Items:</b> <span style="font-size:0.85em">(standards, elements, grade levels, “folders”, etc.)</span></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'Updates to statement or human-readable code for existing items'" v-model="update_types.item_updates_code_or_statement" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'Updates to other properties for existing items'" v-model="update_types.item_updates_not_code_or_statement" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'Existing items moved'" v-model="update_types.item_updates_moved" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'New items added'" v-model="update_types.items_created" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'Items deleted'" v-model="update_types.items_deleted" hide-details></v-checkbox></v-list-item-title></v-list-item>

						<v-list-item @click.stop=""><v-checkbox @click="update_update_types" class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="update_types_assocs_all"></v-checkbox><b class="mr-2">Associations:</b> <span style="font-size:0.85em">(excluding “isChildOf”)</span></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'Updates to existing associations'" v-model="update_types.assocs_updated" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'New associations added'" v-model="update_types.assocs_created" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0 ml-7" :label="'Associations deleted'" v-model="update_types.assocs_deleted" hide-details></v-checkbox></v-list-item-title></v-list-item>
						
						<v-list-item @click.stop=""><b class="mr-2">Document:</b></v-list-item>
						<v-list-item @click.stop=""><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0" :label="'Updates to document properties'" v-model="update_types.document_updated" hide-details></v-checkbox></v-list-item-title></v-list-item>
						<v-list-item v-show="report_type=='framework_archive_compare'" @click.stop="" class="pt-1 mt-1" style="border-top:1px solid #ccc"><v-list-item-title><v-checkbox @click="update_update_types" class="mt-0 pt-0" :label="'Include entities with date changes only'" v-model="update_types.dates_only" hide-details></v-checkbox></v-list-item-title></v-list-item>
						
					</v-list>
				</v-menu>
				<div style="font-size:14px" v-html="update_type_options_display"></div>
			</div>
			<div style="clear:both">
				<UpdateReportByUpdateType v-if="report_type=='update_type'&&initialized" :parent_component="this" :framework_records="filtered_framework_records" :ref_dates="ref_dates" :update_records="update_records" @show_framework_report="show_framework_report" />
				<UpdateReportByFramework v-if="report_type=='framework'&&initialized" :parent_component="this" :framework_records="filtered_framework_records" :ref_dates="ref_dates" :update_records="update_records" :framework_identifier="framework_identifier" />
				<UpdateReportFrameworkArchives v-if="report_type=='framework_all_archives'&&initialized" :parent_component="this" :framework_records="filtered_framework_records" :update_records="update_records" :framework_identifier="framework_identifier" />
				<UpdateReportArchiveCompare v-if="report_type=='framework_archive_compare'&&initialized" :parent_component="this" :framework_records="filtered_framework_records" :update_records="update_records" :framework_identifier="framework_identifier" />
			</div>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UpdateReportByUpdateType from './UpdateReportByUpdateType'
import UpdateReportByFramework from './UpdateReportByFramework'
import UpdateReportFrameworkArchives from './UpdateReportFrameworkArchives'
import UpdateReportArchiveCompare from './UpdateReportArchiveCompare'

export default {
	components: { UpdateReportByUpdateType, UpdateReportByFramework, UpdateReportFrameworkArchives, UpdateReportArchiveCompare },
	props: {
	},
	data() { return {
		dialog_open: true,
		initialized: false,
		ref_dates: {},
		update_records: {},
		report_type_options: [
			{value: 'update_type', text: 'All Frameworks'},
			{value: 'framework', text: 'Single Framework: Updates Summary'},
			{value: 'framework_all_archives', text: 'Single Framework: All Archives'},
			{value: 'framework_archive_compare', text: 'Single Framework: Archive Comparison'},
			// {value: 'update_type', text: 'A: Combine update types; all frameworks; in past day, week, month, etc.'},
			// {value: 'framework', text: 'B: Separate update types; one framework; in past day, week, month, etc.'},
			// {value: 'date', text: 'C: Separate update types; all frameworks; since a designated date'},
		],
		return_to_report_value: '',
	}},
	computed: {
		...mapState(['framework_records', 'framework_archives']),
		...mapGetters([]),
		report_type: {
			get() { return this.$store.state.lst.update_report_type },
			set(val) { this.$store.commit('lst_set', ['update_report_type', val]) }
		},
		framework_identifier: {
			get() { return this.$store.state.lst.update_report_by_framework_identifier },
			set(val) { 
				this.$store.commit('lst_set', ['update_report_by_framework_identifier', val]) 
				if (val) {
					this.$store.commit('set', ['last_framework_update_report', val])
				}
			}
		},
		archive_compare_arr: {
			get() { return this.$store.state.lst.update_report_archive_compare_arr },
			set(val) { this.$store.commit('lst_set', ['update_report_archive_compare_arr', val]) }
		},
		update_types() { return this.$store.state.lst.update_report_by_update_update_types },
		update_types_items_all: {
			get() { return (this.update_types.item_updates_code_or_statement && this.update_types.item_updates_not_code_or_statement && this.update_types.item_updates_moved && this.update_types.items_created && this.update_types.items_deleted) },
			set(val) {
				if (val == true) {
					this.update_types.item_updates_code_or_statement = true
					this.update_types.item_updates_not_code_or_statement = true
					this.update_types.item_updates_moved = true
					this.update_types.items_created = true
					this.update_types.items_deleted = true
				} else {
					this.update_types.item_updates_code_or_statement = false
					this.update_types.item_updates_not_code_or_statement = false
					this.update_types.item_updates_moved = false
					this.update_types.items_created = false
					this.update_types.items_deleted = false
				}
			}
		},
		update_types_assocs_all: {
			get() { return (this.update_types.assocs_updated && this.update_types.assocs_created && this.update_types.assocs_deleted) },
			set(val) {
				if (val == true) {
					this.update_types.assocs_updated = true
					this.update_types.assocs_created = true
					this.update_types.assocs_deleted = true
				} else {
					this.update_types.assocs_updated = false
					this.update_types.assocs_created = false
					this.update_types.assocs_deleted = false
				}
			}
		},
		update_type_options_display() {
			let s = ''

			if (this.update_types.item_updates_not_code_or_statement || this.update_types.item_updates_code_or_statement || this.update_types.item_updates_moved || this.update_types.items_created || this.update_types.items_deleted) {
				s += '<b class="ml-3">Items:</b> '
				if (this.update_types.item_updates_code_or_statement && this.update_types.item_updates_not_code_or_statement && this.update_types.item_updates_moved) {
					s += ' updated (all updates)'
				} else if (this.update_types.item_updates_code_or_statement || this.update_types.item_updates_not_code_or_statement || this.update_types.item_updates_moved) {
					s += ' updated ('
					if (this.update_types.item_updates_code_or_statement) {
						if (this.update_types.item_updates_not_code_or_statement) s += 'all properties'
						else s += 'code/statement'
					} else if (this.update_types.item_updates_not_code_or_statement) {
						s += 'other properties'
					}
					if (this.update_types.item_updates_moved) {
						if (this.update_types.item_updates_code_or_statement || this.update_types.item_updates_not_code_or_statement) s += ' + '
						s += 'moved'
					}
					s += ')'
				}
				
				if (this.update_types.items_created) {
					if (this.update_types.item_updates_not_code_or_statement || this.update_types.item_updates_code_or_statement || this.update_types.item_updates_moved) s += ' + '
					s += 'created'
				}

				if (this.update_types.items_deleted) {
					if (this.update_types.item_updates_not_code_or_statement || this.update_types.item_updates_code_or_statement || this.update_types.item_updates_moved || this.update_types.items_created) s += ' + '
					s += 'deleted'
				}
			}
			if (this.update_types.assocs_updated || this.update_types.assocs_created || this.update_types.assocs_deleted) {
				s += ' '
				s += '<b class="ml-3">Associations:</b> '
				if (this.update_types.assocs_updated) s += 'updated'
				if (this.update_types.assocs_created) {
					if (this.update_types.assocs_updated) s += ' + '
					s += 'created'
				}
				if (this.update_types.assocs_deleted) {
					if (this.update_types.assocs_updated || this.update_types.assocs_created) s += ' + '
					s += 'deleted'
				}
			}
			if (this.update_types.document_updated) {
				s += ' '
				s += '<b class="ml-3">Document:</b> updated'
			}
			return s
		},
		framework_options() {
			let arr = []
			for (let fr of this.filtered_framework_records) {
				if (!fr.json?.CFDocument) continue
				let title = fr.json.CFDocument.title
				if (fr.ss_framework_data.category.search(/\[\[(.*?)\]\]$/) > -1) {
					title = RegExp.$1 + ': ' + title
				}
				arr.push({value: fr.lsdoc_identifier, text: title})
			}
			arr.sort((a,b) => { return U.natural_sort(a.text, b.text) })
			return arr
		},
		filtered_framework_records() {
			let arr = []
			for (let fr of this.framework_records) {
				// skip crosswalks and sandboxes
				if (fr.json.CFDocument?.frameworkType == 'crosswalk') continue
				if (!empty(fr.ss_framework_data.sandboxOfIdentifier)) {
					// exception: if a sandbox is currently open, allow that sandbox to be an option, so we can get to its archives
					if (vapp.case_tree_component?.lsdoc_identifier != fr.lsdoc_identifier) {
						continue
					}
				}
				arr.push(fr)
			}

			return arr
		},
	},
	watch: {
		framework_identifier() {
			// if framework_identifier changes when we're viewing an archive comparison report, have to go back to the framework report
			if (this.report_type == 'framework_archive_compare') {
				this.report_type = 'framework'
				this.return_to_report_value = 'update_type'
			}
		},
	},
	created() {
	},
	mounted() {
		vapp.framework_update_report = this
		if (this.report_type != 'update_type') this.return_to_report_value = 'update_type'
		this.initialize()
	},
	methods: {
		initialize() {
			console.log('UpdateReport initialize', new Date().getTime(), this.$store.state.update_records_timestamp)
			// if we pulled update_records less than 5 minutes ago, re-use them
			if ((new Date().getTime() - this.$store.state.update_records_timestamp) < 5*60*1000) {
				this.update_records = this.$store.state.update_records
				this.ref_dates = this.$store.state.update_record_ref_dates
				this.initialized = true
			} else {
				let payload = {}
				U.loading_start()
				U.ajax('get_framework_update_data', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in admin ajax call'); vapp.ping(); return;
					}

					console.log(result)

					for (let key in result.ref_dates) {
						// clean up ref_dates
						result.ref_dates[key] = this.format_date(result.ref_dates[key])
					}

					for (let framework_identifier in result.update_records) {
						let ur = result.update_records[framework_identifier]
						for (let key in ur) {
							if (key == 'framework_record_created_at') continue
							if (ur[key] == '-') continue
							if (empty(ur[key]['update_report_summary'])) {
								// if update_report_summary isn't saved for the archive, set to '-'; this shouldn't happen once we're dynamically updating archives
								ur[key] = '-'
							} else {
								// json parse the update_report_summaries
								ur[key]['update_report_summary'] = JSON.parse(ur[key]['update_report_summary'])
							}
						}
					}

					// set data in store, then copy to component's data
					this.$store.commit('set', ['update_records_timestamp', new Date().getTime()])
					this.$store.commit('set', ['update_records', result.update_records])
					this.$store.commit('set', ['update_record_ref_dates', result.ref_dates])

					this.update_records = this.$store.state.update_records
					this.ref_dates = this.$store.state.update_record_ref_dates

					this.initialized = true
				})
			}
		},

		update_update_types() {
			// deal with dependencies; have to do this in a timeout so that the check action is "registered" first
			setTimeout(()=>{
				let o = extobj(this.update_types)
				this.$store.commit('lst_set', ['update_report_by_update_update_types', o])
			})
		},

		format_date(date_string, flag) {
			// TODO: have to convert from GMT to local time...
			// 2023-05-22T05:00:00+00:00
			date_string = U.convert_to_local_date(date_string, -1)
			if (flag == 'time') return date.format(date_string, 'YYYY-MM-DD HH:mm')
			else return date.format(date_string, 'YYYY-MM-DD')
		},

		show_framework_report(framework_identifier, report_type) {
			this.$store.commit('lst_set', ['update_report_by_framework_identifier', framework_identifier])
			this.$store.commit('set', ['last_framework_update_report', framework_identifier])

			// if we didn't receive a report_type, or we recieved 'framework', jump to the framework report
			if (empty(report_type) || report_type == 'framework') {
				this.report_type = 'framework'
				this.return_to_report_value = 'update_type'

			// else if we get one of the other framework-specific report values, show it
			} else if (report_type == 'framework_all_archives' || report_type == 'framework_archive_compare') {
				this.report_type = report_type
				this.return_to_report_value = 'framework'
			}
			// if a report_type of, e.g., 'last', is sent in, we'll show whatever report we were last showing
		},

		view_framework($event, framework_identifier, viewer_post_load_execute_fn) {
			this.$store.commit('set', ['last_framework_update_report', framework_identifier])
			if (!framework_identifier) framework_identifier = this.framework_identifier

			// if user holds meta/ctrl key down, open in a new tab/window
			if (U.meta_or_alt_key($event)) {
				let url = `/${framework_identifier}/${framework_identifier}`
				window.open(url)
			} else {
				// else use vapp.open_framework_then to execute the fn if we get one
				vapp.open_framework_then(framework_identifier, viewer_post_load_execute_fn)
			}
			this.hide_report()
		},

		view_framework_archives(framework_identifier) {
			if (!framework_identifier) framework_identifier = this.framework_identifier
			vapp.open_framework_then(framework_identifier, ()=>{
				this.framework_identifier = framework_identifier
				this.report_type = 'framework_all_archives'
			})
		},

		track_changes_to_archive(framework_identifier, archive_fn) {
			this.view_framework(null, framework_identifier, ()=>vapp.case_tree_component.track_changes(archive_fn, '', ''))
		},

		compare_to_archive(framework_identifier, archive_compare_arr) {
			if (!framework_identifier) framework_identifier = this.framework_identifier
			vapp.open_framework_then(framework_identifier, ()=>{
				this.archive_compare_arr = archive_compare_arr
				this.framework_identifier = framework_identifier
				this.report_type = 'framework_archive_compare'
			})
		},

		return_to_report() {
			this.report_type = this.return_to_report_value
			if (this.report_type != 'update_type') this.return_to_report_value = 'update_type'
			else this.return_to_report_value = ''
		},

		hide_report() {
			this.$emit('dialog_cancel')
		},

		framework_color_class(framework_identifier) {
			return U.framework_color(framework_identifier)
		},
	}
}
</script>

<style lang="scss">
.k-framework-updates-table {
	border-radius:12px;
	border:1px solid $v-orange;
	th {
		white-space:nowrap;
		background-color:$v-orange-lighten-4-5;
		color:#000!important;
	}
	td {
		font-size:12px!important;
		line-height:16px;
		vertical-align:top;
		padding-top:2px!important;
		padding-bottom:2px!important;
		// border-color:transparent!important;
	}
}

.k-framework-updates-table-th-left {
	border-top-left-radius:12px;
}
.k-framework-updates-table-th-right {
	border-top-right-radius:12px;
}

.k-framework-updates-table-header {
	th {
		padding:4px 6px!important;
		height:12px!important;
		font-size:12px!important;
		line-height:1.2em!important;
		vertical-align:bottom;
		font-weight:normal;
		cursor:pointer;
	}
	th.k-sorted-col {
		font-weight:bold;
	}
}

</style>
