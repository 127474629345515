<!-- Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams -->
<template><div>
	<div v-if="initialized" style="margin:8px -8px 0 -8px">
		<div class="text-center mb-3">
			<div class="orange lighten-3" style="padding:4px 8px; display:inline-block; border-radius:8px;">
				Updates between <b>{{archive_local_date}}</b> and <b>current framework</b>{{archive_note}}
				<div v-if="archive_last_change_date" v-html="archive_last_change_date"></div>
			</div>
		</div>
		<v-data-table v-if="framework_identifier" light dense 
			:headers="headers" 
			hide-default-header 
			:items="table_rows" 
			:custom-filter="table_search_filter"
			:search="search"
			:footer-props="footer_options" 
			class="k-framework-updates-table"
		>
			<template v-slot:no-data><div style="color:#000; font-size:16px; padding:8px 0">
				<div v-if="total_diffs==0">This archive is identical to the current version of the framework.</div>
				<div v-else>No updates match your UPDATE TYPES filters and/or search terms.</div>
			</div></template>
			<template v-slot:header><thead>
				<tr>
					<th scope="col" class="k-framework-updates-table-th-left"><div class="d-flex align-center">
						<div>Item/Association/Document</div>
						<v-text-field class="my-1 ml-3" style="font-weight:normal; font-size:12px; width:320px;" v-model="search" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" label="Search" outlined single-line hide-details dense background-color="#fff"></v-text-field>
						<v-spacer/>
						<span style="font-weight:normal" class="mr-2">(click for details)</span>
						<!-- <v-spacer/> -->
						<div>Update(s)</div>
					</div></th>
					<th scope="col" class="text-center">Current Date</th>
					<th scope="col" class="text-center">Archived Date</th>
					<th scope="col" class="text-center k-framework-updates-table-th-right"></th>
				</tr>
			</thead></template>
			<template v-slot:item="{ item }">
				<tr :class="main_row_css_class(item)" @mouseover="set_row_hovering(item, true)" @mouseleave="set_row_hovering(item, false)">
					<td style="cursor:pointer; padding-top:7px!important;" @click="show_details(item)" @keydown.enter="show_details(item)" :tabindex="0" :aria-expanded="showing_details[item.entity.identifier] == 'yes' ? 'true' : 'false'">
						<div class="d-flex">
							<v-icon small class="mr-2">{{item.entity_icon}}</v-icon>
							<div v-if="item.entity_type=='item'" class="d-flex"><b v-if="item.entity.humanCodingScheme" class="mr-1">{{item.entity.humanCodingScheme}}</b><div v-html="format_statement(item.entity.fullStatement)"></div></div>
							<div v-else v-html="item.formatted_statement"></div>
							<v-spacer/>
							<div v-html="item.update_type"></div>
							<v-icon small class="ml-2" :color="item.update_color">{{item.update_icon}}</v-icon>
						</div>
					</td>
					<td class="text-center"><div class="d-flex align-center">
						<v-spacer/>
						<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-html="format_date(item.current_date)"></div></template>Current version last changed: <span v-html="format_date(item.current_date,'time')"></span></v-tooltip>
						<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-visible="item.update_type!='DELETED'" v-on="on" icon small color="orange darken-3" @click="view_item('current', item)" aria-label="Show this item in the current framework’s tree view"><v-icon small>fas fa-tree</v-icon></v-btn></template>Show this item in the current framework’s tree view</v-tooltip>
						<v-spacer/>
					</div></td>
					<td class="text-center"><div class="d-flex align-center">
						<v-spacer/>
						<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-html="format_date(item.archived_date)"></div></template>Archived version last changed: <span v-html="format_date(item.archived_date,'time')"></span></v-tooltip>
						<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-visible="item.update_type!='CREATED'" v-on="on" icon small color="orange darken-3" dark @click="view_item('archive', item)" aria-label="Show this item in the current framework’s tree view"><v-icon small>fas fa-tree</v-icon></v-btn></template>Show this item in the archived framework’s tree view</v-tooltip>
						<v-spacer/>
					</div></td>
					<td class="px-0"><nobr>
						<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="item.update_type!='DELETED'&&item.entity_type!='assoc'" icon small color="#666" @click="show_elements_json(item)"><v-icon small>fas fa-code</v-icon></v-btn></template>View item metadata</v-tooltip>
					</nobr></td>
				</tr>
				<tr v-if="showing_details[item.entity.identifier]" v-show="showing_details[item.entity.identifier]=='yes'" :class="details_row_css_class(item)" @mouseover="set_row_hovering(item, true)" @mouseleave="set_row_hovering(item, false)">
					<td colspan="5"><div v-show="showing_details[item.entity.identifier]=='yes'" class="ml-6 mb-1">
						<div class="mb-1" v-if="item.entity_type=='item'&&item.update_type.includes('MOVED')"><span class="k-urac-bullet">•</span> Item has been <b class="blue--text">MOVED</b> since the archived date ({{format_date(item.archived_date)}})</div>
						<div class="mb-1" v-if="item.entity_type=='item'&&item.update_type.includes('UPDATED')">
							<div><span class="k-urac-bullet">•</span> Item has been <b class="blue--text">UPDATED</b> since the archived date (item last updated {{format_date(item.current_date)}}):</div>
							<div v-for="(diff_arr, key) in item.comp.diffs" :key="key" v-html="diff_html(key, diff_arr)"></div>
						</div>
						<div class="mb-1" v-if="item.entity_type=='item'&&item.update_type=='CREATED'"><span class="k-urac-bullet">•</span> Item has been <b class="purple--text">CREATED</b> since the archived date (item created {{format_date(item.current_date)}})</div>
						<div class="mb-1" v-if="item.entity_type=='item'&&item.update_type=='DELETED'"><span class="k-urac-bullet">•</span> Item has been <b class="red--text">DELETED</b> since the archived date ({{format_date(item.archived_date)}})</div>
	
						<div class="mb-1" v-if="item.entity_type=='assoc'&&item.update_type.includes('UPDATED')">
							<div><span class="k-urac-bullet">•</span> Association has been <b class="blue--text">UPDATED</b> since the archived date (association last updated {{format_date(item.current_date)}}):</div>
							<div v-for="(diff_arr, key) in item.comp.diffs" :key="key" v-html="diff_html(key, diff_arr)"></div>
						</div>
						<div class="mb-1" v-if="item.entity_type=='assoc'&&item.update_type=='CREATED'"><span class="k-urac-bullet">•</span> Association has been <b class="purple--text">CREATED</b> since the archived date (association created {{format_date(item.current_date)}})</div>
						<div class="mb-1" v-if="item.entity_type=='assoc'&&item.update_type=='DELETED'"><span class="k-urac-bullet">•</span> Association has been <b class="red--text">DELETED</b> since the archived date ({{format_date(item.archived_date)}})</div>

						<div class="mb-1" v-if="item.entity_type=='doc'&&item.update_type.includes('UPDATED')">
							<div><span class="k-urac-bullet">•</span> Document has been <b class="blue--text">UPDATED</b> since the archived date (document last updated {{format_date(item.current_date)}}):</div>
							<div v-for="(diff_arr, key) in item.comp.diffs" :key="key" v-html="diff_html(key, diff_arr)"></div>
						</div>
						<div class="mb-1" v-if="item.entity_type=='doc'&&item.comp.dates&&item.comp.dates.length==2">
							<div><span class="k-urac-bullet">•</span> Document’s lastChangeDateTime has changed between the archive ({{item.comp.dates[0]}}) and the current framework ({{item.comp.dates[0]}})</div>
						</div>
					</div></td>
				</tr>
			</template>
		</v-data-table>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: { },
	props: {
		framework_records: { required: true },
		framework_identifier: { type: String, required: true },
		update_records: { type: Object, required: true },
		parent_component: { required: true },
	},
	data() { return {
		initialized: false,
		footer_options: {
			itemsPerPageOptions: [50,100,-1],
		},
		comps: null,
		this_lastChangeDateTime: '',
		last_lastChangeDateTime: '',
		showing_details: {},
		hovering: {},
		search: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		search_re() {
			let s = $.trim(this.search)
			if (!s) return ''
			return new RegExp(s.toLowerCase(), 'i')
		},
		framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.framework_identifier) },
		update_record() { return this.update_records[this.framework_identifier] },
		update_types() { return this.parent_component.update_types },
		archive_compare_arr() { return this.parent_component.archive_compare_arr },
		// NOTE: Currently, archive_cmpare_arr[0] is always 'current', and archive_compare_arr[1] is always an object that includes properties (at least) archive_fn, archive_note, and (timestamp or archive_timestamp)
		archive_local_date() {
			let s = ''
			if (this.archive_compare_arr[1].archive_timestamp) s = 'archive saved ' + this.format_date(this.archive_compare_arr[1].archive_timestamp)
			else if (this.archive_compare_arr[1].timestamp) s = 'archive saved ' + this.format_date(this.archive_compare_arr[1].timestamp)
			return s
		},
		archive_last_change_date() {
			if (this.archive_compare_arr[1].archive_lastChangeDateTime) return `(archive last change date/time: ${this.format_date(this.archive_compare_arr[1].archive_lastChangeDateTime, 'time')})`
			else return ''
		},
		archive_note() {
			if ((this.archive_compare_arr[1].archive_note ?? '--') != '--') return ': ' + this.archive_compare_arr[1].archive_note
			else return ''
		},
		total_diffs() {
			if (!this.comps) return 0

			// including dates only
			let s = 0
			if (this.comps.CFItems.updated) s += this.comps.CFItems.updated.length
			if (this.comps.CFItems.created) s += this.comps.CFItems.created.length
			if (this.comps.CFItems.deleted) s += this.comps.CFItems.deleted.length
			if (this.comps.CFAssociations.updated) s += this.comps.CFAssociations.updated.length
			if (this.comps.CFAssociations.created) s += this.comps.CFAssociations.created.length
			if (this.comps.CFAssociations.deleted) s += this.comps.CFAssociations.deleted.length
			if (this.comps.CFDocument && (this.comps.CFDocument.diffs || this.comps.CFDocument.dates)) s += 1
			return s
		},
		headers() {
			return [
				{ text: 'Item/Association/Document', align: 'left', sortable: false, value:'statement' },
				{ text: 'Current Date', align: 'left', sortable: false, value:'title' },
				{ text: 'Archived Date', align: 'left', sortable: false, value:'title' },
				{ text: 'Actions', align: 'center', sortable: false, value:'title' },
			]
		},
		table_rows() {
			if (!this.framework_record) return []
			let arr = []

			let get_comp_dates = (comp) => {
				if (!comp.dates) {
					return [this.this_lastChangeDateTime, this.last_lastChangeDateTime]
				} else {
					return comp.dates
				}
			}

			/////////////////////////////
			// updated items that existed before and still exist now
			if ((this.update_types.item_updates_not_code_or_statement || this.update_types.item_updates_code_or_statement || this.update_types.item_updates_moved) && this.comps.CFItems?.updated) for (let comp of this.comps.CFItems.updated) {
				let update_keys = []
				let update_keys_for_search = []

				if (this.update_types.item_updates_moved && comp.tree_position) update_keys.push('MOVED')

				if ($.isArray(comp.diffs)) {
					comp.diffs = null
				} else if (this.update_types.item_updates_not_code_or_statement || this.update_types.item_updates_code_or_statement) {
					for (let field in comp.diffs) {
						// if we're not showing code/statement updates and the field is statement or code, skip
						if (!this.update_types.item_updates_code_or_statement && (field == 'fullStatement' || field == 'humanCodingScheme')) continue
						// if we're not showing other updates and field *isn't* statement or code, skip
						if (!this.update_types.item_updates_not_code_or_statement && !(field == 'fullStatement' || field == 'humanCodingScheme')) continue

						update_keys_for_search.push(field)
						if (!update_keys.includes('UPDATED')) update_keys.push('UPDATED')
					}
				}

				if (comp.dates?.length == 2 && this.update_types.dates_only) {
					if (update_keys.length == 0) {
						update_keys_for_search.push('DATES ONLY')
						update_keys.push('DATES ONLY')
					}
				}

				if (update_keys.length == 0) continue

				let item = this.framework_record.cfo.cfitems[comp.identifier]

				let [current_date, archived_date] = get_comp_dates(comp)
				arr.push({
					entity_type: 'item',
					entity_icon: 'fas fa-dot-circle',
					update_icon: 'fas fa-circle-half-stroke',
					update_color: 'blue',
					comp: comp,
					entity: item,
					update_type: update_keys.join(', '),
					search_string: (item.identifier + ' ' + update_keys.join(' ') + ' ' + update_keys_for_search.join(' ') + ' ' + (item.humanCodingScheme ? item.humanCodingScheme : '') + ' ' + item.fullStatement).toLowerCase(),
					current_date: current_date,
					archived_date: archived_date,
				})
			}

			/////////////////////////////
			// items created since the archive
			if (this.update_types.items_created && this.comps.CFItems?.created) for (let comp of this.comps.CFItems.created) {
				let item = this.framework_record.cfo.cfitems[comp.identifier]
				let [current_date, archived_date] = [item.lastChangeDateTime, '–']
				arr.push({
					entity_type: 'item',
					entity_icon: 'fas fa-dot-circle',
					update_icon: 'fas fa-circle-plus',
					update_color: 'purple',
					comp: comp,
					entity: item,
					update_type: 'CREATED',
					search_string: (item.identifier + ' ' + (item.humanCodingScheme ? item.humanCodingScheme : '') + ' ' + item.fullStatement).toLowerCase(),
					current_date: current_date,
					archived_date: archived_date,
				})
			}

			/////////////////////////////
			// items deleted since the archive
			if (this.update_types.items_deleted && this.comps.CFItems?.deleted) for (let comp of this.comps.CFItems.deleted) {
				let item = comp
				let [current_date, archived_date] = ['–', item.lastChangeDateTime]
				arr.push({
					entity_type: 'item',
					entity_icon: 'fas fa-dot-circle',
					update_icon: 'fas fa-circle-xmark',
					update_color: 'red',
					comp: comp,
					entity: item,
					update_type: 'DELETED',
					search_string: (item.identifier + ' ' + (item.humanCodingScheme ? item.humanCodingScheme : '') + ' ' + item.fullStatement).toLowerCase(),
					current_date: current_date,
					archived_date: archived_date,
				})
			}

			/////////////////////////////
			// updated associations
			if (this.update_types.assocs_updated && this.comps.CFAssociations?.updated) for (let comp of this.comps.CFAssociations.updated) {
				let update_keys = []
				let update_keys_for_search = []
				if ($.isArray(comp.diffs)) {
					comp.diffs = null
				} else for (let field in comp.diffs) {
					update_keys_for_search.push(field)
					if (!update_keys.includes('UPDATED')) update_keys.push('UPDATED')
				}

				if (comp.dates?.length == 2 && this.update_types.dates_only) {
					if (update_keys.length == 0) {
						update_keys_for_search.push('DATES ONLY')
						update_keys.push('DATES ONLY')
					}
				}

				if (update_keys.length == 0) continue

				let assoc = this.framework_record.json.CFAssociations.find(x=>x.identifier == comp.identifier)
				let [current_date, archived_date] = get_comp_dates(comp)
				let statement = this.assoc_statement(assoc)
				arr.push({
					entity_type: 'assoc',
					entity_icon: 'fas fa-arrows-alt-h',
					update_icon: 'fas fa-circle-half-stroke',
					update_color: 'blue',
					comp: comp,
					entity: assoc,
					update_type: update_keys.join(', '),
					search_string: (assoc.identifier + ' ' + update_keys.join(' ') + ' ' + update_keys_for_search.join(' ') + ' ' + statement).toLowerCase(),
					formatted_statement: `<b style="color:#666">Association:</b> ${statement}`,
					current_date: current_date,
					archived_date: archived_date,
				})
			}

			/////////////////////////////
			// created associations
			if (this.update_types.assocs_created && this.comps.CFAssociations?.created) for (let comp of this.comps.CFAssociations.created) {
				let assoc = this.framework_record.json.CFAssociations.find(x=>x.identifier == comp.identifier)
				let [current_date, archived_date] = [assoc.lastChangeDateTime, '–']
				let statement = this.assoc_statement(assoc)
				arr.push({
					entity_type: 'assoc',
					entity_icon: 'fas fa-arrows-alt-h',
					update_icon: 'fas fa-circle-plus',
					update_color: 'purple',
					comp: comp,
					entity: assoc,
					update_type: 'CREATED',
					search_string: (assoc.identifier + ' ' + statement).toLowerCase(),
					formatted_statement: `<b style="color:#666">Association:</b> ${statement}`,
					current_date: current_date,
					archived_date: archived_date,
				})
			}

			/////////////////////////////
			// deleted associations
			if (this.update_types.assocs_deleted && this.comps.CFAssociations?.deleted) for (let comp of this.comps.CFAssociations.deleted) {
				let assoc = comp
				let [current_date, archived_date] = [assoc.lastChangeDateTime, '–']
				let statement = this.assoc_statement(assoc)
				arr.push({
					entity_type: 'assoc',
					entity_icon: 'fas fa-arrows-alt-h',
					update_icon: 'fas fa-circle-xmark',
					update_color: 'red',
					comp: comp,
					entity: assoc,
					update_type: 'DELETED',
					search_string: (assoc.identifier + ' ' + statement).toLowerCase(),
					formatted_statement: `<b style="color:#666">Association:</b> ${statement}`,
					current_date: current_date,
					archived_date: archived_date,
				})
			}

			/////////////////////////////
			// document updates
			if (this.update_types.document_updated && this.comps.CFDocument) {
				let comp = this.comps.CFDocument
				let update_keys = []
				let update_keys_for_search = []
				if ($.isArray(comp.diffs)) {
					comp.diffs = null
				} else for (let field in comp.diffs) {
					// update_keys.push(field)
					update_keys_for_search.push(field)
					if (!update_keys.includes('UPDATED')) update_keys.push('UPDATED')
				}

				if (comp.dates?.length == 2 && this.update_types.dates_only) {
					if (update_keys.length == 0) {
						update_keys_for_search.push('DATES ONLY')
						update_keys.push('DATES ONLY')
					}
				}

				if (update_keys.length > 0) {
					let doc = this.framework_record.json.CFDocument
					let [current_date, archived_date] = get_comp_dates(comp)
					arr.push({
						entity_type: 'doc',
						entity_icon: 'fas fa-map',
						update_icon: 'fas fa-circle-half-stroke',
						update_color: 'blue',
						comp: comp,
						entity: doc,
						update_type: update_keys.join(', '),
						search_string: (doc.identifier + ' ' + update_keys.join(' ') + ' ' + update_keys_for_search.join(' ') + ' ' + 'Document').toLowerCase(),
						formatted_statement: '<b style="color:#666">Document</b>',
						current_date: current_date,
						archived_date: archived_date,
					})
				}
			}

			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.framework_update_report_archive_compare = this
	},
	mounted() {
		// if the framework_record doesn't exist, or the case tree component isn't open, go back to the update_type report
		if (!this.framework_record || !vapp.case_tree_component || vapp.case_tree_component.lsdoc_identifier != this.framework_identifier) {
			this.parent_component.report_type = 'update_type'
		} else if (!this.archive_compare_arr) {
			this.parent_component.report_type = 'framework'
		} else {
			this.$store.commit('set', ['last_framework_update_report', this.framework_identifier])
			this.initialize()
		}
	},
	methods: {
		initialize() {
			console.log('report initialize', this.archive_compare_arr)
			// call the service to get the comparison between the two specified archives, unless we pulled it in the last 5 minutes
			// note that 
			if ((new Date().getTime() - this.$store.state.archive_comps_timestamp[this.archive_compare_arr[1].archive_fn]) < 5*60*1000) {
				this.comps = this.$store.state.archive_comps[this.archive_compare_arr[1].archive_fn].comps
				this.this_lastChangeDateTime = this.$store.state.archive_comps[this.archive_compare_arr[1].archive_fn].this_lastChangeDateTime
				this.last_lastChangeDateTime = this.$store.state.archive_comps[this.archive_compare_arr[1].archive_fn].last_lastChangeDateTime
				this.initialized = true

			} else {
				let payload = {
					framework_identifier: this.framework_identifier,
					archive_compare_arr: [this.archive_compare_arr[0], this.archive_compare_arr[1].archive_fn]
				}
				U.loading_start()
				U.ajax('get_archive_comparison', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in admin ajax call'); vapp.ping(); return;
					}

					this.$store.commit('set', [this.$store.state.archive_comps_timestamp, this.archive_compare_arr[1].archive_fn, new Date().getTime()])
					this.$store.commit('set', [this.$store.state.archive_comps, this.archive_compare_arr[1].archive_fn, result])

					this.comps = this.$store.state.archive_comps[this.archive_compare_arr[1].archive_fn].comps
					this.this_lastChangeDateTime = this.$store.state.archive_comps[this.archive_compare_arr[1].archive_fn].this_lastChangeDateTime
					this.last_lastChangeDateTime = this.$store.state.archive_comps[this.archive_compare_arr[1].archive_fn].last_lastChangeDateTime
					this.initialized = true
				})
			}
		},
		
		format_date(s, flag) { 
			if (s == '–') return '–'
			return this.parent_component.format_date(s, flag) 
		},

		format_statement(statement) {
			let s = statement.substr(0, 90)
			if (s != statement) s += '…'
			return s
		},

		assoc_statement(assoc) {
			let origin = dv(oprop(assoc, 'originNodeURI', 'title'), '???').replace(/\s*\(:.*?:\)/, '')
			let destination = dv(oprop(assoc, 'destinationNodeURI', 'title'), '???').replace(/\s*\(:.*?:\)/, '')
			if (origin.length > 20) origin = origin.substr(0,20) + '…'
			if (destination.length > 20) destination = destination.substr(0,20) + '…'
			return `${assoc.associationType} (${origin} <i class="fas fa-long-arrow-alt-right"></i> ${destination})`
		},

		set_row_hovering(item, val) {
			// console.log('set_row_hovering', this.hovering[item.entity.identifier], val)
			if (this.hovering[item.entity.identifier] === undefined) {
				this.$set(this.hovering, item.entity.identifier, val)
			} else {
				this.hovering[item.entity.identifier] = val
			}
		},

		show_details(item) {
			if (this.showing_details[item.entity.identifier] == 'no') {
				this.showing_details[item.entity.identifier] = 'yes'
			} else if (this.showing_details[item.entity.identifier] == 'yes') {
				this.showing_details[item.entity.identifier] = 'no'
			} else {
				this.$set(this.showing_details, item.entity.identifier, 'yes')
			}

			// clear last_viewed_item_from_archive_comparison when any item is clicked
			this.$store.commit('set', ['last_viewed_item_from_archive_comparison', null])
		},

		main_row_css_class(item) {
			let s = ''
			if (this.$store.state.last_viewed_item_from_archive_comparison == item.entity.identifier) {
				this.showing_details[item.entity.identifier] = 'yes'
				s += ' k-urac-main-row-showing-details k-urac-row-showing-and-hovering'
			} else if (this.showing_details[item.entity.identifier] == 'yes') {
				s += ' k-urac-main-row-showing-details'
				if (this.hovering[item.entity.identifier]) {
					s += ' k-urac-row-showing-and-hovering'
				}
			}
			return s
		},

		details_row_css_class(item) {
			let s = 'k-urac-details-row'
			if (this.hovering[item.entity.identifier] || this.$store.state.last_viewed_item_from_archive_comparison == item.entity.identifier) {
				s += ' k-urac-row-showing-and-hovering'
			}
			return s
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(this.search_re)) return true

			if (item.search_string.search(this.search_re) > -1) return true
			return false
		},

		// from old CompareArchive component; take out if we don't need it
		display_statement_html(item, type, update_word) {
			if (empty(item)) return ''
			let stmt_html = ''

			update_word = update_word.toUpperCase()
			// let update_word = 'UPDATED'
			// if (item.change_type && item.change_type.find(x=>x == 'new')) update_word = 'NEW'
			// else if (item.change_type && item.change_type.find(x=>x == 'deleted')) update_word = 'DELETED'

			// prepend the humanCodingScheme to statement
			if (type == 'CFItems') {
				let fs = item.fullStatement || '???'
				if (item.humanCodingScheme) stmt_html = sr('<div class="mr-1 grey--text text--darken-2"><nobr><b>$1</b></nobr></div>', item.humanCodingScheme)
				stmt_html += sr('<div class="k-case-tree-markdown">$1</div>', U.marked_latex(fs))
				stmt_html = sr('<b style="font-size:13px" class="blue--text text--darken-2">Item is $1 in current framework</b><div class="d-flex">$2</div>', update_word, stmt_html)

			} else if (type == 'CFAssociations') {
				let origin = dv(oprop(item, 'originNodeURI', 'title'), '???').replace(/\s*\(:.*?:\)/, '')
				let destination = dv(oprop(item, 'destinationNodeURI', 'title'), '???').replace(/\s*\(:.*?:\)/, '')
				if (origin.length > 20) origin = origin.substr(0,20) + '…'
				if (destination.length > 20) destination = destination.substr(0,20) + '…'
				stmt_html = sr('<b style="font-size:13px" class="green--text text--darken-1">Association is $1 in current framework</b><br>$2 ($3 <i class="fas fa-long-arrow-alt-right"></i> $4)', update_word, item.associationType, origin, destination)
			} else if (type == 'CFDocument') {
				stmt_html = sr('<b style="font-size:13px" class="purple--text text--darken-3">Document is UPDATED in current framework</b><br>')
			} else {
				stmt_html = sr('<b style="font-size:13px">$1 $2</b>', update_word, type)
			}

			return sr('<div class="k-td-stmt-text">$1</div>', stmt_html)
		},

		diff_html(key, diff_arr) {
			let prettify_object = (o) => {
				let s = ''

				if ($.isArray(o)) {
					for (let val of o) {
						if (s) s += ' | '
						if (typeof(val) == 'object') s += prettify_object(val)
						else s += val
					}

				} else {
					for (let key in o) {
						if (s) s += ' <br> '
						s += `• ${key}: ${o[key]}`
					}
				}
				return s
			}

			// don't display this diff if the field is being filtered out??
			
			// PW: on 7/17/2023, made a correction here: previously, we were switching which of the diff_arr items was the current version and which was the archived version
			let cdo = diff_arr[0] || '—'
			let ado = diff_arr[1] || '—'

			if (typeof(cdo) == 'object') cdo = prettify_object(cdo)
			if (typeof(ado) == 'object') ado = prettify_object(ado)

			// if both sides have content, set so that both will be equal width
			let flex_basis = 'auto'
			if (ado != '—' && cdo != '—') flex_basis = '50%'

			// if both sides are strings, highlight differences
			if (typeof(ado) == 'string' && typeof(cdo) == 'string') {
				// replace image tags, since they don't render well
				if (!empty(ado)) ado = ado.replace(/<img.*?>/g, '[IMAGE]')
				if (!empty(cdo)) cdo = cdo.replace(/<img.*?>/g, '[IMAGE]')

				let obj = U.diff_string(ado, cdo)
				ado = obj.o
				cdo = obj.n
			}

			let s = ''
			s += `<div class="k-urac-diff-header"><b>${U.field_display_string(key, this.framework_record)}</b></div>`
			s += `<div class="k-urac-diff-text-wrapper">`
			s += `<div class="k-urac-diff-text k-urac-diff-text-archive" style="flex-basis:${flex_basis}">${ado}</div><br/>`
			s += `<div class="k-urac-diff-arrow"><i class="fas fa-arrow-right"></i></div>`
			s += `<div class="k-urac-diff-text k-urac-diff-text-current" style="flex-basis:${flex_basis}">${cdo}</div><br/>`
			s += `</div>`

			return s
		},

		view_item(where, item) {
			let identifier
			if (item.entity_type == 'assoc') {
				// for associations, show the origin item, if it's in this framework, or the destination item, if it's in this framework
				identifier = item.entity.originNodeURI.identifier
				if (!this.framework_record.json.CFItems.find(x=>x.identifier == identifier) && this.framework_record.json.CFDocument.identifier != identifier) {
					identifier = item.entity.destinationNodeURI.identifier
					// if we don't find this one either, error; there's nothing to show
					if (!this.framework_record.json.CFItems.find(x=>x.identifier == identifier) && this.framework_record.json.CFDocument.identifier != identifier) {
						this.$alert('Neither of the items involved in this association are part of this framework.')
						return
					}
				}

			} else {
				identifier = item.entity.identifier
			}
			
			this.$store.commit('set', ['last_viewed_item_from_archive_comparison', identifier])

			if (where == 'archive') {
				vapp.case_tree_component.view_archive(this.archive_compare_arr[1].archive_fn, this.archive_compare_arr[1].timestamp, this.archive_compare_arr[1].archive_note, identifier)
			} else {
				// vapp.case_tree_component.view_archive('current', '', '', identifier)
				vapp.case_tree_component.track_changes(this.archive_compare_arr[1].archive_fn, this.archive_compare_arr[1].timestamp, this.archive_compare_arr[1].archive_note, identifier)
			}
			this.parent_component.hide_report()
		},

		// display raw json
		show_elements_json(item) {
			if (item.entity_type == 'doc') {
				vapp.case_tree_component.more_info('document')
				return
			} else {
				// we have to send a node...
				let tree_nodes = oprop(this.framework_record, 'cfo', 'cfitems', item.entity.identifier, 'tree_nodes')
				if (tree_nodes && tree_nodes.length > 0) {
					vapp.case_tree_component.more_info(tree_nodes[0])
					return
				}
			}

			// if we get here we couldn't find anything to show. but this shouldn't happen...
			console.log('CompareArchive.show_elements_json: couldn’t find item to show')
		},
	}
}
</script>

<style lang="scss">

.k-urac-bullet {
	font-size:24px; 
	line-height:0;
	vertical-align:middle;
}
.k-urac-main-row-showing-details {
	td {
		border-bottom-width:0!important;
		padding-bottom:0!important;
	}
}
.k-urac-details-row {
	td {
		padding-top:0!important;
		border-top-width:0!important;
		height:0!important;
	}
}

.k-urac-row-showing-and-hovering {
	background-color: $v-yellow-lighten-5!important;
}

.k-urac-diff-header {
	color:$v-orange-darken-3;
	margin:4px 0 2px 12px;
	font-size:12px;
}
.k-urac-diff-text-wrapper {
	display:flex;
	align-items: stretch;
	margin-left:8px;
	margin-bottom:8px;
}
.k-urac-diff-text {
	padding:4px;
	border:2px solid #000;
	border-radius:6px;
	background-color:#fff;
}
.k-urac-diff-text-archive {
	// background-color:$v-orange-lighten-5;
	border-color:$v-orange;
}
.k-urac-diff-text-current {
	// background-color:$v-cyan-lighten-5;
	border-color:$v-cyan;
}

.k-urac-diff-arrow {
	align-self: center;
	margin:0 4px;
}
</style>
