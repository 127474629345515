<template>
	<v-dialog v-model="dialog_open" @keydown.esc="$emit('dialog_cancel')" :max-width="show_help?800:400" persistent scrollable :fullscreen="$vuetify.breakpoint.xs"><div class="k-login-wrapper-outer"><v-spacer/>
		<div class="k-login-wrapper" role="dialog" :tabindex="-1" aria-label="Log In Dialog" aria-modal="true">
			<div class="k-login-logo-wrapper k-login-logo-wrapper-ext k-banner-color">
				<div class="k-login__logo-img-wrapper"><img alt="Agency logo" class="k-login__logo-img" :src="agency_logo_img_src"></div>
				<div v-if="show_app_name_with_logo!='false'" class="k-login__logo-text k-app-title-color k-sans-serif-font k-login__logo-text-ext"><b>{{app_name}}</b></div>
			</div>

			<div v-if="use_msft_oidc_login" class="d-flex mt-4 pt-4 pb-2 center justify-center align-center" style="border-top:1px solid #aaa; height:64px;">
				<v-btn @click="msft_sign_in" color="blue" dark class="center">
					Sign in with Microsoft
					<div class="ml-2 larger-svg-container">
						<object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon white-bg"></object> 
					</div>
				</v-btn>
			</div>

			<div v-if="friendly_login_error" class="k-login-error" v-html="friendly_login_error"></div>
			<v-text-field @keyup="keyup" ref="email_field" class="mt-3" background-color="#eee" outlined hide-details prepend-inner-icon="fa fa-envelope" label="Email Address" v-model="email"></v-text-field>
			<v-text-field @keyup="keyup" class="mt-4" background-color="#eee" outlined password hide-details prepend-inner-icon="fa fa-key" :type="show_password ? 'text' : 'password'" label="Password" v-model="password" @focus="enable_keyup">
				<template slot="append">
					<v-icon @click="toggle_password" :aria-label="show_password ? 'Password is currently shown. Click to hide password' : 'Password is currently hidden. Click to show password'">
						{{ show_password ? 'fas fa-eye' : 'fas fa-eye-slash' }}
					</v-icon>
				</template>
			</v-text-field>			<div v-if="!show_cn2_signin_options" class="mt-2"><v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" style="font-weight:normal" @click="forgot_password">Forgot your password?</v-btn></div>
			<div v-if="show_cn2_signin_options" class="mt-3 mb-4">
				<div class="text-center">
					<v-btn class="k-tight-btn k-nocaps-btn mr-2" small color="#57B0E2" dark @click="oauth_sign_in">Sign In via 1EdTech <v-icon x-small class="ml-2">fas fa-up-right-from-square</v-icon></v-btn>
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" style="font-weight:normal" @click="show_help=!show_help">Need Help? Click Here!</v-btn>
				</div>
				<div v-show="show_help" class="pt-3 pb-4 mt-3 mx-4 elevation-2" style="background-color:#eee; border-radius:8px; font-size:16px; line-height:22px;">
					<ul>
						<li>I <b>already have an account</b> for CASE Network 2, but <v-btn class="k-tight-btn k-nocaps-btn mx-1 my-1" small color="primary" dark @click="forgot_password">I Forgot my Password <v-icon x-small class="ml-2">fas fa-face-frown-open</v-icon></v-btn></li>
						<li class="mt-2">My institution <b>already has registered access</b> to CASE Network 2, and <v-btn class="k-tight-btn k-nocaps-btn mx-1 my-1" small color="primary" dark @click="">I need an account <v-icon x-small class="ml-2">fas fa-face-smile-wink</v-icon></v-btn> <nobr>for my email address</nobr></li>
						<li class="mt-2">I’d like to <v-btn class="k-tight-btn k-nocaps-btn mx-1 my-1" small color="#57B0E2" dark @click="oauth_sign_in">Sign In with a 1EdTech Account <v-icon x-small class="ml-2">fas fa-up-right-from-square</v-icon></v-btn></li>
						<li class="mt-2"><v-btn class="k-tight-btn k-nocaps-btn mx-1 my-1" small color="primary" dark @click="register_for_cn2">Register my Institution <v-icon x-small class="ml-2">fas fa-list-check</v-icon></v-btn> for CASE Network 2 or <v-btn class="k-tight-btn k-nocaps-btn mx-1 my-1" small color="primary" dark @click="">Learn About the Benefits <v-icon x-small class="ml-2">fas fa-info-circle</v-icon></v-btn> of registered access</li>
					</ul>
				</div>
			</div>
			<div class="d-flex mt-2">
				<v-btn @click="cancel_sign_in" color="secondary">Cancel</v-btn>
				<v-spacer/>
				<v-btn @click="sign_in" color="primary">Sign In</v-btn>
			</div>
		</div>
	<v-spacer/></div></v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		email: '',
		password: '',
		show_password: false,
		show_help: false,
		keyup_enabled: false,
	}},
	computed: {
		...mapState(['login_error']),
		agency_logo_img_src() {
			if (!this.$store.state.site_config.agency_logo) return ''
			return this.$store.state.site_config.agency_logo
		},
		app_name() { return this.$store.state.site_config.app_name },
		show_app_name_with_logo() { return this.$store.state.site_config.show_app_name_with_logo },
		friendly_login_error() {
			if (this.login_error == 'ad_fail') {
				return 'Unknown email address or invalid password.'
			} else if (this.login_error == 'sis_fail') {
				return 'Your email address could not be verified via your SIS.'
			} else {
				return this.login_error
			}
		},
		show_cn2_signin_options() { return vapp.show_cn2_signin_options },
		use_msft_oidc_login() { return this.$store.state.use_msft_oidc_login },
	},
	watch: {
	},
	created() {
	},
	mounted() {
		this.$nextTick(()=>{
			this.email = U.local_storage_get('sparklsalt_login_email', '')
			$(this.$refs.email_field.$el).find('input').focus()
		})
	},
	methods: {
		keyup(event) {
			if (!this.keyup_enabled) return
			if (event.code == 'Enter' || event.keyCode == 13) this.sign_in()
		},

		forgot_password() {
			this.$prompt({
				title: 'Forgot Password',
				text: 'Enter your email address and click the button below to send a one-time sign-in link to your email account. <b>This link will be valid for one hour.</b> After using the link to sign in, you can reset your password.',
				initialValue: this.email,
				acceptText: 'Send One-Time Sign-In Link',
				dialogMaxWidth: 540,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(fp_email => {
				U.loading_start()
				U.ajax('send_token_signin_email', {user_email: fp_email, token_duration: 60}, result=>{
					U.loading_stop()
					let msg
					if (result.status != 'ok') {
						if (result.status === 'bad_email') {
							msg = 'We couldn’t find an account for the email you entered.'
						} else {
							msg = 'The one-time sign-in link could not be sent.'
						}
						console.log('Error in token signin ajax call: ' + result.status)	
					} else {
						msg = 'One-time sign-in link sent. <b>Please check your spam or junk folder</b> if you don’t receive the link within one minute, and remember that <b>the link will expire after one hour</b>.'
					}
					this.$alert(msg)
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		oauth_sign_in() {
			vapp.sign_in('oauth')
		},

		register_for_cn2() {
			window.open('https://www.1edtech.org/form/cn2')
		},

		sign_in() {
			this.email = $.trim(this.email)
			this.password = $.trim(this.password)

			if (empty(this.email)) {
				this.$alert('Please enter your email address.')
				return
			}

			if (empty(this.password)) {
				this.$alert('Please enter your password.')
				return
			}

			// shortcut: if email has no @, append @doe.k12.ga.us
			if (this.email.indexOf('@') == -1) {
				this.email += '@doe.k12.ga.us'
			}

			// READY TO SIGN IN...

			// store email in local storage
			U.local_storage_set('sparklsalt_login_email', this.email)
			U.loading_start('', 'initialize_app')
			vapp.initialize_app({
				email: this.email,
				password: this.password,
			})
		},

		// Microsoft SAML sign in
		msft_sign_in() {
			// READY TO SIGN IN...

			window.location.href = '/src/msftoidclogin.php'
		},

		cancel_sign_in() {
			// if the user tried to go directly to a private framework and needed to sign in, but then cancelled,
			if (vapp.case_tree_component && !vapp.case_tree_component.initialized) {
				// we need to redirect to the framework list
				vapp.go_to_route('')
			}
			this.$emit('dialog_cancel')
		},
		toggle_password() {
			this.show_password = !this.show_password
			this.keyup_enabled = false
		},
		enable_keyup() {
			setTimeout(x=>{
				// enable the keyup event for the inputs after a second; if it's enabled at the start, then if the user opened the sign-in dialog by hitting the return key, the keyup event sometimes fires right away
				this.keyup_enabled = true
			}, 2000)
		}
	}
}
</script>

<style lang="scss">
.k-login-wrapper-outer {
	display:flex;
	flex-direction: column;
	background-color:#fff;
}

.k-login-wrapper {
	border-radius:8px;
	background:rgba(255, 255, 255, 1);
	padding:10px;

	.k-login-logo-wrapper {
		display:flex;
		// flex-wrap:wrap;
		align-items: center;
		// width:100%;
		margin-bottom:20px;
		border-radius:4px;
	}

	// the following css classes are injected by store.js based on env variables:
	// .k-login__logo-img-wrapper
	// .k-login__logo-img
	// .k-login-logo-wrapper-ext
	// .k-login__logo-text-ext

	.k-login__logo-text {
		flex: 0 1 auto;
		// flex: 0 1 50%;
		margin-right:15px;
		// text-align:center;
		// font-family added by k-sans-serif-font
		// color will be added by k-app-title-color
		font-weight:bold;
		font-size:36px;
		line-height:36px;
	}
}

.k-login-error {
	background-color:$v-red-lighten-4;
	color:$v-red-darken-4;
	border-radius:5px;
	padding:10px;
	margin: 10px 0 20px 0;
	max-width: 500px;
	font-size: 15px;
}
</style>
