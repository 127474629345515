<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable @keydown.esc="$emit('dialog_cancel')">
	<v-card role="dialog" aria-model="true" aria-labelledby="manage-comment-groups">
		<v-card-title style="border-bottom:1px solid #999">
			<b id="manage-comment-groups">Manage Comment Groups</b>
			<v-spacer/>
			<v-icon color="light-blue" class="mr-2" large @click="U.show_help('comment_groups')" aria-label="Open help for comment groups">fas fa-info-circle</v-icon>
			<v-btn small text color="primary" @click="new_group_clicked" class="mr-2"><v-icon small class="mr-2">fas fa-plus</v-icon>Add New Group</v-btn>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-if="group_rows" style="clear:both">
				<v-data-table light dense
					:headers="headers"
					:items="group_rows"
					hide-default-footer
					:items-per-page="-1"
					class="k-admin-users-table"
					no-data-text="Click the button above to add a comment group."
				>
					<template v-slot:item="{ item }"><tr>
						<td><v-btn icon x-small class="mr-2" @click="edit_group(item)" aria-label="Edit comment group"><v-icon>fas fa-edit</v-icon></v-btn>{{item.name}}</td>
						<td class="text-left">{{item.admin_user_names}}</td>
						<td class="text-left">{{item.user_names}}</td>
						<td class="text-center">{{item.comment_count}}</td>
					</tr></template>
				</v-data-table>
			</div>
			<CommentGroupEdit v-if="group_being_edited" :group_being_edited="group_being_edited" @editor_cancel="group_being_edited=null" @group_edited="group_edited"></CommentGroupEdit>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CommentGroupEdit from './CommentGroupEdit'

export default {
	components: { CommentGroupEdit },
	props: {
		framework_record: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Group Name', align: 'left', sortable: false, value:'name' },
			{ text: 'Admin Users', align: 'left', sortable: false, value:'admin_user_names' },
			{ text: 'All Users', align: 'left', sortable: false, value:'user_names' },
			{ text: 'Comment Count', align: 'center', sortable: false, value:'comment_count' },
		],
		group_being_edited: null,
		show_info_dialog:false
	}},
	computed: {
		...mapState(['user_info', 'comment_groups']),
		...mapGetters(['comments_hash']),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		group_rows() {
			let arr = []
			for (let cg of this.comment_groups) {
				if (cg.framework_identifier == this.framework_identifier) {
					let o = {
						comment_group: cg,
						name: cg.name,
						user_names: cg.user_names.join(', '),
						admin_user_names: '',
						comment_count: 0,
					}

					// get admin user names
					for (let auid of cg.admin_user_ids) {
						let i = cg.user_ids.findIndex(x=>x==auid)
						if (i > -1) {
							if (o.admin_user_names) o.admin_user_names += ', '
							o.admin_user_names += cg.user_names[i]
						}
					}

					// get count of comments
					if (this.comments_hash[this.framework_identifier]) {
						for (let item_identifier in this.comments_hash[this.framework_identifier]) {
							for (let c of this.comments_hash[this.framework_identifier][item_identifier]) {
								if (c.comment_group_id == cg.comment_group_id) {
									++o.comment_count
								}
							}
						}
					}

					arr.push(o)
				}
			}
			return arr
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		new_group_clicked() {
			this.group_being_edited = new Comment_Group({
				framework_identifier: this.framework_identifier,
				creator_user_id: this.user_info.user_id,
				admin_user_ids: [this.user_info.user_id],
				user_ids: [this.user_info.user_id],
				user_names: [this.user_info.first_name + ' ' + this.user_info.last_name],
			})
		},

		edit_group(group_row) {
			this.group_being_edited = group_row.comment_group
		},

		group_edited(payload) {
			let new_group = (this.group_being_edited.comment_group_id == 0)

			// transfer values set in the editor to group_being_edited, which will be in the store if it's an existing group
			for (let key in payload) {
				if (new_group) {
					this.group_being_edited[key] = payload[key]
				} else {
					this.$store.commit('set', [this.group_being_edited, key, payload[key]])
				}
			}

			// now create payload to send to the server: all values for the group, plus user_info.user_id
			payload = Object.assign({}, this.group_being_edited)
			payload.user_id = this.user_info.user_id

			U.loading_start()
			U.ajax('save_comment_group', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in admin ajax call: ' + result.status); vapp.ping(); return;
				}

				let msg
				if (new_group) {
					this.$alert('New comment group created.')
					// set the comment_group_id and add the new group to the store
					this.group_being_edited.comment_group_id = result.comment_group_id
					this.$store.commit('set', [this.comment_groups, 'PUSH', this.group_being_edited])
				}

				// close group editor
				this.group_being_edited = null

				// call schedule_comment_update, to make sure we start retrieving updates periodically
				vapp.case_tree_component.schedule_comment_update()
			});
		},
	}
}
</script>

<style lang="scss">
</style>
