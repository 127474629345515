U.googleTranslateElementInit = function() {
	// uncomment the below to cancel google translate functionality
	// $('#google_translate_element_wrapper').hide()
	// return

	// the google_translate_element appears to need to be both in the DOM and visible for the GT initialization to work properly
	if ($('#google_translate_element').length > 0 && $('#google_translate_element').is(':visible')) {
		// console.log('INITIALIZING GOOGLE TRANSLATE ELEMENT')
		window.google_translate_element = new google.translate.TranslateElement({
				// https://clearwordstranslations.com/language/en/most-popular-languages/#:~:text=Besides%20English%2C%20the%20next%20most,%2C%20Russian%2C%20French%20and%20German.
				// Besides English, the next most used languages online are Chinese, Spanish, Arabic, Portuguese, Indonesian, Japanese, Russian, French and German
				// if pageLanguage is specified, we don't see that language in the list to translate to
				// pageLanguage: 'en', 
				includedLanguages: 
					'en'	// English
					+',es'	// Spanish
					+',vi'	// Vietnamese
					+',fr'	// French
					+',zh-CN'	// Chinese
					+',hi'	// Hindi
					+',ar'	// Arabic
					+',id'	// Indonesian
					+',ja'	// Japanese
					+',no'	// Norwegian
					+',de'	// German
					+',it'	// Italian
					+',ko'	// Korean
					// +',sv'	// Swedish
					+',th'	// Thai
					+',tr'	// Turkish
					+',uk'	// Ukranian
					+',sw'	// Swahili
					+',nl'	// Dutch
					+',fil'	// Filipino
					+',ru'	// Russian
					+',mi'	// Maori
					+',pt'	// Portuguese
					// +',pt-PT'	// Portuguese (Portugal) -- this and the brazil variant don't seem to work
					// +',pt-BR'	// Portuguese (Brazil)
					,
				layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
				autoDisplay: false,
			}, 'google_translate_element'
		)
	} else {
		// console.log('GOOGLE TRANSLATE ELEMENT NOT READY; TRY AGAIN...')
		setTimeout(U.googleTranslateElementInit, 50)
	}
}

U.googleTranslateRestoreOriginal = async function() {
	// try to find the "restore" btn in the google translate toolbar (which may be hidden); if we find it, click it
	let iframe = $('.skiptranslate iframe.skiptranslate')[0]

	if (!iframe) {
		console.log('googleTranslateRestoreOriginal: couldn’t get iframe')
		return false
	}

	// NOTE: this may not work forever; google could change this "magic identifier" at any time
	let btn = iframe.contentDocument.getElementById(':2.restore')
	if (!btn) {
		console.log('googleTranslateRestoreOriginal: couldn’t get button')
		return false
	}

	if (btn) btn.click()
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, 1000);
	});
}

U.googleTranslateGetCurrentLanguage = function() {
	let s = U.cookie_get('googtrans', '', false)
	// if the page is translated, we should get, e.g., '/auto/es'; if it's not translated, we should get an empty string
	if (s.search(/.auto.(\w+)/) == 0) {
		return RegExp.$1
	}
	return ''
}