<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="600"><v-card>
	<v-card-title>Edit Comment Group <b class="mx-2">{{group_being_edited.name}}</b></v-card-title>
	<v-card-text style="color:#000;">
		<div class="k-case-ie-line">
			<label for="group-name" class="k-case-ie-line-label text-right mr-2" style="flex:0 0 100px" >Group Name:</label>
			<div class="k-case-ie-line-body">
				<v-text-field ref="name_text_field" background-color="#fff" :id="'group-name'" outlined dense hide-details v-model="name" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label text-right mr-2" style="flex:0 0 100px">Group Members:</div>
			<div class="k-case-ie-line-body" style="flex: 0 0 calc(100% - 110px)">
				<v-chip-group column>
					<v-chip small v-for="(user) in users" v-bind:tabindex="-1" :key="user.user_id" :close="user.user_id!=user_info.user_id" @click:close="remove_user(user)">{{user.user_name}}</v-chip>
					<v-chip small @click="add_user" tag="button"><v-icon small class="mr-1">fas fa-plus</v-icon>Add New Member</v-chip>
				</v-chip-group>
			</div>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label text-right mr-2" style="flex:0 0 100px">Group Admins:</div>
			<div class="k-case-ie-line-body" style="flex: 0 0 calc(100% - 110px)">
				<v-chip-group column>
					<v-chip small v-for="(admin) in admins" v-bind:tabindex="-1" :key="admin.user_id" :close="admin.user_id!=user_info.user_id" @click:close="remove_admin(admin)">{{admin.user_name}}</v-chip>
					<v-chip small @click="add_admin" tag="button"><v-icon small class="mr-1">fas fa-plus</v-icon>Add New Admin</v-chip>
				</v-chip-group>
			</div>
		</div>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-spacer></v-spacer>
		<v-btn small color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn small color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		group_being_edited: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		name: this.group_being_edited.name,
		users: [],
		admins: [],
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
		this.users = []
		for (let i = 0; i < this.group_being_edited.user_ids.length; ++i) {
			this.users.push({
				user_id: this.group_being_edited.user_ids[i],
				user_name: this.group_being_edited.user_names[i],
			})
		}

		for (let admin_user_id of this.group_being_edited.admin_user_ids) {
			let i = this.group_being_edited.user_ids.findIndex(x=>x==admin_user_id)
			this.admins.push({
				user_id: this.group_being_edited.user_ids[i],
				user_name: this.group_being_edited.user_names[i],
			})
		}
	},
	mounted() {
		if (empty(this.name)) {
			setTimeout(x=>$(this.$refs.name_text_field.$el).find('input').focus(), 100)
		}
	},
	methods: {
		remove_user(user) {
			// if the user is also an admin, remove from admins
			let i = this.admins.findIndex(x=>x.user_id==user.user_id)
			if (i > -1) this.admins.splice(i, 1)

			// then remove from users
			i = this.users.findIndex(x=>x==user)
			this.users.splice(i, 1)
		},

		add_user() {
			this.$prompt({
				title: 'Add New Member to Comment Group',
				text: 'Enter the email address for the new user.',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				initialValue: '',
				acceptText: 'Add User',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid email.').then(x=>{this.new_user_clicked()})
					return
				}

				let payload = {
					user_id: this.user_info.user_id,
					email_to_check: email,
				}

				U.loading_start()
				U.ajax('check_user_email', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in admin ajax call'); vapp.ping(); return;
					}

					// if we received back a user_id, add
					if (result.user_id) {
						// check to see if user is already there
						if (this.users.find(x=>x.user_id == result.user_id)) {
							this.$alert(sr('That user ($1 $2) is already a member of the group', result.first_name, result.last_name))
							return
						}
						this.users.push({
							user_id: result.user_id,
							user_name: result.first_name + ' ' + result.last_name
						})

					} else {
						// else report error
						this.$alert(sr('Cannot find a user with that email address ($1) in the SuitCASE system.', email))
						return
					}
				});

			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		remove_admin(admin) {
			let i = this.admins.findIndex(x=>x==admin)
			this.admins.splice(i, 1)
		},

		add_admin() {
			this.$prompt({
				title: 'Add New Admin to Comment Group',
				text: 'Enter the email address for the new group admin (group admins must already be added as group members).',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				initialValue: '',
				acceptText: 'Add Admin User',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid email.').then(x=>{this.new_user_clicked()})
					return
				}

				let payload = {
					user_id: this.user_info.user_id,
					email_to_check: email,
				}

				U.loading_start()
				U.ajax('check_user_email', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in admin ajax call'); vapp.ping(); return;
					}

					// if we received back a user_id, add
					if (result.user_id) {
						// check to see if user is already there
						if (this.admins.find(x=>x.user_id == result.user_id)) {
							this.$alert(sr('That user ($1 $2) is already an admin of the group', result.first_name, result.last_name))
							return
						}
						this.admins.push({
							user_id: result.user_id,
							user_name: result.first_name + ' ' + result.last_name
						})

						// for admins, also check to make sure they're already in users; if not, add them
						if (!this.users.find(x=>x.user_id == result.user_id)) {
							this.users.push({
								user_id: result.user_id,
								user_name: result.first_name + ' ' + result.last_name
							})
						}

					} else {
						// else report error
						this.$alert(sr('Cannot find a user with that email address ($1) in the SuitCASE system.', email))
						return
					}
				});

			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		save_edits() {
			if (empty(this.name)) {
				this.$alert('Every comment group must have a name.')
				return
			}

			let payload = {
				name: this.name,
				user_ids: [],
				user_names: [],
				admin_user_ids: [],
			}

			for (let user of this.users) {
				payload.user_ids.push(user.user_id)
				payload.user_names.push(user.user_name)
			}
			for (let admin of this.admins) payload.admin_user_ids.push(admin.user_id)

			this.$emit('group_edited', payload)
		},
	}
}
</script>

<style lang="scss">
</style>
