<template><div>
	<div v-if="object_type=='item'">
		<div v-if="object.identifier" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :label="'Identifier'" :val="object_to_copy.identifier" class="mr-1"/></div><div class="k-cjvr-key">Identifier</div><div class="k-cjvr-val" v-html="object.identifier"></div></div>
		<div v-if="object.uri" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.uri" class="mr-1" :label="'URI'"/></div><div class="k-cjvr-key">URI</div><div class="k-cjvr-val"><a :href="object.uri" target="_blank" v-html="object.uri"></a></div><div v-if="revert_allowed.uri"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'uri')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.sourceItemIdentifier" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.sourceItemIdentifier?object_to_copy.sourceItemIdentifier:''" class="mr-1" :label="'Source Item Identifier'"/></div><div class="k-cjvr-key">Source Item Identifier*</div><div class="k-cjvr-val" v-html="object.sourceItemIdentifier"></div></div>
		<div v-if="object.sourceItemURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.sourceItemURI?object_to_copy.sourceItemURI:''" class="mr-1" :label="'Source Item URI'"/></div><div class="k-cjvr-key">Source Item URI*</div><div class="k-cjvr-val"><a :href="object.sourceItemURI" target="_blank" v-html="object.sourceItemURI"></a></div></div>
		<div v-if="object.humanCodingScheme" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.humanCodingScheme" class="mr-1" :label="'Human-Readable Code'"/></div><div class="k-cjvr-key">Human-Readable Code</div><div class="k-cjvr-val" v-html="object.humanCodingScheme"></div><div v-if="revert_allowed.humanCodingScheme"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'humanCodingScheme')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.fullStatement" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.fullStatement" class="mr-1" :label="'Full Statement'"/></div><div class="k-cjvr-key">Full Statement</div><div class="k-cjvr-val" v-html="object.fullStatement"></div><div v-if="revert_allowed.fullStatement"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'fullStatement')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.abbreviatedStatement" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.abbreviatedStatement" class="mr-1" :label="'Abbreviation Statement'"/></div><div class="k-cjvr-key">Abbrev. Statement</div><div class="k-cjvr-val" v-html="object.abbreviatedStatement"></div><div v-if="revert_allowed.abbreviatedStatement"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'abbreviatedStatement')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.notes" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.notes" class="mr-1" :label="'Notes'"/></div><div class="k-cjvr-key">Notes</div><div class="k-cjvr-val" v-html="object.notes"></div><div v-if="revert_allowed.notes"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'notes')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.supplementalNotes" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.supplementalNotes" class="mr-1" :label="'Supplemental Information'"/></div><div class="k-cjvr-key">*Supp. Info.</div><div class="k-cjvr-val k-cjvr-exemplar k-case-tree-item-details-exemplar k-case-tree-markdown"><div class="fr-view k-case-tree-item-details-exemplar-body" v-html="processed_supplementalNotes"></div></div><div v-if="revert_allowed.supplementalNotes"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'supplementalNotes')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.educationLevel" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="array_html(object_to_copy.educationLevel)" class="mr-1" :label="'Education Level'"/></div><div class="k-cjvr-key">Education Level</div><div class="k-cjvr-val" v-html="array_html(object.educationLevel)"></div><div v-if="revert_allowed.educationLevel"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'educationLevel')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.language" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.language" class="mr-1" :label="'Language'"/></div><div class="k-cjvr-key">Language</div><div class="k-cjvr-val" v-html="object.language"></div><div v-if="revert_allowed.language"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'language')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.alternativeLabel" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.alternativeLabel" class="mr-1" :label="'Alt. Label'"/></div><div class="k-cjvr-key">Alt. Label</div><div class="k-cjvr-val" v-html="object.alternativeLabel"></div><div v-if="revert_allowed.alternativeLabel"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'alternativeLabel')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.CFItemType" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.CFItemType" class="mr-1" :label="'Item Type'"/></div><div class="k-cjvr-key">Item Type</div><div class="k-cjvr-val" v-html="object.CFItemType"></div><div v-if="revert_allowed.CFItemType"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'CFItemType')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.CFItemTypeURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="uri_html(object_to_copy.CFItemTypeURI)" class="mr-1" :label="'CFItemTypeURI'"/></div><div class="k-cjvr-key">CFItemTypeURI</div><div class="k-cjvr-val" v-html="uri_html(object.CFItemTypeURI)"></div><div v-if="revert_allowed.CFItemTypeURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'CFItemTypeURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.subject" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="array_html(object_to_copy.subject)" class="mr-1" :label="'Subject'"/></div><div class="k-cjvr-key">Subject</div><div class="k-cjvr-val" v-html="array_html(object.subject)"></div><div v-if="revert_allowed.subject"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'subject')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.subjectURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="array_of_uris_html(object_to_copy.subjectURI)" class="mr-1" :label="'SubjectURI'"/></div><div class="k-cjvr-key">SubjectURI</div><div class="k-cjvr-val" v-html="array_of_uris_html(object.subjectURI)"></div><div v-if="revert_allowed.subjectURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'subjectURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.listEnumeration" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.listEnumeration" class="mr-1" :label="'listEnumeration'"/></div><div class="k-cjvr-key">listEnumeration</div><div class="k-cjvr-val" v-html="object.listEnumeration"></div><div v-if="revert_allowed.listEnumeration"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'listEnumeration')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.conceptKeywords" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="array_html(object_to_copy.conceptKeywords)" class="mr-1" :label="'conceptKeywords'"/></div><div class="k-cjvr-key">conceptKeywords</div><div class="k-cjvr-val" v-html="array_html(object.conceptKeywords)"></div><div v-if="revert_allowed.conceptKeywords"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'conceptKeywords')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.conceptKeywordsURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="uri_html(object_to_copy.conceptKeywordsURI)" class="mr-1" :label="'conceptKeywordsURI'"/></div><div class="k-cjvr-key">conceptKeywordsURI</div><div class="k-cjvr-val" v-html="uri_html(object.conceptKeywordsURI)"></div><div v-if="revert_allowed.conceptKeywordsURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'conceptKeywordsURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.licenseURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="uri_html(object_to_copy.licenseURI)" class="mr-1" :label="'licenseURI'"/></div><div class="k-cjvr-key">licenseURI</div><div class="k-cjvr-val" v-html="uri_html(object.licenseURI)"></div><div v-if="revert_allowed.licenseURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'licenseURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.statusStartDate" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.statusStartDate" class="mr-1" :label="'Implementation Start Date'"/></div><div class="k-cjvr-key">Implementation Start Date</div><div class="k-cjvr-val" v-html="object.statusStartDate"></div><div v-if="revert_allowed.statusStartDate"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'statusStartDate')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.statusEndDate" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.statusEndDate" class="mr-1" :label="'Retirement Date'"/></div><div class="k-cjvr-key">Retirement Date</div><div class="k-cjvr-val" v-html="object.statusEndDate"></div><div v-if="revert_allowed.statusEndDate"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'statusEndDate')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.isSupplementalItem" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.isSupplementalItem" class="mr-1" :label="'Supplemental Branch'"/></div><div class="k-cjvr-key">Supplemental Branch*</div><div class="k-cjvr-val" v-html="object.isSupplementalItem"></div><div v-if="revert_allowed.isSupplementalItem"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'isSupplementalItem')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-for="(def,key) in custom_fields" class="k-case-json-view-row" v-if="object[key]"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy[key]" class="mr-1" :label="key"/></div><div class="k-cjvr-key">{{key}}*</div><div class="k-cjvr-val" v-html="object[key]"></div><div v-if="revert_allowed[key]"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', key)"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.lastChangeDateTime" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.lastChangeDateTime" class="mr-1" :label="'Last Changed Date'"/></div><div class="k-cjvr-key">Last Changed Date</div><div class="k-cjvr-val"><span v-html="object.lastChangeDateTime"></span> <i class="pl-2">({{local_time(object.lastChangeDateTime)}} local time)</i></div></div>
	</div>

	<div v-if="object_type=='document'">
		<div v-if="object.identifier" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.identifier" class="mr-1" :label="'Identifier'"/></div><div class="k-cjvr-key">Identifier</div><div class="k-cjvr-val" v-html="object.identifier"></div></div>
		<div v-if="object.uri" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.uri" class="mr-1" :label="'URI'"/></div><div class="k-cjvr-key">URI</div><div class="k-cjvr-val"><a :href="object.uri" target="_blank" v-html="object.uri"></a></div><div v-if="revert_allowed.uri"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'uri')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.title" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.title" class="mr-1" :label="'Title'"/></div><div class="k-cjvr-key">Title</div><div class="k-cjvr-val" v-html="object.title"></div><div v-if="revert_allowed.title"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'title')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.adoptionStatus" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.adoptionStatus" class="mr-1" :label="'Adoption Status'"/></div><div class="k-cjvr-key">Adoption Status</div><div class="k-cjvr-val" v-html="object.adoptionStatus"></div><div v-if="revert_allowed.adoptionStatus"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'adoptionStatus')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.frameworkType" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.frameworkType" class="mr-1" :label="'Framework Type'"/></div><div class="k-cjvr-key">Framework Type</div><div class="k-cjvr-val" v-html="object.frameworkType"></div><div v-if="revert_allowed.frameworkType"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'frameworkType')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.version" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.version" class="mr-1" :label="'Version'"/></div><div class="k-cjvr-key">Version</div><div class="k-cjvr-val" v-html="object.version"></div><div v-if="revert_allowed.version"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'version')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.officialSourceURL" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.officialSourceURL" class="mr-1" :label="'Official Source URL'"/></div><div class="k-cjvr-key">Official Source URL</div><div class="k-cjvr-val" v-html="object.officialSourceURL"></div><div v-if="revert_allowed.officialSourceURL"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'officialSourceURL')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.creator" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.creator" class="mr-1" :label="'Creator'"/></div><div class="k-cjvr-key">Creator</div><div class="k-cjvr-val" v-html="object.creator"></div><div v-if="revert_allowed.creator"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'creator')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.publisher" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.publisher" class="mr-1" :label="'Publisher'"/></div><div class="k-cjvr-key">Publisher</div><div class="k-cjvr-val" v-html="object.publisher"></div><div v-if="revert_allowed.publisher"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'publisher')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.notes" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.notes" class="mr-1" :label="'Notes'"/></div><div class="k-cjvr-key">Notes</div><div class="k-cjvr-val" v-html="object.notes"></div><div v-if="revert_allowed.notes"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'notes')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.description" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.description" class="mr-1" :label="'Description'"/></div><div class="k-cjvr-key">Description</div><div class="k-cjvr-val" v-html="object.description"></div><div v-if="revert_allowed.description"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'description')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.language" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.language" class="mr-1" :label="'Language'"/></div><div class="k-cjvr-key">Language</div><div class="k-cjvr-val" v-html="object.language"></div><div v-if="revert_allowed.language"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'language')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.subject" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="array_html(object_to_copy.subject)" class="mr-1" :label="'Subject'"/></div><div class="k-cjvr-key">Subject</div><div class="k-cjvr-val" v-html="array_html(object.subject)"></div><div v-if="revert_allowed.subject"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'subject')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.subjectURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="array_of_uris_html(object_to_copy.subjectURI)" class="mr-1" :label="'SubjectURI'"/></div><div class="k-cjvr-key">SubjectURI</div><div class="k-cjvr-val" v-html="array_of_uris_html(object.subjectURI)"></div><div v-if="revert_allowed.subjectURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'subjectURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.statusStartDate" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.statusStartDate" class="mr-1" :label="'Implementation Start Date'"/></div><div class="k-cjvr-key">Implementation Start Date</div><div class="k-cjvr-val" v-html="object.statusStartDate"></div><div v-if="revert_allowed.statusStartDate"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'statusStartDate')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.statusEndDate" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.statusEndDate" class="mr-1" :label="'Retirement Date'"/></div><div class="k-cjvr-key">Retirement Date</div><div class="k-cjvr-val" v-html="object.statusEndDate"></div><div v-if="revert_allowed.statusEndDate"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'statusEndDate')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.licenseURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="uri_html(object_to_copy.licenseURI)" class="mr-1" :label="'licenseURI'"/></div><div class="k-cjvr-key">licenseURI</div><div class="k-cjvr-val" v-html="uri_html(object.licenseURI)"></div><div v-if="revert_allowed.licenseURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'licenseURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.sourceFrameworkIdentifier" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.sourceFrameworkIdentifier" class="mr-1" :label="'Source Framework Identifier'"/></div><div class="k-cjvr-key" style="font-size:12px">Source Frmwrk Identifier*</div><div class="k-cjvr-val" v-html="object.sourceFrameworkIdentifier"></div><div v-if="revert_allowed.sourceFrameworkIdentifier"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'sourceFrameworkIdentifier')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.sourceFrameworkURI" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.sourceFrameworkURI" class="mr-1" :label="'Source Framework URI'"/></div><div class="k-cjvr-key" style="font-size:12px">Source Framework URI*</div><div class="k-cjvr-val" v-html="object.sourceFrameworkURI"></div><div v-if="revert_allowed.sourceFrameworkURI"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', 'sourceFrameworkURI')"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.caseVersion" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.caseVersion" class="mr-1" :label="'CASE Version'"/></div><div class="k-cjvr-key">CASE Version</div><div class="k-cjvr-val"><span v-html="object.caseVersion"></span></div></div>
		<div v-for="(def,key) in custom_fields" class="k-case-json-view-row" v-if="object[key]"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy[key]" class="mr-1" :label="key"/></div><div class="k-cjvr-key">{{key}}*</div><div class="k-cjvr-val" v-html="object[key]"></div><div v-if="revert_allowed[key]"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="pink darken-4" dark style="margin-top:2px; margin-bottom:-1px;" class="ml-1 k-tight-btn" @click="$emit('revert', key)"><v-icon x-small class="mr-1">fas fa-clock-rotate-left</v-icon>Revert</v-btn></template><div class="text-center">Revert property<br>to this value</div></v-tooltip></div></div>
		<div v-if="object.lastChangeDateTime" class="k-case-json-view-row"><div class="k-cjvr-cb"><CopyBtn :size="'small'" :val="object_to_copy.lastChangeDateTime" class="mr-1" :label="'Last Changed Date'"/></div><div class="k-cjvr-key">Last Changed Date</div><div class="k-cjvr-val"><span v-html="object.lastChangeDateTime"></span> <i class="pl-2">({{local_time(object.lastChangeDateTime)}} local time)</i></div></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CopyBtn from '../utilities/CopyBtn'

export default {
	components: { CopyBtn },
	props: {
		object_type: { type: String, required: true },
		// note that object and object_to_copy should have extensions flattened using U.flatten_extensions
		object: { type: Object, required: true },
		object_to_copy: { type: Object, required: true },
		revert_allowed: { type: Object, required: false, default() { return {} } },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		processed_supplementalNotes() {
			if (empty(this.object.supplementalNotes)) return ''
			return U.render_latex(this.object.supplementalNotes)
		},
		custom_fields() {
			if (this.object_type == 'document') return window.CASE_Custom_Extension_Fields.CFDocument ?? null
			if (this.object_type == 'item') return window.CASE_Custom_Extension_Fields.CFItem ?? null
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		local_time(d) { return U.local_last_change_date_time(d) },

		uri_html(o) {
			if (typeof(o) == 'string' || empty(o)) return o
			return U.case_uri_html(o)
		},

		array_html(arr) {
			if (typeof(arr) == 'string' || empty(arr)) return arr
			return U.case_array_html(arr)
		},

		array_of_uris_html(arr) {
			if (typeof(arr) == 'string' || empty(arr)) return arr
			return U.case_array_of_uris_html(arr)
		},
	}
}
</script>

<style lang="scss">
.k-case-json-view-row {
	display:flex;
	align-items: flex-start;
	margin-bottom:4px;
	border-top:1px solid #ddd;
}

.k-cjvr-cb {
	flex-shrink: 1;
	flex-grow: 0;
	flex-basis: auto;
	min-height:21px;
	background-color:#ddd;
	// border-radius:0 0 0 4px;
}

.k-cjvr-key {
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: auto;
	min-height:21px;
	width:160px;
	max-width:30%;
	font-weight:bold;
	text-align:right;
	margin-right:12px;
	color:#333;
	background-color:#ddd;
	padding:1px 4px;
	// border-radius:0 0 4px 0;
}

.k-cjvr-val {
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: auto;
}

.k-cjvr-exemplar {
	border:1px solid #999;
	border-radius:8px;
	padding:4px;
	margin-top:4px;
}

</style>
