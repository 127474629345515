<template><li><a class="k-fwcc-doc-link" :href="`/${doc.identifier}`" @click.prevent="view_framework"><div class="k-fwcc-doc-item" :class="document_class">
	<div class="k-fwcc-doc-title">
		<!-- <div><v-icon small class="mr-2" color="#fff">fas fa-map</v-icon></div> -->
		<span>
			<v-tooltip top><template v-slot:activator="{on}"><v-icon v-on="on" v-if="is_sandbox" small class="mr-1" style="margin-top:-6px" color="#fff">fas fa-umbrella-beach</v-icon></template><div class="text-center">This is a “sandbox” copy of the original framework.</div></v-tooltip>
			<span class="k-fwcc-doc-title-text" v-html="doc.title"></span>
		</span>
		<v-spacer />
		<div v-if="image_src" class="k-fwcc-framework-image-wrapper"><img :src="image_src" alt=""/></div>
	</div>
</div></a></li></template>

<script>
import { mapState, mapGetters } from 'vuex'
import goTo from 'vuetify/lib/services/goto'

export default {
	components: { },
	props: {
		framework_record: { type: Object, required: true },
		case_tree_lsdoc_identifier: { type: String, required: false, default() { return '' }},
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
		doc() { return this.framework_record.json.CFDocument },
		is_sandbox() { return !empty(this.framework_record.ss_framework_data.sandboxOfIdentifier) },
		color_class() {
			return U.framework_color(this.doc.identifier)
			// e.g. 'k-framework-color-0', which sets background-color to a light version; add -med, -dark, -border, or -text if necessary
		},

		document_class() {
			let s = 'k-fw-document-card-' + this.doc.identifier

			// add class if this framework's tree is currently showing, or if another framework's tree is showing
			if (this.doc.identifier == this.case_tree_lsdoc_identifier) {
				s += ' k-fwcc-doc-item-showing'	//  elevation-2'
			} else if (this.case_tree_lsdoc_identifier) {
				s += ' k-fwcc-doc-item-other-item-showing'
			}

			// add class for sandboxes
			if (this.is_sandbox) s += ' k-fwcc-doc-item-sandbox'

			s += ' ' + this.color_class + '-dark'
			s += ' ' + this.color_class + '-text'

			return s
		},
		image_src() {
			return U.framework_image_src(this.framework_record)
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		view_framework($event) {
			this.$emit('view_framework', [this.doc.identifier, null, $event])
		},
	}
}
</script>

<style lang="scss">
.k-fwcc-doc-item {
	border:4px solid transparent;
	padding:8px;
	margin:8px;
	border-radius:8px;
	width:300px;
	min-height:72px;
	cursor:pointer;
}

.k-fwcc-doc-title {
	font-size:16px;
	display:flex;
	align-items: top;
	line-height:18px;
	color:#fff;
}

.k-fwcc-doc-item:hover {
	.k-fwcc-doc-title-text {
		text-decoration:underline;
	}
}

.k-fwcc-doc-item-showing {
	.k-fwcc-doc-title-text {
		font-weight:bold;
	}
}

.k-fwcc-doc-item-other-item-showing {
	opacity:0.5;
}

.k-fwcc-doc-item-sandbox {
	border: 4px dashed #aaa!important;
}

.k-fwcc-framework-image-wrapper {
	flex: 0 0 83px;
	width: 83px;
    height: 83px;
    border-radius: 6px;
    background-color: #fff;
	// background-color:rgba(255,255,255,0.9);
    padding: 3px;
	margin-left:8px;
	display: flex;
	align-items: center;
	justify-content: center;
    // margin: 4px 8px 0 8px;
	img {
		max-width: 77px;
		max-height:77px;
		border-radius:5px;
		opacity:0.8;
	}
}
.k-fwcc-doc-link {
	text-decoration: none !important;
}
.k-fwcc-doc-link:focus {
	outline: none !important;
	.k-fwcc-doc-item {
		outline: none !important;
		box-shadow: 0 0 0 1px #000 !important;
		color: #000 !important;
		background-color: #fff !important;
		transition: none !important;
		.fas, .far { color:#000!important; }
	}
	.k-fwcc-doc-title {
		color: black;
		text-decoration: underline;
	}
	.k-fwcc-framework-image-wrapper {
		outline: none !important;
		box-shadow: 0 0 0 1px #000 !important;
		color: #000 !important;
		background-color: #fff !important;
		transition: none !important;
		.fas, .far { color:#000!important; }
	}
}
</style>
