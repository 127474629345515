class Comment {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'comment_id', 0)
		sdp(this, data, 'item_identifier', '')	// this could be a CFItem identifier or a CFDocument identifier
		sdp(this, data, 'framework_identifier', '')
		sdp(this, data, 'comment_group_id', 0)	// 0 = "personal" comment -- not in any group; -1 = public review
		sdp(this, data, 'parent_comment_id', 0)
		sdp(this, data, 'author_user_id', 0)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'attn_user_id', 0)
		sdp(this, data, 'pinned', false)	// not currently used
		sdp(this, data, 'resolved', false)

		sdp(this, data, 'body', '')

		// suggested edits to item json
		// note that this may include 'supplementalNotes' (string) and may include 'applied' (boolean)
		if (data.suggested_edits) {
			// suggested_edits, if there, is an object; do a deep copy
			this.suggested_edits = $.extend(true, {}, data.suggested_edits)
		} else {
			data.suggested_edits = null
		}

		// convert created_at and updated_at from mysql to unix timestamp (seconds) if needed
		if (typeof(data.created_at) == 'number') this.created_at = data.created_at
		else this.created_at = (empty(data.created_at)) ? 0 : date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() / 1000

		if (typeof(data.updated_at) == 'number') this.updated_at = data.updated_at
		else this.updated_at = (empty(data.updated_at)) ? 0 : date.parse(data.updated_at, 'YYYY-MM-DD HH:mm:ss').getTime() / 1000
	}

	comment_is_read() {
		// we consider comment read if its comment_id is in comment_reads OR if this user wrote the comment
		return vapp.$store.state.user_info.comment_reads[this.comment_id] == 1 || this.author_user_id == vapp.$store.state.user_info.user_id
	}

	// return formatted version of suggested edits to the item the comment is attached to
	suggested_edits_formatted(render_latex, framework_record) {
		if (!this.suggested_edits) return ''

		let html = '<ul>'

		for (let key in this.suggested_edits) {
			if (key == 'applied') continue
			let val

			// for extensions, the only thing we care about here is supplementalNotes
			if (key == 'extensions') {
				key = 'supplementalNotes'
				val = this.suggested_edits.extensions.supplementalNotes
			} else {
				val = this.suggested_edits[key]
			}

			if (typeof(val) != 'string') val = JSON.stringify(val)

			// apply markdown for fullStatement, abbreviatedStatement, and notes
			if (key == 'fullStatement' || key == 'abbreviatedStatement' || key == 'notes' || key == 'supplementalNotes') {
				// also render latex if specified
				if (render_latex === true) val = U.marked_latex(val)
				else val = marked(val)

				// make sure all links open in a new window
				if (key == 'notes') val = val.replace(/<a /g, '<a target="blank" ')
			}

			// also render latex for exemplars if specified (but don't do markup here)
			if (key == 'supplementalNotes') {
				if (render_latex === true) val = U.render_latex(val)
			}

			html += sr('<li><b style="color:#555;">$1:</b> $2</li>', U.field_display_string(key, framework_record), val)
		}

		html += '</ul>'

		return html
	}
}
window.Comment = Comment

class Comment_Group {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'comment_group_id', 0)
		sdp(this, data, 'framework_identifier', '')
		sdp(this, data, 'name', '')
		sdp(this, data, 'creator_user_id', 0)
		sdp(this, data, 'admin_user_ids', [])
		sdp(this, data, 'user_ids', [])
		sdp(this, data, 'user_names', [])	// array of first/last names: 'Pepper Williams', 'Mike Zapp', etc.; must be in same order as user_ids
		sdp(this, data, 'created_at', '')
	}
}
window.Comment_Group = Comment_Group
